import { Button, ButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import { useLaneContext } from '../../../context/LaneContext';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import s from '../styles.module.scss';
import { IColumn, IIcon } from '../../../context/types';

type Props = {
  resetLayout: (
    setColumns: React.Dispatch<React.SetStateAction<IColumn[]>>,
    setPreview: React.Dispatch<React.SetStateAction<string>>,
    setIcons: React.Dispatch<React.SetStateAction<IIcon[]>>
  ) => void;
  openModal: () => string | undefined;
  saveImage: () => Promise<string | undefined>;
};

const Buttons = ({ resetLayout, openModal, saveImage }: Props) => {
  const { setColumns, setPreview, setIcons } = useLaneContext();
  return (
    <Box display='flex' justifyContent='space-between' alignItems='center'>
      <ButtonGroup className={s.buttonGroup}>
        <Button variant='contained' color='success' endIcon={<PreviewIcon />} onClick={() => saveImage()}>
          Preview
        </Button>
        <Button
          variant='contained'
          color='error'
          endIcon={<RestartAltIcon />}
          onClick={() => resetLayout(setColumns, setPreview, setIcons)}
        >
          Reset
        </Button>
      </ButtonGroup>
      <Button variant='contained' endIcon={<SaveIcon />} onClick={openModal}>
        Save
      </Button>
    </Box>
  );
};

export default Buttons;
