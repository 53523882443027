import { Add, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Menu, Submenu, SubmenuData } from '../../../../../api/menu-management/menu/types';
import { API_MENU_MANAGEMENT_ITEMS } from '../../../../../api/menu-management/submenu-item';
import { ISubmenuItem_API } from '../../../../../api/menu-management/submenu-item/types';
import ConfirmDialog from '../../../../common/ConfirmDialog';
import Loader from '../../../../common/loader/Loader';
import s from '../../../../digital-signage-cms/add-playlist/styles.module.scss';
import InputEditOnClick from '../InputEditOnClick';

type Props = {
  index: number;
  menuId: string;
  submenuData: Submenu;
  updateParentSubmenuData: (index: number, submenuData: SubmenuData) => void;
  removeItemFromParentSubmenu: ({ submenuId, menuId }: { submenuId: string; menuId: string }) => void;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SubmenuCard = ({ index, menuId, submenuData, removeItemFromParentSubmenu, updateParentSubmenuData }: Props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const queryClient = useQueryClient();
  const mainBlue = theme.palette.primary.main;
  const lightGray = theme.palette.secondary.light;
  const [submenu, setSubmenu] = useState<SubmenuData>(submenuData.submenu);
  // components in view
  const [itemDropDownInView, setItemDropDownInView] = useState<boolean>(false);
  const [confirmationDialogInView, setConfirmationDialogInView] = useState<boolean>(false);
  const [overwrittingDialogInView, setOverwrittingDialogInView] = useState<boolean>(false);
  const [deleteDialogInView, setDeleteDialogInView] = useState<boolean>(false);
  const [currentDeletionType, setCurrentDeletionType] = useState<string>('');
  // fetching all the submenu items
  // eslint-disable-next-line
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { error: submenuItemsError, data: submenuItemsData } = useQuery(
    ['allClientSubmenuItems'],
    API_MENU_MANAGEMENT_ITEMS.getAllSubmenuItems
  );
  // this is the string version of an item object
  const [itemsSelected, setItemsSelected] = useState<string[]>(
    submenu.items.map(({ submenuItem }) => {
      return submenuItem.name;
    })
  );
  // submenu name
  const [name, setName] = useState<string>(submenu.name);
  // dialog message
  const [dialogMessage, setDialogMessage] = useState<string | null>(null);
  const [deletionItemIndex, setDeletionItemIndex] = useState<number>(-1);

  // this is used for the popup module for adding items to a submenu
  const handleChange = (event: SelectChangeEvent<typeof itemsSelected>) => {
    const {
      target: { value },
    } = event;
    setItemsSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  // handling the closing of the items Dialog
  const handleClose = () => {
    // we need a confirmation pop
    if (itemsSelected.length > 0) {
      setConfirmationDialogInView(true);
    } else {
      // we need to reset the items this submenu had
      setConfirmationDialogInView(false);
      setItemDropDownInView(false);
      setItemsSelected(
        submenu.items.map(({ submenuItem }) => {
          return submenuItem.name;
        })
      );
      setItemDropDownInView(false);
    }
  };
  // handling the closing of the confirmation dialog
  const handleConfirmationDialogClose = (res: boolean) => {
    if (res) {
      setConfirmationDialogInView(false);
      setItemDropDownInView(false);
      setItemsSelected(
        submenu.items.map(({ submenuItem }) => {
          return submenuItem.name;
        })
      );
    } else {
      setConfirmationDialogInView(false);
    }
  };
  // handling the overwritting item connection message
  const handleOverWritingItems = (res: boolean) => {
    if (res) {
      submitSubmenuItems();
    }
    setOverwrittingDialogInView(false);
  };

  // handling the submenu items adding
  const handleAddBtnClick = async () => {
    submitSubmenuItems();
  };

  const submitSubmenuItems = async () => {
    const selectedItemIDs: { submenuItemId: string; position: number; name: string }[] = [];
    for (let i = 0; i < itemsSelected.length; i++) {
      const itemSelected = itemsSelected[i];
      // checking to make sure we have an array of submenu items from DB
      if (submenuItemsData && Array.isArray(submenuItemsData)) {
        const index = submenuItemsData.findIndex((sb: ISubmenuItem_API) => itemSelected === sb.name);
        if (index > -1) {
          selectedItemIDs.push({
            submenuItemId: submenuItemsData[index].id,
            position: i,
            name: submenuItemsData[index].name,
          });
        }
      }
    }
    addItemToSubmenuMutation.mutate({ submenuId: submenu.id, itemsIdList: selectedItemIDs });
    return;
  };

  // handling the deletion of an item
  const handleSubmenuItemDelete = (index: number) => {
    const itemName = itemsSelected[index];
    setDeleteDialogInView(true);
    setDialogMessage(`Are you sure you would like to remove ${itemName} from ${submenu.name}`);
    setDeletionItemIndex(index);
    setCurrentDeletionType('item');
  };

  const handleRemovalClick = async (res: boolean) => {
    if (currentDeletionType === 'item') {
      if (res && deletionItemIndex > -1) {
        // getting the item details to ensure it is still part of the original list
        const i = submenu.items.findIndex(({ submenuItem }) => submenuItem.name === itemsSelected[deletionItemIndex]);
        // before updating frontend lets remove via backend
        if (i > -1) {
          const item = submenu.items[i];
          removeItemFromSubmenuMutation.mutate({ submenuId: submenu.id, submenuItem: item });
        }
      }
    } else {
      if (res) {
        removeItemFromParentSubmenu({ menuId: menuId, submenuId: submenu.id });
      }
    }
    setDeleteDialogInView(false);
  };
  // mutation for removing an item from the the list of submenu
  const removeItemFromSubmenuMutation = useMutation({
    mutationFn: (itemRemoved: {
      submenuId: string;
      submenuItem: {
        submenuId: string;
        submenuItemId: string;
        startDate: Date;
        endDate: Date;
        createdAt: Date;
        submenuItem: {
          id: string;
          name: string;
        };
      };
    }) => API_MENU_MANAGEMENT_ITEMS.removeItemFromSubmenu(itemRemoved),
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      if (data) {
        const _itemsSelected = [...itemsSelected];
        _itemsSelected.splice(deletionItemIndex, 1);
        queryClient.setQueryData<Menu[]>(['allClientMenus'], [...data]);
        setItemsSelected(_itemsSelected);
        setDeletionItemIndex(-1);
        // changing the menu selected to the new menu just created
        toast.success('Successfully removed item from submenu!');
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newMenu, context) => {
      if (context) {
        // queryClient.setQueryData(['allClientSubmenuItems'], context.previousSubmenuItems);
        toast.error('Failed to remove item from submenu.');
      }
    },
  });
  // mutation for adding an item to the list of items for the submenu
  const addItemToSubmenuMutation = useMutation({
    mutationFn: (data: {
      submenuId: string;
      itemsIdList: { submenuItemId: string; position: number; name: string }[];
    }) => API_MENU_MANAGEMENT_ITEMS.addItemsToSubmenu(data),
    // When mutate is called:
    onMutate: async (data: {
      submenuId: string;
      itemsIdList: { submenuItemId: string; position: number; name: string }[];
    }) => {
      // we have to update the menus information
      // the API request will not send back
      await queryClient.cancelQueries({ queryKey: ['allClientMenus'] });
      // Snapshot the previous value
      const previousMenus = queryClient.getQueryData<Menu[]>(['allClientMenus']);
      // Optimistically update to the new value
      if (previousMenus) {
        const _previousMenus = [...previousMenus];
        const menuIndex = _previousMenus.findIndex((menu) => {
          return menu.id === menuId;
        });
        const _menuToUpdate = { ..._previousMenus[menuIndex] };
        // now we need to find the submenu
        const submenuIndex = _menuToUpdate.submenus.findIndex(({ submenu }) => {
          return submenu.id === data.submenuId;
        });
        const _submenuToUpdate = { ..._menuToUpdate.submenus[submenuIndex].submenu };
        // now we need to update this specific submenu to have this item
        _previousMenus[menuIndex].submenus[submenuIndex].submenu = _submenuToUpdate;
        queryClient.setQueryData<Menu[]>(['allClientMenus'], _previousMenus);
      }
      // Return a context object with the snapshotted value
      return { previousMenus };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      // data returns the response from the API
      // previousMenus is the list of all the menus
      if (context && context.previousMenus) {
        const updatedMenus = [...data];
        // find the menu we just edited from the newly updated list
        const menuIndex = updatedMenus.findIndex((menu) => {
          return menu.id === menuId;
        });
        if (menuIndex > -1) {
          const foundMenu = updatedMenus[menuIndex];
          // now we look for the submenu that was updated
          // eslint-disable-next-line
          const updatedSubmenuIndex = foundMenu.submenus.findIndex((sm: any) => {
            return sm.submenu.id === variables.submenuId;
          });
          if (updatedSubmenuIndex > -1) {
            setSubmenu({ ...foundMenu.submenus[updatedSubmenuIndex].submenu });
          }
          queryClient.setQueryData<Menu[]>(['allClientMenus'], updatedMenus);
          // changing the menu selected to the new menu just created
          toast.success('Successfully added item(s) to Submenu!');
          setItemDropDownInView(false);
        }
      }
    },
    // // If the mutation fails,
    // // use the context returned from onMutate to roll back
    onError: (err, newMenu, context) => {
      if (context?.previousMenus) {
        queryClient.setQueryData(['allClientMenus'], context.previousMenus);
        toast.error('Failed to add item(s) to submenu.');
      }
    },
  });

  // DRAGGABLE FUNCTIONS
  // saving references for dragItem and dragOverItem
  // eslint-disable-next-line
  const dragItem = useRef<any>(null);
  // eslint-disable-next-line
  const dragOverItem = useRef<any>(null);
  // handling sorting drag & drop
  const handleSort = () => {
    // duplicate items
    const _itemsSelected = [...itemsSelected];
    // remove and save the dragged item content
    const draggedItemContent = _itemsSelected.splice(dragItem.current, 1)[0];
    // switch the position
    _itemsSelected.splice(dragOverItem.current, 0, draggedItemContent);
    // reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;
    // update the actual array
    setItemsSelected(_itemsSelected);
    // converting to list of items rather than a string
    const orderedItems: {
      submenuId: string;
      submenuItemId: string;
      createdAt: Date;
      startDate: Date;
      endDate: Date;
      submenuItem: {
        id: string;
        name: string;
      };
    }[] = [];
    _itemsSelected.forEach((itemName) => {
      const i = submenu.items.findIndex(({ submenuItem }) => submenuItem.name === itemName);
      if (i > -1) {
        orderedItems.push(submenu.items[i]);
      }
    });
    const _submenu = { ...submenu };
    _submenu.updatedAt = new Date();
    _submenu.items = orderedItems;
    updateParentSubmenuData(index, _submenu);
    setSubmenu(_submenu);
  };

  const updateParentValue = (keyName: string, value: string) => {
    if (keyName === 'name') {
      setName(value);
      const _submenu = { ...submenu };
      _submenu.name = value;
      _submenu.updatedAt = new Date();
      updateParentSubmenuData(index, _submenu);
      setSubmenu(_submenu);
    }
  };

  const handleSubmenuDeleteBtnClick = () => {
    setDeleteDialogInView(true);
    setDialogMessage(`Are you sure you want to delete the submenu ${submenu.name}?`);
    setCurrentDeletionType('submenu');
  };

  useEffect(() => {
    setSubmenu({ ...submenuData.submenu });
    const newSubmenuItems = submenuData.submenu.items.map(({ submenuItem }) => {
      return submenuItem.name;
    });
    setItemsSelected(newSubmenuItems);
  }, [submenuData]);

  return (
    <>
      <Stack
        key={submenu.id}
        border={'1px solid #000'}
        bgcolor={'#fff'}
        alignItems={'center'}
        // spacing={2}
        height={'50vh'}
      >
        <Box bgcolor={lightGray} width={'100%'} height={'15%'} position={'relative'}>
          <InputEditOnClick keyName='name' updateParentValue={updateParentValue} initialValue={name} />
          {itemsSelected.length > 0 ? (
            <Box
              position={'absolute'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              left={'90%'}
              top={'33%'}
              borderRadius={'50%'}
              bgcolor={mainBlue}
              width={'1.4vw'}
              height={'1.4vw'}
            >
              <Typography color={'#fff'}>{itemsSelected.length}</Typography>
            </Box>
          ) : null}
        </Box>
        {itemsSelected.length > 0 ? (
          <Stack
            spacing={1}
            alignItems={'center'}
            width={'100%'}
            height={'75%'}
            overflow={'scroll'}
            sx={{ scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}
          >
            {itemsSelected.map((item, index) => {
              return (
                <Grid
                  container
                  key={`item-${index}`}
                  width={'100%'}
                  draggable
                  onDragStart={() => (dragItem.current = index)}
                  onDragEnter={() => (dragOverItem.current = index)}
                  onDragEnd={handleSort}
                  onDragOver={(e) => e.preventDefault()}
                  className={s.grabbable}
                >
                  <Grid item xs={2} pt={1}>
                    <Typography width={'100%'} textAlign={'center'}>
                      {index + 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography width={'100%'} textAlign={'center'} pt={1} pb={1}>
                      {item}
                    </Typography>
                  </Grid>
                  {matches ? (
                    <Grid
                      xs={2}
                      item
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      sx={{ opacity: 0, '&:hover': { opacity: 1 } }}
                    >
                      <Button
                        onClick={() => handleSubmenuItemDelete(index)}
                        style={{ padding: 0, minWidth: '100%', width: '100%', height: '100%' }}
                      >
                        <Delete />
                      </Button>
                    </Grid>
                  ) : (
                    <Grid xs={2} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <Button
                        onClick={() => handleSubmenuItemDelete(index)}
                        style={{ padding: 0, minWidth: '100%', width: '100%', height: '100%' }}
                      >
                        <Delete />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Stack>
        ) : (
          <Box height={'75%'} display={'flex'} alignItems={'center'}>
            <Typography textAlign={'center'} width={'75%'} ml={'12.5%'} noWrap={false} fontWeight={'bold'}>
              Looks like this submenu has not items. Please add an item.
            </Typography>
          </Box>
        )}
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          spacing={1}
          bgcolor={lightGray}
          width={'100%'}
          height={'10%'}
          p={1}
        >
          <Button sx={{ backgroundColor: mainBlue }} onClick={() => setItemDropDownInView(true)} size='small'>
            <Add htmlColor='#fff' sx={{ '&:hover': { color: mainBlue } }} />
          </Button>
          <Button sx={{ backgroundColor: 'red' }} onClick={handleSubmenuDeleteBtnClick} size='small'>
            <Delete htmlColor='#fff' sx={{ '&:hover': { color: 'red' } }} />
          </Button>
        </Stack>
      </Stack>
      {itemDropDownInView && (
        <Dialog open={true} onClose={handleClose}>
          <DialogTitle>Select An Item to Add</DialogTitle>
          <DialogContent>
            {submenuItemsError ? (
              <Typography>There was an error loading items</Typography>
            ) : currentStep === 0 ? (
              <FormControl sx={{ m: 1, minWidth: 280, maxWidth: 280 }}>
                <InputLabel id='submenu-items-multiple-chip-label'>Items</InputLabel>
                <Select
                  labelId='submenu-items-multiple-chip-label'
                  id='submenu-items-multiple-chip'
                  multiple
                  value={itemsSelected}
                  onChange={handleChange}
                  input={<OutlinedInput id='submenu-items-select-multiple-chip' label='Items' />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {submenuItemsData
                    ? submenuItemsData.map((item: ISubmenuItem_API) => (
                        // <MenuItem key={item.id} value={item.name} style={getStyles(item, itemsSelected, theme)}>
                        <MenuItem key={item.id} value={item.name}>
                          {item.name} {item.submenu && item.submenu.id !== submenu.id ? `(${item.submenu.name})` : ''}
                        </MenuItem>
                      ))
                    : ''}
                </Select>
              </FormControl>
            ) : (
              <Box height={'18vh'}>
                <Loader />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            {currentStep === 0 ? (
              <>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAddBtnClick}>Add</Button>
              </>
            ) : (
              ''
            )}
          </DialogActions>
        </Dialog>
      )}
      {confirmationDialogInView && (
        <ConfirmDialog
          title={'Please confirm action'}
          message={'Are you sure you would like to close this form? All data will be lost'}
          handleClick={handleConfirmationDialogClose}
        />
      )}
      {overwrittingDialogInView && (
        <ConfirmDialog
          title={'Please confirm action'}
          message={
            'You will being over writting some items that are currently connected to another submenu. Are you sure you would like to overwrite this?'
          }
          handleClick={handleOverWritingItems}
        />
      )}
      {deleteDialogInView && (
        <ConfirmDialog
          title={'Please confirm action'}
          message={dialogMessage ? dialogMessage : 'Are you sure you would like to remove this item from its submenu?'}
          handleClick={handleRemovalClick}
        />
      )}
    </>
  );
};

export default SubmenuCard;
