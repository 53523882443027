import ColumnsInitialValue from '../../../constants/columns-initial-value';
import IconsInitialValue from '../../../constants/icons-initial-value';
import { IColumn, IIcon } from '../../../context/types';

const resetLayout = (
  setColumns: React.Dispatch<React.SetStateAction<IColumn[]>>,
  setPreview: React.Dispatch<React.SetStateAction<string>>,
  setIcons: React.Dispatch<React.SetStateAction<IIcon[]>>
) => {
  const clonedColumns = JSON.parse(JSON.stringify(ColumnsInitialValue));
  setColumns(clonedColumns);

  const clonedIcons = JSON.parse(JSON.stringify(IconsInitialValue));
  setIcons(clonedIcons);

  setPreview('');
};

export default resetLayout;
