import { Dispatch, SetStateAction } from 'react';
import { IColumn } from '../../../context/types';
import checkLastColumn from './check-last-column';

const splitColumn = (column: IColumn, columns: IColumn[], setColumns: Dispatch<SetStateAction<IColumn[]>>) => {
  const columnToSplit = column.width - 1;
  const position = column.position;

  const nextColumnPosition = columns.find((col) => col.position === position + columnToSplit)?.position || 0;

  const columnsCopy = columns.slice(); // copy and .slice() makes a new reference of columns
  columnsCopy[position].width = column.width - 1;
  columnsCopy[nextColumnPosition].merged = false;

  const columnsReplace = checkLastColumn(columnsCopy);

  setColumns(columnsReplace);
};

export default splitColumn;
