const ColumnsInitialValue = [
  {
    position: 0,
    merged: false,
    width: 1,
    last: false,
    image: '',
    imageId: null,
  },
  {
    position: 1,
    merged: false,
    width: 1,
    last: false,
    image: '',
    imageId: null,
  },
  {
    position: 2,
    merged: false,
    width: 1,
    last: false,
    image: '',
    imageId: null,
  },
  {
    position: 3,
    merged: false,
    width: 1,
    last: false,
    image: '',
    imageId: null,
  },
  {
    position: 4,
    merged: false,
    width: 1,
    last: false,
    image: '',
    imageId: null,
  },
  {
    position: 5,
    merged: false,
    width: 1,
    last: true,
    image: '',
    imageId: null,
  },
  // {
  //   position: 6,
  //   merged: false,
  //   width: 1,
  //   last: false,
  //   image: '',
  //   imageName: '',
  // },
  // {
  //   position: 7,
  //   merged: false,
  //   width: 1,
  //   last: true,
  //   image: '',
  //   imageName: '',
  // },
];

export default ColumnsInitialValue;
