import PlaylistView from '../../../components/digital-signage-cms/playlist/PlaylistView';
import { useAuthContext } from '../../../context/AuthContext';
import { decrypt } from '../../../utils/cryptojs-helper';

const PlaylistPage = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const user = JSON.parse(decrypted);
  return <PlaylistView user={user} />;
};

export default PlaylistPage;
