import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { API_CLIENT } from '../../api/client';
import Loader from '../../components/common/loader';
import SingleClient from '../../components/single-client';

const SingleClientPage = () => {
  const location = useLocation();
  const id = location.pathname.split('/')[2];

  const { error, data, refetch } = useQuery(['GetSingleClient', id], API_CLIENT.getSingle);
  // the page will transform to the correct format
  if (error) return <p>error</p>;
  if (data) {
    return <SingleClient data={data} refetch={refetch} />;
  }
  return <Loader />;
};

export default SingleClientPage;
