import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';

const LogoutPage = () => {
  const { logout } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate('/login');
    // wait a half a second so toast shows up
    setTimeout(() => {
      toast.error('Your Session Has Expired, Please Log Back In');
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};

export default LogoutPage;
