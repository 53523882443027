import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import { API_LOGGING } from '../../../api/logging';
import { uploadLogsToS3Bucket } from '../../../api/logging/api';
import ConfirmDialog from '../../common/ConfirmDialog';
import Loader from '../../common/loader/Loader';
import LogTable from '../components/LogTable';

const loggingSystems: string[] = ['HTTP', 'Lane Management', 'AWS SDK', 'Digital Signage'];
/**
 * This will give the admin user the option to view all logs or all
 * curio cloud systems
 */
const AdminLogging = () => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [confirmationDialogInView, setConfirmationDialogInView] = useState<boolean>(false);
  const [loggingSystem, setLoggingSystem] = useState<string>('');
  const { error, data, isFetching } = useQuery(['currentLogs', loggingSystem], () =>
    API_LOGGING.getLogsForSystem(loggingSystem)
  );

  const handleChange = (event: SelectChangeEvent) => {
    setLoggingSystem(event.target.value as string);
  };

  const handleUploadBtnClick = () => {
    setConfirmationDialogInView(true);
  };

  const handleUploadLogs = async (res: boolean) => {
    if (res) {
      setIsUploading(true);
      await uploadLogsToS3Bucket();
      setIsUploading(false);
    }
    setConfirmationDialogInView(false);
  };

  return (
    <Box
      bgcolor={'#fff'}
      borderRadius={2}
      p={1}
      display={{ xs: 'flex' }}
      flexDirection={{ xs: 'column' }}
      gap={2}
      maxWidth={{ xs: '310px', sm: '720px', md: '1000px', lg: '1320px', xl: '1800px' }}
    >
      <Grid container direction={{ xs: 'column', md: 'row' }} gap={2}>
        <Grid item xs={8} md={5}>
          <Typography variant='h3' textAlign={{ xs: 'center', md: 'left' }}>
            Logging
          </Typography>
        </Grid>
        <Grid item xs={4} md={6} display={'flex'} alignItems={'center'} justifyContent={{ xs: 'center', md: 'right' }}>
          <Button onClick={handleUploadBtnClick} disabled={isUploading} variant='contained'>
            {isUploading ? <LoaderIcon /> : <Typography color={'#fff'}>Upload Logs to S3Bucket</Typography>}
          </Button>
        </Grid>
      </Grid>
      <Typography>Please select a system</Typography>
      <FormControl fullWidth>
        <InputLabel id='logging-system-select-label'>Logging System</InputLabel>
        <Select
          labelId='logging-systeme-select-label'
          id='logging-system-select'
          value={loggingSystem}
          label='Logging System'
          onChange={handleChange}
        >
          {loggingSystems.map((name, index) => {
            return (
              <MenuItem key={`system-logging-option-${index}`} value={name}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {isFetching && <Loader />}
      {error ? (
        <Typography>Error</Typography>
      ) : data && typeof data === 'string' ? (
        <LogTable logs={data} />
      ) : (
        <Typography>There are no logs</Typography>
      )}
      {confirmationDialogInView && (
        <ConfirmDialog
          title='Please confirm the following action'
          message='Are you sure you would like to upload logs to S3Bucket?'
          handleClick={handleUploadLogs}
        />
      )}
    </Box>
  );
};

export default AdminLogging;
