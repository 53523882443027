import BackupTableIcon from '@mui/icons-material/BackupTable';
import { useState } from 'react';
import UsersTable from '.';
import MultiHeader from '../common/multi-header';
import RolesTable from './RolesTable';
import { Props } from './types';

const UsersGroupsTables = ({ usersData, rolesData, loggedUser }: Props) => {
  const [tableInView, setTableInView] = useState(0);

  const handleHeaderOptionClick = (index: number) => {
    setTableInView(index);
  };
  // we need to create the options based on the roles
  const options = loggedUser.currentClient.client.roles.includes(4000)
    ? [
        { text: 'Users', Icon: <BackupTableIcon />, index: 0 },
        { text: 'Roles', Icon: <BackupTableIcon />, index: 1 },
      ]
    : [{ text: 'Users', Icon: <BackupTableIcon />, index: 0 }];

  return (
    <>
      {/* <MultiHeader
        options={[
          { text: 'Users', Icon: <BackupTableIcon />, index: 0 },
          { text: 'Roles', Icon: <BackupTableIcon />, index: 1 },
        ]}
        handleClick={handleHeaderOptionClick}
      /> */}
      <MultiHeader options={options} handleClick={handleHeaderOptionClick} />
      {tableInView === 0 ? <UsersTable loggedUser={loggedUser} data={usersData} /> : <RolesTable data={rolesData} />}
    </>
  );
};

export default UsersGroupsTables;
