import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Location } from '../types';

type Props = {
  locations: Location[];
};

const LocationTable = ({ locations }: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 380 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='right'>Address</TableCell>
            <TableCell align='right'>City</TableCell>
            <TableCell align='right'>State</TableCell>
            <TableCell align='right'>Zip Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {locations.map((location) => (
            <TableRow key={location.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component='th' scope='row'>
                {location.name}
              </TableCell>
              <TableCell align='right'>{location.address}</TableCell>
              <TableCell align='right'>{location.city}</TableCell>
              <TableCell align='right'>{location.state}</TableCell>
              <TableCell align='right'>{location.zipCode}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LocationTable;
