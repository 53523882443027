import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

export interface ILocation {
  name: string;
  address: string;
  aptNumber: string;
  city: string;
  state: string;
  zip: string;
}

export interface IMenu {
  name: string;
  description: string;
}

export interface ISubmenu {
  name: string;
  description: string;
}

export interface ISubmenuItem {
  name: string;
}

export type FormTypes = {
  location: ILocation | null;
  menu: IMenu | null;
  submenu: ISubmenu | null;
  submenuItem: ISubmenuItem | null;
};

interface IMenuForms {
  forms: FormTypes;
  setForms: Dispatch<SetStateAction<FormTypes>>;
}

const MenuContext = createContext({} as IMenuForms);

export const useMenuContext = () => useContext(MenuContext);

export const MenuContextProvider = ({ children }: { children: ReactNode }) => {
  const [forms, setForms] = useState<FormTypes>({ location: null, menu: null, submenu: null, submenuItem: null });

  return <MenuContext.Provider value={{ forms, setForms }}>{children}</MenuContext.Provider>;
};
