import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { LocationData } from '../../api/whiteboard/map/location/types';
import { API_WHITEBOARD_APP_LOCATION_SITES } from '../../api/whiteboard/map/site';
import useLocalStorage from '../../hooks/useLocalStorage';
import { SiteLocation } from './../../api/whiteboard/map/site/types';
import FormDialog from './components/forms/FormDialog';
import IconsSection from './components/sections/IconsSection';
import LabelsSection from './components/sections/LabelsSection';
import MapSection from './components/sections/MapSection';
import textSizing from './textSizing';

type Props = {
  locationData: LocationData; // {locartion, labels, media}
};

const MapWhiteboard = ({ locationData }: Props) => {
  // eslint-disable-next-line
  const [drawSiteId, setDrawSiteId] = useLocalStorage('draw_site', {} as { siteId: string; siteName: string });
  // we need to know fetch the site information for this location
  const { data: allSitesData } = useQuery(['locationSiteLocations', locationData.location.id], () =>
    API_WHITEBOARD_APP_LOCATION_SITES.getAllSiteLcationsForLocation(location.id)
  );
  // on initial load we will show all the media Icons and labels for this location
  const { location, media, labels } = locationData;
  const theme = useTheme();
  const mainBlue = theme.palette.primary.main;
  const lightGray = theme.palette.secondary.light;
  const [moduleInView, setModuleInView] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>('');
  const [siteLocations, setSiteLocations] = useState<SiteLocation[] | null>(null);
  // the Object of the selected site
  const [selectedSite, setSelectedSite] = useState<SiteLocation | null>(null);
  // used for the MaterialUI Select
  const [selectedSiteName, setSelectedSiteName] = useState<string | null>(null);
  // this function will manage the pop up module
  const handleButtonClick = (dialogType: string) => {
    setModuleInView(true);
    setDialogType(dialogType);
  };

  // Function for the select drop down for the Site to select
  const handleSiteChange = (event: SelectChangeEvent) => {
    const selectedSiteName = event.target.value as string;
    // we need to find the site object
    const foundSiteLocationIndex = siteLocations?.findIndex((sitelocation) => {
      return sitelocation.name === selectedSiteName;
    });
    if (allSitesData && foundSiteLocationIndex !== undefined && foundSiteLocationIndex >= 0) {
      setSelectedSite(allSitesData[foundSiteLocationIndex]);
      setSelectedSiteName(selectedSiteName);
    }
  };

  const getFilteredPlayersList = () => {
    if (selectedSite) {
      const _players = location.players.filter((player) => {
        return player.siteLocation && player.siteLocation.id === selectedSite.id;
      });
      return _players;
    } else {
      return location.players;
    }
  };

  useEffect(() => {
    if (allSitesData) {
      setSiteLocations(allSitesData);
      if (selectedSiteName) {
        const foundSiteLocationIndex = allSitesData?.findIndex((sitelocation) => {
          return sitelocation.name === selectedSiteName;
        });
        if (allSitesData && foundSiteLocationIndex !== undefined && foundSiteLocationIndex >= 0) {
          setSelectedSite(allSitesData[foundSiteLocationIndex]);
        }
      }
    }
    setDrawSiteId({ siteId: '', siteName: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSitesData]);
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flexWrap={'wrap'}
      bgcolor={'#fff'}
      height={{ xs: '82vh', md: '82vh', xl: '88vh' }}
      width={{ xs: '94vw', md: '90vw', lg: '92vw' }}
      border={`4px solid ${lightGray}`}
    >
      <Grid
        container
        width={{ xs: '85%', md: '80%', lg: '86%' }}
        height={'10%'}
        justifyContent={'center'}
        alignItems={'center'}
        // p={2}
        borderBottom={`4px solid ${lightGray}`}
      >
        <Grid item xs={7}>
          {location ? (
            <Typography fontSize={{ xs: textSizing.xs.title }} fontWeight={'bold'} pl={1}>
              {location.name}
            </Typography>
          ) : (
            <Typography>No location exist</Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          {siteLocations && siteLocations.length > 0 ? (
            <FormControl fullWidth>
              <InputLabel id='site-select-label'>Site</InputLabel>
              <Select
                labelId='site-select-label'
                id='site-select'
                value={selectedSiteName ? selectedSiteName : ''}
                label='Site'
                onChange={handleSiteChange}
              >
                {siteLocations.map((site) => {
                  return (
                    <MenuItem key={site.id} value={site.name}>
                      {site.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <Typography>Please Create a Site Location</Typography>
          )}
        </Grid>
        <Grid item xs={2} display={'flex'} justifyContent={{ xs: 'right' }}>
          <Button variant='contained' onClick={() => handleButtonClick('add-site-location')}>
            <Typography
              fontWeight={'bold'}
              color={'#fff'}
              fontSize={{ xs: textSizing.xs.button, lg: textSizing.lg.button }}
            >
              Add Site Location
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <MapSection
        siteId={selectedSiteName}
        activeSite={selectedSite}
        resetActiveSite={() => {
          setSelectedSite(null);
          setSelectedSiteName(null);
        }}
        handleNewMapClick={handleButtonClick}
        lightGray={lightGray}
        locationId={locationData.location.id}
      />
      <Box width={{ xs: '85%', md: '80%', lg: '86%' }} height={'20%'}>
        <Stack direction={'column'} height={'100%'} width={'100%'}>
          <Box height={'50%'} display={'flex'} flexDirection={'row'} borderTop={`4px solid ${lightGray}`}>
            <Box width={{ xs: '80%' }}>
              <LabelsSection location={locationData} labels={labels} siteLocation={selectedSite} />
            </Box>
            <Box width={{ xs: '20%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Button variant='contained' onClick={() => handleButtonClick('company-form')}>
                <Typography
                  color={'#fff'}
                  fontSize={{ xs: textSizing.xs.button, lg: textSizing.lg.button }}
                  fontWeight={'bold'}
                >
                  Add Company
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box height={'50%'} borderTop={`4px solid ${lightGray}`}>
            <Typography fontWeight={'bold'} fontSize={{ xs: textSizing.xs.sectionTitles }} pl={2}>
              Bright Sign Players
            </Typography>
            <Box paddingLeft={'1.7vw'} width={'100%'} display={'flex'} flexDirection={'row'}>
              <Box width={'80%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                {location.players.length > 0 ? (
                  <List sx={{ display: 'flex', flexDirection: 'row', padding: 0 }}>
                    {getFilteredPlayersList().map((player) => {
                      return (
                        <ListItem key={player.id} style={{ padding: 0 }}>
                          <Stack direction={'row'} spacing={1}>
                            <Typography fontWeight={'bold'} fontSize={{ xs: textSizing.xs.sectionText }}>
                              Name:{' '}
                            </Typography>
                            <Typography fontSize={{ xs: textSizing.xs.sectionText }}>{player.name}</Typography>
                          </Stack>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <Typography>No players connected to location. Please contact support.</Typography>
                )}
              </Box>
              <Box width={'20%'}>
                {selectedSite && (
                  <Button variant='contained' onClick={() => handleButtonClick('player-management')}>
                    <Typography color={'#fff'}>Manage Players</Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>
      <IconsSection
        media={media}
        lightGray={lightGray}
        siteLocation={selectedSite}
        location={locationData}
        handleAddIconClick={handleButtonClick}
      />
      {moduleInView && (
        <FormDialog
          type={dialogType}
          location={locationData}
          siteLocation={selectedSite}
          closeDialog={() => setModuleInView(false)}
          mainBgColor={mainBlue}
          itemToEdit={null}
        />
      )}
    </Box>
  );
};

export default MapWhiteboard;
