import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import checkIfEmptyString from '../../../utils/check-if-empty';

type InputError = { isError: boolean; message: string };
const resetError = { isError: false, message: '' };

const NewLocation = () => {
  // input refs
  const nameRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const cityRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLInputElement>(null);
  const zipCodeRef = useRef<HTMLInputElement>(null);
  // input ERRORS
  const [nameError, setNameError] = useState<InputError>(resetError);
  const [addressError, setAddressError] = useState<InputError>(resetError);
  const [cityError, setCityError] = useState<InputError>(resetError);
  const [stateError, setStateError] = useState<InputError>(resetError);
  const [zipCodeError, setZipCodeError] = useState<InputError>(resetError);

  const handleCreateClick = () => {
    let errorCount = 0;
    // validating all input data
    if (nameRef.current != null) {
      if (nameRef.current.value.length < 4) {
        errorCount++;
        setNameError({ isError: true, message: 'Location name must be greater than 3 characters.' });
      } else {
        setNameError(resetError);
      }
    }
    if (addressRef.current != null) {
      if (checkIfEmptyString(addressRef.current.value)) {
        errorCount++;
        setAddressError({ isError: true, message: 'Cannot be empty.' });
      } else {
        setAddressError(resetError);
      }
    }
    if (cityRef.current != null) {
      if (checkIfEmptyString(cityRef.current.value)) {
        errorCount++;
        setCityError({ isError: true, message: 'Cannot be empty.' });
      } else {
        setCityError(resetError);
      }
    }
    if (stateRef.current != null) {
      if (checkIfEmptyString(stateRef.current.value)) {
        errorCount++;
        setStateError({ isError: true, message: 'Cannot be empty.' });
      } else {
        setStateError(resetError);
      }
    }
    if (zipCodeRef.current != null) {
      if (checkIfEmptyString(zipCodeRef.current.value)) {
        errorCount++;
        setZipCodeError({ isError: true, message: 'Cannot be empty.' });
      } else {
        setZipCodeError(resetError);
      }
    }
    if (errorCount > 0) {
      alert('there are errors');
    }
  };

  return (
    <Box bgcolor={'#fff'} borderRadius={2}>
      <Typography p={2} textAlign={'center'} borderBottom={'1px solid #dedede'}>
        Looks like you have no locations. Lets go ahead a create a location.
      </Typography>
      <Stack spacing={2} p={2}>
        <Typography textAlign={'center'} fontWeight={'bold'}>
          Location Details
        </Typography>
        <TextField
          inputRef={nameRef}
          fullWidth
          id='location-name'
          label='Location'
          variant='outlined'
          error={nameError.isError}
          helperText={nameError.message}
        />
        <TextField
          inputRef={addressRef}
          fullWidth
          id='street-address'
          label='Street Address'
          variant='outlined'
          error={addressError.isError}
          helperText={addressError.message}
        />
        <TextField
          inputRef={cityRef}
          fullWidth
          id='city'
          label='City'
          variant='outlined'
          error={cityError.isError}
          helperText={cityError.message}
        />
        <Stack direction={'row'} spacing={1}>
          <TextField
            inputRef={stateRef}
            id='state'
            label='State'
            variant='outlined'
            error={stateError.isError}
            helperText={stateError.message}
          />
          <TextField
            inputRef={zipCodeRef}
            id='zip-code'
            label='ZIP Code'
            variant='outlined'
            error={zipCodeError.isError}
            helperText={zipCodeError.message}
          />
        </Stack>
        <Button variant='contained' onClick={handleCreateClick}>
          <Typography color={'#fff'}>Create</Typography>
        </Button>
      </Stack>
    </Box>
  );
};

export default NewLocation;
