import { Dialog, DialogContent, DialogTitle, Grid, Stack, Typography } from '@mui/material';
import { PresentationDetails } from '../../../../api/digital-signage-cms/types';
import { formatDateMMDDYYY } from '../../../../utils/format-data';

type Props = {
  presentationDetails: PresentationDetails;
  handleClose: () => void;
};
const PresentationDetailsDialog = ({ presentationDetails, handleClose }: Props) => {
  return (
    <Dialog open={true} onClose={() => handleClose()} fullWidth>
      <DialogTitle>{presentationDetails.name}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Grid container>
            <Grid item xs={4}>
              <Typography fontWeight={'bold'}>Description:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{presentationDetails.description}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Typography fontWeight={'bold'}>Dimensions:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                {presentationDetails.width}x{presentationDetails.height}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Typography fontWeight={'bold'}>URL:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography noWrap textOverflow={'ellipsis'}>
                {presentationDetails.url}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Typography fontWeight={'bold'}>Created At:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{formatDateMMDDYYY(presentationDetails.createdAt)}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Typography fontWeight={'bold'}>Created By:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                {presentationDetails.user.firstName} {presentationDetails.user.lastName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Typography fontWeight={'bold'}>Zone Count:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{presentationDetails._count.zones}</Typography>
            </Grid>
          </Grid>
          {presentationDetails._count.zones > 0 && presentationDetails.zones ? (
            <Grid container>
              <Grid item xs={12}>
                <Typography fontWeight={'bold'}>Zone Details:</Typography>
              </Grid>
              <Grid item xs={12}>
                {presentationDetails.zones.map((zone, index) => {
                  return (
                    <Stack spacing={1} key={`zone-${index}`} pl={4}>
                      <Typography>Name: {zone.name}</Typography>
                      <Typography>Playlist Count: {zone.playlists.length}</Typography>
                    </Stack>
                  );
                })}
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PresentationDetailsDialog;
