import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FileUpload, FileUploadErrorParams, FileUploadUploadParams } from 'primereact/fileupload';
import toast from 'react-hot-toast';
import { LocationData } from '../../../../api/whiteboard/map/location/types';
import { WBMedia } from '../../../../api/whiteboard/map/site/types';

type Props = {
  itemToEdit: WBMedia | null;
  locationData: LocationData;
  close: () => void;
};

const IconForm = ({ close, locationData, itemToEdit }: Props) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const mainBlueBg = theme.palette.primary.main;
  const mainErrorBg = theme.palette.error.main;

  const handleFileSuccessUploaded = (event: FileUploadUploadParams) => {
    const response = JSON.parse(event.xhr.responseText);
    console.log(response);
    const newMediaIcon: WBMedia[] = [...response.media];
    const updatedLocationData = {
      ...locationData,
    };
    console.log(newMediaIcon);
    // if we updated a single Icon then we need to find a replace
    if (itemToEdit) {
      const _previousIcons = updatedLocationData ? [...updatedLocationData.media] : [];
      const foundIndex = _previousIcons.findIndex((icon) => {
        return icon.id === itemToEdit.id;
      });
      if (foundIndex > -1) {
        // since we update one icon at a time then we only grab the item in index 0
        _previousIcons[foundIndex] = newMediaIcon[0];
        updatedLocationData.media = _previousIcons;
        queryClient.setQueryData<LocationData>(['clientLocation'], updatedLocationData);
        toast.success(`Succesfully updated icon ${itemToEdit.name}`);
      }
    } else {
      // we are creating a new one so we can just add it to the end of the list
      updatedLocationData.media = updatedLocationData.media.concat(newMediaIcon);
      // updating the data set with the new lsit of media
      queryClient.setQueryData<LocationData>(['clientLocation'], updatedLocationData);
      toast.success('Succesfully uploaded new icon(s)!');
    }
    close();
  };

  const handleFileErrorUpload = (event: FileUploadErrorParams) => {
    const errorMessage = event.xhr.responseText;
    toast.error(errorMessage);
  };

  const getForm = () => {
    if (itemToEdit) {
      return (
        <FileUpload
          url={`${process.env.REACT_APP_API_BASE_URL_V2}/whiteboard/map/update/icon/${locationData.location.id}/${itemToEdit.id}`}
          withCredentials={true}
          name='media[]' // name 'media[]' is what is called in the api, also has to be array to handle multiple files
          accept='image/png'
          maxFileSize={3000000}
          emptyTemplate={<p>Drag and drop file here to upload.</p>}
          chooseOptions={{ style: { background: mainBlueBg, border: 'none' } }}
          uploadOptions={{ label: 'Update Icon', style: { background: mainBlueBg, border: 'none' } }}
          cancelOptions={{ style: { background: mainErrorBg, border: 'none' } }}
          onUpload={handleFileSuccessUploaded}
          onError={handleFileErrorUpload}
        />
      );
    } else {
      return (
        <FileUpload
          url={`${process.env.REACT_APP_API_BASE_URL_V2}/whiteboard/map/upload-new-icons`}
          withCredentials={true}
          name='media[]' // name 'media[]' is what is called in the api, also has to be array to handle multiple files
          accept='image/png'
          maxFileSize={1000000}
          multiple
          emptyTemplate={<p>Drag and drop file(s) here to upload.</p>}
          chooseOptions={{ style: { background: mainBlueBg, border: 'none' } }}
          uploadOptions={{ label: 'Upload New Icon(s)', style: { background: mainBlueBg, border: 'none' } }}
          cancelOptions={{ style: { background: mainErrorBg, border: 'none' } }}
          onUpload={handleFileSuccessUploaded}
          onError={handleFileErrorUpload}
        />
      );
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} pt={2}>
      {itemToEdit ? (
        <Stack direction={'row'} spacing={1}>
          <Typography>Updating the icon: </Typography>
          <Typography fontWeight={'bold'}>{itemToEdit.name}</Typography>
        </Stack>
      ) : (
        <Typography>Upload New Icon(s)</Typography>
      )}
      {getForm()}
    </Box>
  );
};

export default IconForm;
