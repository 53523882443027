import html2canvas from 'html2canvas';

const createImageFromHtml = async (element: HTMLInputElement) => {
  const canvas = await html2canvas(element, {
    width: 550,
    height: 100,
    scale: 1,
    backgroundColor: 'transparent',
  });

  const image = canvas.toDataURL('image/png', 1.0);
  const stringToSend = image.replace('data:image/png;base64,', ''); // remove beginning of base64 string
  return stringToSend;
};

export default createImageFromHtml;
