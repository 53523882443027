import PersonIcon from '@mui/icons-material/Person';
import { Button, Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { decrypt } from '../../utils/cryptojs-helper';
import Header from '../common/header';

const Profile = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);

  return (
    <Card sx={{ maxWidth: 1000, margin: '0 auto' }}>
      <Header text='My Profile' Icon={<PersonIcon />} />
      <Box>
        <Typography>
          <strong>Email:</strong> {user.email}
        </Typography>
        <Typography>
          <strong>Name:</strong> {user.firstName} {user.lastName}
        </Typography>
        <Typography>
          <strong>Clients:</strong>
          {user.clients.map((client: { client: { id: string; name: string } }) => (
            <span key={client.client.id}> {client.client.name}</span>
          ))}
        </Typography>
      </Box>
      <Link to={`/users/${user.id}`} style={{ textDecoration: 'none', marginTop: 5 }}>
        <Button variant='outlined'>Update Info</Button>
      </Link>
    </Card>
  );
};

export default Profile;
