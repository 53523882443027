import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { API_WHITEBOARD_APP_LOCATION } from '../../api/whiteboard/map/location';
import Loader from '../../components/common/loader';
import MapWhiteboard from '../../components/map-whiteboard/MapWhiteboard';

const MapWhiteboardPage = () => {
  const { error: locationError, data: locationData } = useQuery(
    ['clientLocation'],
    API_WHITEBOARD_APP_LOCATION.getSingleLocation
  );
  if (locationError) return <Typography>Error fetching location information</Typography>;
  if (locationData) return <MapWhiteboard locationData={locationData} />;
  return <Loader />;
};

export default MapWhiteboardPage;
