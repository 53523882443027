import { Button, Card } from '@mui/material';
import { Box } from '@mui/system';
import BuildIcon from '@mui/icons-material/Build';
import { useState } from 'react';
import StepOne from './components/StepOne';
import { Dimensions } from './types';
import StepTwo from './components/step-two/StepTwo';
import fileDownload from 'js-file-download';
import Preview from './components/Preview';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Header from '../common/header';

const WeatherWidget = () => {
  const [steps, setSteps] = useState(1);
  const [location, setLocation] = useState('');
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 300,
    height: 500,
  });
  const [showCity, setShowCity] = useState(true);
  const [showFiveDay, setShowFiveDay] = useState(true);

  const [html, setHtml] = useState('');

  const [coords, setCoords] = useState<number[]>([]);

  const [zipCode, setZipCode] = useState('');

  const [customer, setCustomer] = useState('');

  const navigate = useNavigate();

  const downloadHtml = () => {
    fileDownload(html, 'weather-widget.html');
    toast.success('File Downloaded');
    setTimeout(() => {
      navigate('/dashboard');
    }, 2000);
  };

  return (
    <Box maxWidth={800} marginX='auto' alignItems='center'>
      {(steps === 1 || steps === 2) && (
        <Card>
          <Header text='Widget Configuration' Icon={<BuildIcon />} />
          {steps === 1 && (
            <StepOne
              location={location}
              setLocation={setLocation}
              setSteps={setSteps}
              setCoords={setCoords}
              customer={customer}
              setCustomer={setCustomer}
              setZipCode={setZipCode}
            />
          )}

          {steps === 2 && (
            <StepTwo
              dimensions={dimensions}
              setDimensions={setDimensions}
              setSteps={setSteps}
              location={location}
              setLocation={setLocation}
              showCity={showCity}
              setShowCity={setShowCity}
              showFiveDay={showFiveDay}
              setShowFiveDay={setShowFiveDay}
              setHtml={setHtml}
              coords={coords}
              zipCode={zipCode}
              customer={customer}
            />
          )}
        </Card>
      )}
      {steps === 3 && (
        <>
          <Preview dimensions={dimensions} showFiveDay={showFiveDay} showCity={showCity} location={location} />
          <Button variant='contained' color='error' endIcon={<FileDownloadIcon />} onClick={() => downloadHtml()}>
            Download HTML
          </Button>
        </>
      )}
    </Box>
  );
};

export default WeatherWidget;
