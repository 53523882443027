import { Grid } from '@mui/material';
import Carousel from '../carousel';
import QuickLinks from '../quick-links';
import Support from '../support';
import s from './styles.module.scss';

const Dashboard = () => {
  return (
    <Grid container spacing={2} rowGap={15}>
      <Grid item xs={12} lg={8} height={300} className={s.carousel}>
        <Carousel />
      </Grid>
      <Grid item xs={12} lg={4} height={300}>
        <QuickLinks />
      </Grid>
      <Grid item xs={12} lg={6} height={300} mb={15}>
        <Support />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
