import { CloseRounded } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { fabric } from 'fabric';
import { Canvas, Image } from 'fabric/fabric-impl';
import { MutableRefObject, useEffect, useState } from 'react';
import { API_DS_MEDIA } from '../../../../api/digital-signage-cms/media';
import { MediaGetData } from '../../../../api/digital-signage-cms/types';
import { useAuthContext } from '../../../../context/AuthContext';
import { decrypt } from '../../../../utils/cryptojs-helper';
import Loader from '../../../common/loader';
import AssetUploadFile from '../../../upload-file/AssetUploadFile';
import s from '../styles/image.module.scss';

const ImageModule = ({
  canvas,
  handleClose,
}: {
  canvas: MutableRefObject<Canvas | undefined>;
  handleClose: () => void;
}) => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const userJSON = JSON.parse(decrypted);
  const { currentClient } = userJSON;

  //   const url = `/media/get/${clientId}`; // url for get all media for client
  //   const { error, data, refetch } = useQuery(['GetAllMedia', url], API_DS_MEDIA.);
  const { error, data, refetch } = useQuery(['allClientMedia'], () =>
    API_DS_MEDIA.getAllMedia(currentClient.client.id)
  );

  const [selectedMedia, setSelectedMedia] = useState<number>(-1);
  const [assetsMedia, setAssetsMedia] = useState<MediaGetData[]>([]);

  const handleMediaSelected = (index: number) => {
    if (index !== selectedMedia) setSelectedMedia(index);
    else setSelectedMedia(-1);
  };

  const handleSelectBtnClick = () => {
    if (assetsMedia && selectedMedia != -1) {
      const url = assetsMedia[selectedMedia].bucketURL;
      const screenHeight = window.innerHeight * 0.85;
      const screenWidth = window.innerWidth * 0.85;

      // TODO: scaling for the image
      fabric.Image.fromURL(
        url,
        function (img: Image) {
          if (img != undefined) {
            // scaling
            if ((img.height && img.height > screenHeight) || (img.width && img.width > screenWidth)) {
              img.scale(0.5);
            }

            canvas.current?.add(img);
            canvas.current?.renderAll();
          }
        },
        { crossOrigin: 'Anonymous' }
      );

      handleClose();
    }
  };

  useEffect(() => {
    if (data) {
      const onlyAssets = data.filter((media) => {
        return media.type === 'asset';
      });
      setAssetsMedia(onlyAssets);
    }
  }, [data]);

  if (error) return <p>error</p>;
  if (assetsMedia)
    return (
      <div className={s.imageFormContainer}>
        <button className={s.closeBtn} onClick={handleClose}>
          <CloseRounded />
        </button>
        <div className={s.content}>
          <div className={s.mediaListWrp}>
            {assetsMedia && assetsMedia.length > 0 ? (
              <>
                <ul className={s.mediaList}>
                  {assetsMedia.map((d, index) => {
                    return (
                      <li
                        className={s.imgListItem}
                        key={`media-img-${index}`}
                        onClick={() => handleMediaSelected(index)}
                        style={{ border: index == selectedMedia ? '2px solid #ffffff' : 'none' }}
                      >
                        <p title={d.name}>{d.name}</p>
                        <img className={s.mediaImg} src={d.previewBucketURL} alt={d.name} title={d.name} />
                      </li>
                    );
                  })}
                </ul>
                <button onClick={handleSelectBtnClick} disabled={selectedMedia == -1 ? true : false}>
                  SELECT
                </button>
              </>
            ) : (
              <Typography textAlign={'center'} color={'#fff'} fontWeight={'bold'}>
                You have no assets
              </Typography>
            )}
          </div>

          <Box>
            {userJSON.permissions[userJSON.currentClient.client.name].includes('media-create') ? (
              <AssetUploadFile callback={refetch} />
            ) : (
              <Typography color={'#fff'} textAlign={'center'}>
                No Permission to Upload Media
              </Typography>
            )}
          </Box>
        </div>
      </div>
    );
  return <Loader />;
};

export default ImageModule;
