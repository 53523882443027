import toast from 'react-hot-toast';
import axios from '../../../config/axiosV2';
import { NewPresentation, PresentationDetails } from '../types';

/**
 * This function is called when a User submits the 'New Presenation' form.
 * @param presentationData - presentation name, description, zones (with playlist)
 * @param clientId - the current client the User is viewing
 * @returns - false if FAIL / presentation details if successful
 */
const create = async (presentationData: NewPresentation, clientId: string): Promise<boolean> => {
  try {
    const res = await axios.post(
      '/digitalsignage/presentation',
      { ...presentationData },
      {
        headers: {
          ccid: clientId,
        },
      }
    );
    const { data } = res;
    // we need to check if there are any warning messages
    if ('warnings' in data) {
      data.warnings.forEach(({ message }: { name: string; message: string }) => {
        toast.error(message, { duration: 10000 });
      });
    }
    toast.success('Presentation Created Successfully');
    const { presentation } = data;
    return presentation;
  } catch {
    toast.error('Error - Failed to create presentation. Please Try Again.');
    return false;
  }
};

/**
 * This function is called when a User clicks 'edit', triggering a form, and then submit
 * via the 'edit' button of the form.
 * @param presentationData - new data for the presentation
 * @param clientId - the clientid for the current client being viewed
 * @returns false - FAIL / new presentation details SUCCESS
 */
const edit = async (presentationData: PresentationDetails, clientId: string): Promise<boolean> => {
  try {
    const res = await axios.post(
      '/digitalsignage/presentation/edit',
      { ...presentationData },
      {
        headers: {
          ccid: clientId,
        },
      }
    );
    const { data } = res;
    // we need to check if there are any warning messages
    if ('warnings' in data) {
      data.warnings.forEach(({ message }: { name: string; message: string }) => {
        toast.error(message, { duration: 10000 });
      });
    }
    toast.success('Presentation Created Successfully');
    const { presentation } = data;
    return presentation;
  } catch {
    toast.error('Error - Failed to create presentation. Please Try Again.');
    return false;
  }
};

/**
 * This function is called when a user visits the Presentation page.
 * Before loading that page all presentations for a User current client need
 * to be fetched.
 * @param clientId - the current client to fetch Presentations for
 * @returns false if failure. List of all presentations if successful.
 */
const getAllPresentations = async (clientId: string) => {
  try {
    const { data } = await axios.get('/digitalsignage/presentation/all', {
      headers: {
        ccid: clientId,
      },
    });
    const { presentations }: { presentations: PresentationDetails[] } = data;
    return presentations;
  } catch {
    toast.error('Error - Failed to get all presentations');
    return false;
  }
};

const deletePresentation = async (presentationId: string) => {
  try {
    await axios.post(`/digitalsignage/presentation/delete/${presentationId}`);
    return true;
  } catch {
    toast.error('Error - Failed to delete presentations');
    return false;
  }
};
export { create, deletePresentation, edit, getAllPresentations };
