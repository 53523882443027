import { Box, Button, Card, CardHeader, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Link as ScrollLink } from 'react-scroll';
import { useAuthContext } from '../../../../context/AuthContext';
import { decrypt } from '../../../../utils/cryptojs-helper';
import { API_LANE_MANAGEMENT } from '../../api';
import { useLaneContext } from '../../context/LaneContext';
import s from './styles.module.scss';

const CurrentlyPlaying = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);
  const currentClient = user.currentClient.client.name.toLowerCase();
  const { screen, setColumns, setIcons } = useLaneContext();

  const { data } = useQuery(
    ['GetCurrentlyPlaying', { client: currentClient, screen }],
    API_LANE_MANAGEMENT.getCurrentlyPlaying
  );

  const mapToLayout = () => {
    if (data) {
      setColumns(data[0].columns);
      setIcons(data[1].icons);
    }
  };

  return (
    <Card className={s.container}>
      <CardHeader
        disableTypography
        title={
          <Box display='flex' alignItems='center'>
            Currently Playing
            <Box className={s.live}>
              {/* used for live animation */}
              <div></div>
            </Box>
          </Box>
        }
      />
      <img
        src={
          `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_ENV}/lane-management/${currentClient}/${screen}/published-full-width.png?` +
          new Date().getTime() // cache breaker
        }
        alt='Currently Playing'
      />
      <Stack direction='row' justifyContent='center'>
        <ScrollLink to='custom-layout' smooth duration={2000}>
          <Button onClick={mapToLayout}>Map To Custom Layout</Button>
        </ScrollLink>
      </Stack>
    </Card>
  );
};

export default CurrentlyPlaying;
