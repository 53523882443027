import { Stack, Typography } from '@mui/material';

const LogTable = ({ logs }: { logs: string }) => {
  const logsRowData = logs.split('\n').reverse();

  return (
    <Stack maxHeight={{ xs: '65vh' }} overflow={'scroll'}>
      {logsRowData.map((log, index) => {
        return (
          <Typography key={index} width={'100%'} borderBottom={'1px solid #000'} minWidth={'1200px'}>
            {index + 1}. {log}
          </Typography>
        );
      })}
    </Stack>
  );
};

export default LogTable;
