import { Box, Typography } from '@mui/material';
import LocationTable from './components/LocationTable';
import NewLocation from './components/NewLocation';
import { Location } from './types';

type Props = {
  locations: Location[];
};

const Locations = ({ locations }: Props) => {
  return locations.length > 0 ? (
    <Box p={1} bgcolor={'#fff'}>
      <Typography variant='h4' mb={2} mt={2}>
        Locations
      </Typography>
      <LocationTable locations={locations} />
    </Box>
  ) : (
    <NewLocation />
  );
};

export default Locations;
