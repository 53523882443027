import toast from 'react-hot-toast';
import axios from '../../config/axiosV2';
import checkIfEmptyString from '../../utils/check-if-empty';

const getLogsForSystem = async (fileName: string): Promise<boolean | undefined> => {
  if (checkIfEmptyString(fileName)) {
    return false;
  } else {
    const formattedFileName = fileName.toLowerCase().split(' ').join('-');
    const url = `/logging/${formattedFileName}`;
    try {
      const res = await axios.get(url);
      // will be empty string if no logs
      const { data } = res;
      return data;
    } catch (err) {
      toast.error('Error fetching logs');
      return false;
    }
  }
};

const uploadLogsToS3Bucket = async () => {
  try {
    const url = '/logging/upload-to-s3bucket';
    const res = await axios.post(url);
    const { data } = res;
    return data;
  } catch (err) {
    toast.error('Failed to upload logs to s3Bucket');
    return false;
  }
};

export { getLogsForSystem, uploadLogsToS3Bucket };
