import Add from '@mui/icons-material/Add';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { API_PLAYLIST } from '../../../api/digital-signage-cms/playlist';
import { MediaInPlaylist, PlaylistData } from '../../../api/digital-signage-cms/types';
import { UserAuthData } from '../../../api/user/types';
import ConfirmDialog from '../../common/ConfirmDialog';
import s from '../add-playlist/styles.module.scss';
import EditMediaDialog from './components/EditMediaDialog';
import EditMediaSelectedCard from './components/EditMediaSelectedCard';
import TableRowData from './components/TableRowData';

type Props = {
  playlist: PlaylistData;
  user: UserAuthData;
};

const EditPlaylistView = ({ playlist, user }: Props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const mainBlue = theme.palette.primary.main;
  const lightGray = theme.palette.secondary.light;
  const infoGray = theme.palette.info.light;
  const todaysDate = new Date();
  const [confirmationDialogInView, setConfirmationDialogInView] = useState<boolean>(false);
  // state data
  const [playlistName, setName] = useState<string>(playlist.name);
  const [validityStartDate, setValidityStartDate] = useState<string | Date>('');
  const [validityEndDate, setValidityEndDate] = useState<string | Date>('');
  const [finalizedMediaSelected, setFinalizedMediaSelected] = useState<MediaInPlaylist[]>([...playlist.media]);
  // input errors stats
  const [playlistNameError, setPlaylistNameError] = useState<{ isError: boolean; message: string }>({
    isError: false,
    message: '',
  });
  const [validityStartDateError, setValidityStartDateError] = useState<{ isError: boolean; message: string }>({
    isError: false,
    message: '',
  });
  const [validityEndDateError, setValidityEndDateError] = useState<{ isError: boolean; message: string }>({
    isError: false,
    message: '',
  });
  // Boolean states
  const [hasValidityDate, setHasValidityDate] = useState<boolean>(false);
  const [mediaDialogInView, setMediaDialogInView] = useState<boolean>(false);
  const [applyToAllBtn, setApplyToAllBtn] = useState<boolean>(false);
  // this function used to make the confirmation dialog pop up
  const handleEditClick = (res: boolean) => {
    if (res) {
      handlePlaylistEdit();
    }
    setConfirmationDialogInView(false);
  };
  // Creation of the playlist
  // validations will be done before submitting
  const handlePlaylistEdit = async () => {
    let errorCount = 0;
    const _playlistNameError = { isError: false, message: '' };
    const _validityStartDateError = { isError: false, message: '' };
    const _validityEndDateError = { isError: false, message: '' };
    // name validition
    if (playlistName.length < 3) {
      errorCount++;
      _playlistNameError.isError = true;
      _playlistNameError.message = 'Playlist name be greater than 3 characters';
    }
    // check to see first if we have a value for both start and end date
    if (validityStartDate && validityEndDate) {
      const _startDate = new Date(validityStartDate);
      const _endDate = new Date(validityEndDate);
      if (_startDate > _endDate) {
        errorCount++;
        _validityStartDateError.isError = true;
        _validityStartDateError.message = 'Start date must occur before end date';
        _validityEndDateError.isError = true;
        _validityEndDateError.message = 'End date must occur after start date';
      }
    } else {
      // validation date validations
      // if there is an end date then we need to make sure the date has not passed
      if (validityEndDate) {
        const _endDate = new Date(validityEndDate);
        if (_endDate < todaysDate) {
          errorCount++;
          _validityEndDateError.isError = true;
          _validityEndDateError.message = 'End date cannot be in the past.';
        }
      }
    }

    // has not
    if (errorCount == 0) {
      const editedPlaylistData = { ...playlist };
      editedPlaylistData.name = playlistName;
      editedPlaylistData.media = finalizedMediaSelected;
      editPresentationMutation.mutate({ editedPlaylist: editedPlaylistData, clientId: user.currentClient.client.id });
    }
    setValidityStartDateError(_validityStartDateError);
    setValidityEndDateError(_validityEndDateError);
    setPlaylistNameError(_playlistNameError);
  };

  // Query Code to update the local cache
  const editPresentationMutation = useMutation({
    mutationFn: ({ editedPlaylist, clientId }: { editedPlaylist: PlaylistData; clientId: string }) =>
      API_PLAYLIST.edit({ editedPlaylistData: editedPlaylist, clientId: clientId }),
    // When mutate is called:
    onMutate: async ({ editedPlaylist }: { editedPlaylist: PlaylistData; clientId: string }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['AllUserPlaylist'] });
      // Snapshot the previous value
      const previousPlaylist = queryClient.getQueryData<PlaylistData[]>(['AllUserPlaylist']);
      // Optimistically update to the new value
      if (previousPlaylist) {
        const _previousPlaylist = previousPlaylist.map((playlist: PlaylistData) => {
          if (playlist.id == editedPlaylist.id) {
            return editedPlaylist;
          } else return playlist;
        });
        queryClient.setQueryData<PlaylistData[]>(['AllUserPlaylist'], _previousPlaylist);
      }
      // Return a context object with the snapshotted value
      return { previousPlaylist };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      if (context && context.previousPlaylist) {
        const _previousPlaylist = context.previousPlaylist.map((playlist: PlaylistData) => {
          if (playlist.id == variables.editedPlaylist.id) {
            return variables.editedPlaylist;
          } else return playlist;
        });
        queryClient.setQueryData<PlaylistData[]>(['AllUserPlaylist'], _previousPlaylist);
        queryClient.setQueryData<PlaylistData>(
          ['playlistDetails', variables.editedPlaylist.id],
          variables.editedPlaylist
        );
      }
      toast.success('Playlist created succesfully');
      navigate('/digital-signage/playlist');
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newPresentation, context) => {
      if (context?.previousPlaylist) {
        queryClient.setQueryData(['allClientPresentations'], context.previousPlaylist);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['allClientPresentations'] });
    },
  });

  /**
   * This function is passed to the child component. The child component will
   * pass the list of media that was selected from the popup.
   * This function will keep an original copy of the media and then set the final
   * media list with the final position and duration of each media
   * @param mediaSelected : list of the media selected
   */
  const updateMediaSelectedFromChild = (mediaSelected: MediaInPlaylist[]) => {
    // we need to check to see if there are any validity dates for already selected media
    if (hasValidityDate && finalizedMediaSelected.length > 0) {
      // iterate through new media list and check if they have a validity date
      for (let i = 0; i < mediaSelected.length; i++) {
        const tempMedia = mediaSelected[i];
        const tempMediaIndex = finalizedMediaSelected.findIndex((m) => m.mediaId === tempMedia.mediaId);
        if (tempMediaIndex > -1) {
          if (finalizedMediaSelected[tempMediaIndex].validityStartDate) {
            tempMedia.validityStartDate = finalizedMediaSelected[tempMediaIndex].validityStartDate;
          }
          if (finalizedMediaSelected[tempMediaIndex].validityEndDate) {
            tempMedia.validityEndDate = finalizedMediaSelected[tempMediaIndex].validityEndDate;
          }
        }
      }
      setFinalizedMediaSelected(mediaSelected);
    } else {
      setFinalizedMediaSelected(mediaSelected);
    }
  };
  // handling attaching the validity dates to a media that
  // has its checkbox selected
  const addValidityDatesToMedia = (index: number, hasDates: boolean) => {
    const _finalizedMediaSelected = [...finalizedMediaSelected];
    if (hasDates) {
      if (validityStartDate) {
        _finalizedMediaSelected[index].validityStartDate = validityStartDate;
      }
      if (validityEndDate) {
        _finalizedMediaSelected[index].validityEndDate = validityEndDate;
      }
    } else {
      _finalizedMediaSelected[index].validityStartDate = '';
      _finalizedMediaSelected[index].validityEndDate = '';
    }
    setFinalizedMediaSelected(_finalizedMediaSelected);
  };

  // handling the duration changes for media
  const handleMediaDurationChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const _finalizedMediaSelected = [...finalizedMediaSelected];
    if (event.target.value.length === 0) {
      _finalizedMediaSelected[index].duration = '';
      setFinalizedMediaSelected(_finalizedMediaSelected);
    } else {
      const parsedValue = parseInt(event.target.value);
      if (!isNaN(parsedValue)) {
        _finalizedMediaSelected[index].duration = parsedValue;
        setFinalizedMediaSelected(_finalizedMediaSelected);
      }
    }
  };

  const handleValidityDateChange = (type: number, value: string) => {
    // if 1 -> end Date
    if (type) {
      if (value) {
        setValidityEndDate(value);
      } else {
        setValidityEndDate('');
      }
    } else {
      if (value) {
        setValidityStartDate(value);
      } else {
        setValidityStartDate('');
        setValidityEndDate('');
      }
    }
  };

  const handleRowDeletion = (index: number) => {
    if (finalizedMediaSelected.length === 1) {
      setFinalizedMediaSelected([]);
    } else {
      // const _finalizedMediaSelected = [...finalizedMediaSelected]
      const _finalizedMediaSelected = [...finalizedMediaSelected];
      _finalizedMediaSelected.splice(index, 1);
      setFinalizedMediaSelected(_finalizedMediaSelected);
    }
  };

  // DRAGGABLE FUNCTIONS
  // saving references for dragItem and dragOverItem
  // eslint-disable-next-line
  const dragItem = useRef<any>(null);
  // eslint-disable-next-line
  const dragOverItem = useRef<any>(null);

  // handling sorting
  const handleSort = () => {
    // duplicate items
    const _playlistSlides = [...finalizedMediaSelected];
    // remove and save the dragged item content
    const draggedItemContent = _playlistSlides.splice(dragItem.current, 1)[0];
    // switch the position
    _playlistSlides.splice(dragOverItem.current, 0, draggedItemContent);
    // reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;
    // update the actual array
    setFinalizedMediaSelected(_playlistSlides);
  };

  // inital load we need to check if there are any validity dates applied
  useEffect(() => {
    if (playlist) {
      let hasValidityApplied = false;
      playlist.media.map((media) => {
        if (media.validityStartDate || media.validityEndDate) {
          hasValidityApplied = true;
        }
      });
      setHasValidityDate(hasValidityApplied);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      maxWidth={{ xs: '310px', sm: '768px', md: '830px', lg: '1600px' }}
      height={{ xs: '86vh', md: '82vh' }}
      overflow={'scroll'}
      bgcolor={'#fff'}
      borderRadius={2}
      sx={{
        '&::-webkit-scrollbar': { display: 'none' },
        overflowX: 'scroll',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      <Grid container height={{ xs: '6vh', md: '10vh' }} bgcolor={lightGray}>
        <Grid item xs={9} display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'} justifyContent={'left'}>
          <Typography fontWeight={'bold'} pl={{ xs: 1, md: 2 }}>
            Edit Playlist
          </Typography>
          <FeaturedPlayListIcon />
        </Grid>
      </Grid>
      <Stack direction={{ xs: 'column', md: 'row' }}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={{ xs: '100%', md: '30%' }}
          gap={2}
          pt={{ xs: 2, sm: 4, md: 1 }}
          pb={{ xs: 2, sm: 4, md: 0 }}
          pl={{ xs: 1, sm: 14, md: 1 }}
          pr={{ xs: 1, sm: 14, md: 1 }}
          borderBottom={{ xs: `2px solid ${lightGray}`, md: 'none' }}
        >
          <Box display={'flex'} flexDirection={'column'}>
            <FormLabel>Name</FormLabel>
            <TextField
              value={playlistName}
              onChange={(event) => setName(event.target.value)}
              error={playlistNameError.isError}
              helperText={playlistNameError.message}
              fullWidth
            />
          </Box>
          <Grid container>
            <Grid item xs={8}>
              <FormLabel>Media</FormLabel>
            </Grid>
            <Grid item xs={4}>
              <Button sx={{ backgroundColor: mainBlue }} onClick={() => setMediaDialogInView(true)}>
                <Add htmlColor='#fff' />
              </Button>
            </Grid>
          </Grid>
          <Box>
            <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <FormControlLabel
                control={<Checkbox checked={hasValidityDate} onChange={() => setHasValidityDate(!hasValidityDate)} />}
                label='Include validity date'
              />
              <Tooltip title='Validity date is applied to each individual selected image'>
                <InfoIcon htmlColor={mainBlue} fontSize='small' />
              </Tooltip>
            </FormGroup>
          </Box>
          {hasValidityDate && (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Typography color={infoGray}>Select the media to apply validity date</Typography>
              <Grid container alignItems={'center'} justifyContent={'center'} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id={'validity-start-date'}
                    fullWidth
                    type={'date'}
                    value={validityStartDate}
                    error={validityStartDateError.isError}
                    helperText={validityStartDateError.isError ? validityStartDateError.message : 'Start Date'}
                    inputProps={{ min: todaysDate.toISOString().slice(0, 10) }}
                    onChange={(e) => handleValidityDateChange(0, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id={'validity-end-date'}
                    fullWidth
                    type={'date'}
                    value={validityEndDate}
                    error={validityEndDateError.isError}
                    helperText={validityEndDateError.isError ? validityEndDateError.message : 'End Date'}
                    inputProps={{ min: todaysDate.toISOString().slice(0, 10) }}
                    onChange={(e) => handleValidityDateChange(1, e.target.value)}
                  />
                </Grid>
                <Grid item xs={10} ml={1}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={applyToAllBtn} onChange={() => setApplyToAllBtn(!applyToAllBtn)} />}
                      label='Apply validity dates to all'
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Box>
          )}
          <Button variant='contained' color='error' onClick={() => navigate('/digital-signage/playlist')}>
            Cancel
          </Button>
        </Box>
        <Box display={'flex'} flexDirection={{ xs: 'column' }} width={{ xs: '100%', md: '70%' }} pt={2} gap={2} pb={2}>
          {matches ? (
            <TableContainer component={Paper} style={{ height: '62vh' }}>
              <Table sx={{ minWidth: { md: 600 } }} aria-label='media selected table'>
                <TableHead>
                  <TableRow style={{ backgroundColor: mainBlue }}>
                    {hasValidityDate ? (
                      <TableCell align='center' width={'10%'}>
                        <Typography color={'#fff'}>Apply Validity Date</Typography>
                      </TableCell>
                    ) : (
                      ''
                    )}
                    <TableCell align='center' width={'5%'}>
                      <Typography color={'#fff'}>Position</Typography>
                    </TableCell>
                    <TableCell align='center' width={'35%'}>
                      <Typography color={'#fff'}>Preview</Typography>
                    </TableCell>
                    <TableCell align='center' width={'25%'}>
                      <Typography color={'#fff'}>Title</Typography>
                    </TableCell>
                    <TableCell align='center' width={'30%'}>
                      <Typography color={'#fff'}>Duration (secs)</Typography>
                    </TableCell>
                    {hasValidityDate ? (
                      <>
                        <TableCell align='center' width={'15%'}>
                          <Typography color={'#fff'}>Start Date</Typography>
                        </TableCell>
                        <TableCell align='center' width={'15%'}>
                          <Typography color={'#fff'}>End Date</Typography>
                        </TableCell>
                      </>
                    ) : (
                      ''
                    )}
                    <TableCell align='center' width={'5%'}>
                      <Typography color={'#fff'}>Actions</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {finalizedMediaSelected.length > 0 ? (
                    finalizedMediaSelected.map((media: MediaInPlaylist, index: number) => {
                      return (
                        <TableRow
                          key={`media-${index}`}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          draggable
                          onDragStart={() => (dragItem.current = index)}
                          onDragEnter={() => (dragOverItem.current = index)}
                          onDragEnd={handleSort}
                          onDragOver={(e) => e.preventDefault()}
                          className={s.grabbable}
                        >
                          <TableRowData
                            includeValidityDate={hasValidityDate}
                            index={index}
                            mediaDetails={media}
                            handleRowDeletion={handleRowDeletion}
                            addValidityDates={addValidityDatesToMedia}
                            handleMediaDurationChange={handleMediaDurationChange}
                          />
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell width={'15%'}></TableCell>
                      <TableCell width={'15%'}></TableCell>
                      <TableCell width={'40%'}>
                        <Typography textAlign={'center'}>No currently selected media</Typography>
                      </TableCell>
                      <TableCell width={'15%'}></TableCell>
                      <TableCell width={'15%'}></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : finalizedMediaSelected.length > 0 ? (
            finalizedMediaSelected.map((media: MediaInPlaylist, index) => {
              return (
                <Box
                  key={`${media.media?.name}-media`}
                  draggable
                  onDragStart={() => (dragItem.current = index)}
                  onDragEnter={() => (dragOverItem.current = index)}
                  onDragEnd={handleSort}
                  onDragOver={(e) => e.preventDefault()}
                  className={s.grabbable}
                  width={{ xs: '100%', sm: '60%' }}
                  ml={{ xs: 0, sm: '20%' }}
                  p={1}
                >
                  <EditMediaSelectedCard
                    includeValidityDate={hasValidityDate}
                    index={index}
                    mediaDetails={media}
                    addValidityDates={addValidityDatesToMedia}
                    handleMediaDurationChange={handleMediaDurationChange}
                  />
                </Box>
              );
            })
          ) : (
            <Typography textAlign={'center'} pt={1} pb={1}>
              No Currently Selected Media
            </Typography>
          )}
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'} pt={1} pb={1}>
            <Button
              sx={{ backgroundColor: theme.palette.primary.main }}
              onClick={() => setConfirmationDialogInView(true)}
            >
              <Typography color={'#fff'}>Edit</Typography>
            </Button>
          </Box>
        </Box>
      </Stack>
      {mediaDialogInView && (
        <EditMediaDialog
          playlistId={playlist.id}
          handleClose={() => setMediaDialogInView(false)}
          updateSelectedMedia={updateMediaSelectedFromChild}
          playlistSlides={finalizedMediaSelected}
        />
      )}
      {confirmationDialogInView && (
        <ConfirmDialog
          title={'Please confirm the following actions'}
          message={'Are you sure you would like to finalize these edits?'}
          handleClick={handleEditClick}
        />
      )}
    </Box>
  );
};

export default EditPlaylistView;
