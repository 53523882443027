import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeIcon from '@mui/icons-material/Badge';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { Autocomplete, Box, Button, Card } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_CLIENT } from '../../api/client';
import { ClientSingleData, ClientUpdateData } from '../../api/client/types';
import rolesData from '../../utils/roles-data';
import { formatClientNameToDisplay } from '../clients-table/utils/create-data';
import BaseInput from '../common/BaseInput';
import Header from '../common/header';
import Modal from '../common/modal';
import s from './styles.module.scss';
import { Props } from './types';

interface Map {
  [key: number]: string | undefined;
}
const LABELS: Map = {
  4000: 'Admin',
  2000: 'Users',
  5001: 'Lane-Management',
  5002: 'Weather-Widget',
  5003: 'Content-Management',
  5004: 'Content-Creation',
  5005: 'Menu-Management',
  5006: 'Whiteboard-Management',
};

const SingleClient = ({ data, refetch }: Props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  // const [clientData, setClientData] = useState<ClientGetData>(data);
  const rolesLabeled = data.roles.map((role) => {
    const c = role.role.code;
    const label = LABELS[c] || '';

    return { label, id: c };
  });

  const dataWithRoles: ClientSingleData = {
    id: data.id,
    name: formatClientNameToDisplay(data.name),
    roles: rolesLabeled,
  };
  const [clientData, setClientData] = useState<ClientSingleData>(dataWithRoles);

  const update = async () => {
    const rolesList = clientData.roles.map((role) => {
      return role.id;
    });
    const clientUpdated: ClientUpdateData = {
      id: clientData.id,
      name: clientData.name,
      roles: rolesList,
    };
    const success = await API_CLIENT.update(clientUpdated);
    success ? navigate('/clients') : '';
    refetch();
  };

  // fn passed to modal
  const deleteClient = async () => {
    const success = await API_CLIENT.remove(clientData.id);
    success ? navigate('/clients') : '';
  };

  return (
    <Box className={s.container}>
      <Box width='100%' display='flex' justifyContent='space-between'>
        <Link to='/clients' style={{ textDecoration: 'none' }}>
          <Button color='warning' endIcon={<ArrowBackIcon />}>
            Cancel
          </Button>
        </Link>
        <Button color='error' sx={{ alignSelf: 'flex-end' }} onClick={() => setIsOpen(true)}>
          Delete Client
        </Button>
      </Box>
      <Card>
        <Header text='Update Name' Icon={<BadgeIcon />} />
        <BaseInput
          label='Name'
          value={clientData.name}
          onChange={(e) => setClientData({ ...clientData, name: e.target.value })}
        />
      </Card>
      <Card sx={{ marginTop: 2, marginBottom: 1 }}>
        <Header text='Update Roles' Icon={<FactCheckIcon />} />
        <Autocomplete
          value={clientData.roles}
          onChange={(_, roles) => setClientData({ ...clientData, roles })}
          fullWidth
          multiple
          options={rolesData}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions
          renderInput={(params) => <BaseInput {...params} label='Pick Roles' />}
        />
      </Card>
      <Button variant='contained' size='small' className={s.button} onClick={() => update()}>
        Update
      </Button>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} fn={deleteClient} />
    </Box>
  );
};

export default SingleClient;
