import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { API_CLIENT } from '../../../api/client';
import { API_PLAYER_MANAGEMENT } from '../../../api/player-management';
import { NewPlayer, Player } from '../../../api/player-management/types';
import ConfirmDialog from '../../common/ConfirmDialog';
import Loader from '../../common/loader/Loader';

type Props = {
  handleClose: () => void;
};

const PlayerModal = ({ handleClose }: Props) => {
  const url = '/client'; // url for get all clients
  const queryClient = useQueryClient();
  const { error, data } = useQuery(['GetAllClients', url], API_CLIENT.getAll);
  // we need to fetch all the clients
  const [confirmationDialogInview, setConfirmationDialogInview] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  // states for inputs
  const [name, setName] = useState<string>('');
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [locatedAt, setLocatedAt] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<string>('');

  const handleCloseClick = (res: boolean) => {
    if (res) handleClose();
    setConfirmationDialogInview(false);
  };
  const handleClientChange = (event: SelectChangeEvent) => {
    setSelectedClient(event.target.value as string);
  };
  const handleCreateClick = async () => {
    setCurrentStep(1);
    if (data) {
      console.log(data);
      const clientIndex = data.findIndex((client) => client.name === selectedClient);
      console.log(clientIndex);
      let clientId = null;
      if (clientIndex > -1 && data) {
        console.log('getting ID');
        clientId = data[clientIndex].id;
      }
      console.log(clientId);
      if (clientId) {
        const playerData: NewPlayer = {
          name,
          locatedAt,
          serialNumber,
          clientId: clientId,
        };
        updatePlayerMutation.mutate(playerData);
      } else {
        toast.error('Failed to find selected client');
        setCurrentStep(0);
      }
    } else {
      toast.error('You can not create a player with no client connected');
    }
  };

  const updatePlayerMutation = useMutation({
    mutationFn: (newPlayer: NewPlayer) => API_PLAYER_MANAGEMENT.createPlayer(newPlayer),
    // When mutate is called:
    onMutate: async (newPlayer: NewPlayer) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['AllPlayers'] });
      // Snapshot the previous value
      const previousPlayers = queryClient.getQueryData<Player[]>(['AllPlayers']);
      if (previousPlayers) {
        // iterate through and find the menu that was updated
        const _allPlayers = [...previousPlayers];
        const _newPlayer = {
          id: '',
          name: newPlayer.name,
          serialNumber: newPlayer.serialNumber,
          locatedAt: newPlayer.locatedAt,
          client: {
            id: newPlayer.clientId,
            name: '',
          },
          location: null,
          menu: {
            id: '',
            name: '',
          },
          updatedAt: new Date(),
        };
        _allPlayers.push(_newPlayer);
        queryClient.setQueryData<Player[]>(['AllPlayers'], _allPlayers);
      }
      // Return a context object with the snapshotted value
      return { previousPlayers };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      if (context && context.previousPlayers) {
        const returnedPlayer: Player = { ...data };
        const _allPlayers = [...context.previousPlayers];
        _allPlayers.push(returnedPlayer);
        // Replace optimistic todo in the todos list with the result
        queryClient.setQueryData<Player[]>(['AllPlayers'], _allPlayers);
        handleClose();
        toast.success('Successfully created new player.');
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newPlayer, context) => {
      if (context?.previousPlayers) {
        queryClient.setQueryData(['AllPlayers'], context.previousPlayers);
        setCurrentStep(0);
        toast.error('Failed to create player');
      }
    },
  });

  return (
    <>
      <Dialog open>
        <DialogTitle textAlign={'center'}>Add New Player</DialogTitle>
        <DialogContent>
          {currentStep === 0 ? (
            <Stack spacing={2} p={1}>
              {error ? (
                <Typography textAlign={'center'}>Failed to fetch clients</Typography>
              ) : data ? (
                <>
                  <TextField
                    label={'name'}
                    value={name}
                    onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setName(e.target.value)}
                  />
                  <TextField
                    label={'serial number'}
                    value={serialNumber}
                    onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                      setSerialNumber(e.target.value)
                    }
                  />
                  <TextField
                    label={'location'}
                    value={locatedAt}
                    onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setLocatedAt(e.target.value)}
                  />
                  <FormControl fullWidth>
                    <InputLabel id='player-modal-client'>Client</InputLabel>
                    <Select
                      labelId='player-modal-client'
                      id='player-modal-client-select'
                      value={selectedClient}
                      label='Client'
                      onChange={handleClientChange}
                    >
                      {data.map((client) => {
                        return (
                          <MenuItem key={client.id} value={client.name}>
                            {client.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <Typography>Loading...</Typography>
              )}
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} p={2} spacing={1}>
                <Button onClick={() => setConfirmationDialogInview(true)} variant={'contained'} color={'error'}>
                  Cancel
                </Button>
                <Button onClick={handleCreateClick} variant={'contained'}>
                  Create
                </Button>
              </Stack>
            </Stack>
          ) : (
            <Loader />
          )}
        </DialogContent>
      </Dialog>
      {confirmationDialogInview && (
        <ConfirmDialog
          title={'Please confirm your action.'}
          message='Are you sure you want to close this form? All data will be lost.'
          handleClick={handleCloseClick}
        />
      )}
    </>
  );
};

export default PlayerModal;
