import { Stack, TextField } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { ISubmenu, useMenuContext } from '../../context/MenuFormsContext';

const SubmenuForm = () => {
  const { forms, setForms } = useMenuContext();
  const [submenuFormData, setMenuFormData] = useState<ISubmenu>({
    name: '',
    description: '',
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const _submenuFormData: ISubmenu = { ...submenuFormData };
    _submenuFormData.description = inputValue;

    setMenuFormData(_submenuFormData);
    const contextForms = { ...forms };
    contextForms.submenu = _submenuFormData;
    setForms(contextForms);
  };

  return (
    <Stack mt={1}>
      <TextField placeholder='Description' multiline minRows={3} maxRows={6} onChange={handleInputChange} />
    </Stack>
  );
};

export default SubmenuForm;
