import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import s from './styles.module.scss';

type Props = {
  height: number;
};

const Hourly = ({ height }: Props) => {
  let styles;
  if (height <= 400) {
    styles = {
      flexDirection: 'column',
    };
  } else {
    styles = {
      flexDirection: 'row',
      width: '150px',
    };
  }

  return (
    <Stack mt={1} direction={height <= 400 ? 'row' : 'column'} className={s.container}>
      <Box className={s.row} mx={styles}>
        <span>Mon</span>
        <img src='/icons/cloud.svg' />
        <Box className={s.highLowContainer} mx={{ flexDirection: height >= 400 ? 'column' : '' }}>
          <span className={s.high}>87&#xb0;</span>
          <span className={s.low}>82&#xb0;</span>
        </Box>
      </Box>
      <Box className={s.row} mx={styles}>
        <span>Tues</span>
        <img src='/icons/cloud.svg' />
        <Box className={s.highLowContainer} mx={{ flexDirection: height >= 400 ? 'column' : '' }}>
          <span className={s.high}>87&#xb0;</span>
          <span className={s.low}>82&#xb0;</span>
        </Box>
      </Box>
      <Box className={s.row} mx={styles}>
        <span>Wed</span>
        <img src='/icons/cloud.svg' />
        <Box className={s.highLowContainer} mx={{ flexDirection: height >= 400 ? 'column' : '' }}>
          <span className={s.high}>87&#xb0;</span>
          <span className={s.low}>82&#xb0;</span>
        </Box>
      </Box>
      <Box className={s.row} mx={styles}>
        <span>Thur</span>
        <img src='/icons/cloud.svg' />
        <Box className={s.highLowContainer} mx={{ flexDirection: height >= 400 ? 'column' : '' }}>
          <span className={s.high}>87&#xb0;</span>
          <span className={s.low}>82&#xb0;</span>
        </Box>
      </Box>
      <Box className={s.row} mx={styles}>
        <span>Fri</span>
        <img src='/icons/cloud.svg' />
        <Box className={s.highLowContainer} mx={{ flexDirection: height >= 400 ? 'column' : '' }}>
          <span className={s.high}>87&#xb0;</span>
          <span className={s.low}>82&#xb0;</span>
        </Box>
      </Box>
    </Stack>
  );
};

export default Hourly;
