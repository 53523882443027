import { Dispatch, SetStateAction } from 'react';
import toast from 'react-hot-toast';
import axios from '../../../config/axios';
import resetLayout from '../components/custom-layout/utils/reset-columns';
import { FormattedColumns, IIcon } from '../context/types';
import { ColumnIconConfig, PublishArgs, QueryKeyClientScreenArgs, ReplaceArgs } from './types';

const getCurrentlyPlaying = async ({ queryKey }: QueryKeyClientScreenArgs): Promise<ColumnIconConfig | undefined> => {
  const { client, screen } = queryKey[1];
  try {
    const { data } = await axios.get<ColumnIconConfig>(`/image/current/${client}/${screen}`);
    return data;
  } catch (error) {
    toast.error('Error Getting Currently Playing Data');
  }
};

const getQuickSelect = async ({ queryKey }: QueryKeyClientScreenArgs): Promise<ColumnIconConfig[] | undefined> => {
  const { client, screen } = queryKey[1];
  try {
    const { data } = await axios.get<ColumnIconConfig[]>(`/image/select/${client}/${screen}`);
    return data;
  } catch (error) {
    toast.error('Error Getting Quick Select Data');
  }
};

const create = async (
  columns: FormattedColumns,
  icons: IIcon[],
  setLoading: Dispatch<SetStateAction<boolean>>,
  setPreview: Dispatch<SetStateAction<string>>,
  client: string,
  screen: number
) => {
  const data = {
    columns,
    icons,
    client,
    screen,
  };

  setLoading(true);
  try {
    // have to use fetch, (axios doesn't work with blob stuff)
    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/image/create`, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // have to add own logout logic since not using axios
    if (res.status === 401) {
      window.location.href = '/logout';
    }
    const blob = await res.blob();
    const image = URL.createObjectURL(blob);

    setPreview(image);
    setLoading(false);
  } catch (error) {
    setLoading(false);
    toast.error('Error Creating Image');
  }
};

const createCustom = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  stringToSend: string,
  client: string,
  screen: number
): Promise<boolean> => {
  try {
    setLoading(true);
    await axios.post('/image/custom', { image: stringToSend, client, screen });
    toast.success('Image Successfully Created');
    setLoading(false);
    return true;
  } catch (error) {
    setLoading(false);
    toast.error('Error Creating Image');
    return false;
  }
};

const publish = async (args: PublishArgs): Promise<boolean> => {
  const { client, screen, config, setLoading, setColumns, setPreview, setIcons, formattedColumns } = args;
  try {
    setLoading(true);
    const resp = await axios.post('/image/publish', { client, screen, config, formattedColumns });

    if (resp.status == 200) {
      toast.success('Successfully Published');
    } else if (resp.status == 209) {
      toast.error(resp.data.message);
    } else throw Error;
    resetLayout(setColumns, setPreview, setIcons);
    setLoading(false);
    return true;
  } catch (error) {
    setLoading(false);
    toast.error('Error, Please Try Again');
    return false;
  }
};

const replace = async (args: ReplaceArgs, setLoading: Dispatch<SetStateAction<boolean>>): Promise<boolean> => {
  try {
    setLoading(true);
    await axios.post('/image/replace', { ...args });
    toast.success('Successfully Updated');
    setLoading(false);
    return true;
  } catch (error) {
    setLoading(false);
    toast.error('Error, Please Try Again');
    return false;
  }
};

export default replace;

export { getCurrentlyPlaying, getQuickSelect, create, createCustom, publish, replace };
