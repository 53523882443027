import { Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Menu } from '../../../../api/menu-management/menu/types';
import { API_MENU_MANAGEMENT_ITEMS } from '../../../../api/menu-management/submenu-item';
import { ISubmenuItem_API } from '../../../../api/menu-management/submenu-item/types';
import ConfirmDialog from '../../../common/ConfirmDialog';
import Loader from '../../../common/loader';
import InputEditOnClick from './InputEditOnClick';

type Props = {
  handleClose: () => void;
};
const ItemsListDialog = ({ handleClose }: Props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const queryClient = useQueryClient();
  const {
    error,
    data: submenuItemsData,
    isFetching,
  } = useQuery(['allClientSubmenuItems'], API_MENU_MANAGEMENT_ITEMS.getAllSubmenuItems);
  const [itemsList, setItemsList] = useState<ISubmenuItem_API[]>([]);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(-1);
  const [confirmationDialogInview, setConfirmationDialogInview] = useState<boolean>(false);

  const handleItemDeleteBtnClick = (index: number) => {
    setSelectedItemIndex(index);
    setConfirmationDialogInview(true);
  };

  const handleDeletionConfirmation = (res: boolean) => {
    if (res && itemsList) {
      // call mutation
      deleteSubmenuItemMutation.mutate(itemsList[selectedItemIndex].id);
    }
    setConfirmationDialogInview(false);
  };
  // this function is passed to the input so it can update the value
  // change from the Child TextField
  const updateParentValue = (keyId: string, newValue: string) => {
    if (submenuItemsData) {
      // editSubmenuItem
      const itemIndex = submenuItemsData.findIndex((item) => item.id === keyId);
      if (itemIndex > -1) {
        const item = submenuItemsData[itemIndex];
        if (item.name !== newValue && newValue.trim().length > 3) {
          editSubmenuItemMutation.mutate({ id: keyId, clientId: item.clientId, newName: newValue });
        }
      }
    }
  };

  // deleteSubmenuItem
  const deleteSubmenuItemMutation = useMutation({
    mutationFn: (submenuItemId: string) => API_MENU_MANAGEMENT_ITEMS.deleteSubmenuItem(submenuItemId),
    // When mutate is called:
    onMutate: async (submenuItemId: string) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['allClientSubmenuItems'] });
      // Snapshot the previous value
      const previousItems = queryClient.getQueryData<ISubmenuItem_API[]>(['allClientSubmenuItems']);
      if (previousItems) {
        // iterate through and find the menu that was updated
        const _items = [...previousItems];
        const itemIndex = _items.findIndex((item: ISubmenuItem_API) => item.id === submenuItemId);
        _items.splice(itemIndex, 1);
        queryClient.setQueryData<ISubmenuItem_API[]>(['allClientSubmenuItems'], _items);
      }
      // Return a context object with the snapshotted value
      return { previousItems };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      if (context && context.previousItems) {
        // data will be the updated list of all Client Menus
        // previousItems contains ALL the items before any deletion
        const _items = [...context.previousItems];
        const itemIndex = _items.findIndex((item: ISubmenuItem_API) => item.id === variables);
        _items.splice(itemIndex, 1);
        queryClient.setQueryData<ISubmenuItem_API[]>(['allClientSubmenuItems'], _items);
        // Replace optimistic todo in the todos list with the result
        queryClient.setQueryData<Menu[]>(['allClientMenus'], [...data]);
        toast.success('Successfully deleted item!');
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newLocation, context) => {
      if (context?.previousItems) {
        queryClient.setQueryData(['allClientSubmenuItems'], context.previousItems);
        toast.error('Failed to delete item!');
      }
    },
  });

  // updating SubmenuItem name
  const editSubmenuItemMutation = useMutation({
    mutationFn: (data: { newName: string; id: string; clientId: string }) =>
      API_MENU_MANAGEMENT_ITEMS.editSubmenuItem(data),
    // When mutate is called:
    onMutate: async (data: { newName: string; id: string; clientId: string }) => {
      // onMutate will handle editing allClientSubmenuItems to remove it from the list
      await queryClient.cancelQueries({ queryKey: ['allClientSubmenuItems'] });
      // Snapshot the previous value
      const previousItems = queryClient.getQueryData<ISubmenuItem_API[]>(['allClientSubmenuItems']);
      if (previousItems) {
        // iterate through and find the menu that was updated
        const _items = [...previousItems];
        const itemIndex = _items.findIndex((item: ISubmenuItem_API) => item.id === data.id);
        const _item = _items[itemIndex];
        _item.name = data.newName;
        _items[itemIndex] = _item;
        queryClient.setQueryData<ISubmenuItem_API[]>(['allClientSubmenuItems'], _items);
      }
      // Return a context object with the snapshotted value
      return { previousItems };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      // data is the new list of Menus with the edited item name
      // if we get to success then the previousItems will have the updated list from onMutate
      if (context && context.previousItems) {
        queryClient.setQueryData<ISubmenuItem_API[]>(['allClientSubmenuItems'], [...context.previousItems]);
        queryClient.setQueryData<Menu[]>(['allClientMenus'], [...data]);
        toast.success('Successfully edited name item!');
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newLocation, context) => {
      if (context?.previousItems) {
        queryClient.setQueryData(['allClientSubmenuItems'], context.previousItems);
        toast.error('Failed to edit item!');
      }
    },
  });

  const sortAlphabetically = (itemsList: ISubmenuItem_API[]) => {
    const _itemsList = [...itemsList];
    _itemsList.sort(function (a, b) {
      const nameA = a.name.toLowerCase(),
        nameB = b.name.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    return _itemsList;
  };

  useEffect(() => {
    if (submenuItemsData) {
      const sortedItems = sortAlphabetically(submenuItemsData);
      setItemsList(sortedItems);
    }
  }, [submenuItemsData]);
  return (
    <>
      <Dialog open={true}>
        <DialogTitle>All Items</DialogTitle>
        <DialogContent sx={{ maxHeight: '60vh' }}>
          {isFetching ? (
            <Loader />
          ) : itemsList && itemsList.length > 0 ? (
            <Stack direction={'row'} flexWrap={'wrap'} width={'30vw'}>
              {itemsList.map((item, index) => {
                return (
                  <Grid container key={item.id} sx={{ width: '10vw' }}>
                    <Grid item xs={11}>
                      <InputEditOnClick
                        keyName={item.id}
                        updateParentValue={updateParentValue}
                        initialValue={item.name}
                      />
                    </Grid>
                    <Grid item xs={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      {matches ? (
                        <Grid
                          xs={2}
                          item
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          sx={{ opacity: 0, '&:hover': { opacity: 1 } }}
                        >
                          <Button
                            onClick={() => handleItemDeleteBtnClick(index)}
                            style={{ padding: 0, minWidth: '100%', width: '100%', height: '100%' }}
                          >
                            <Delete />
                          </Button>
                        </Grid>
                      ) : (
                        <Grid xs={2} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                          <Button style={{ padding: 0, minWidth: '100%', width: '100%', height: '100%' }}>
                            <Delete />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Stack>
          ) : error ? (
            <Typography>Error loading Items</Typography>
          ) : (
            <Typography>No Current Items Exist</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {confirmationDialogInview && (
        <ConfirmDialog
          title={'Please confirm your action.'}
          message={
            itemsList
              ? `Are you sure you want to delete item ${itemsList[selectedItemIndex].name}`
              : 'Are you sure you want to delete the item?'
          }
          handleClick={handleDeletionConfirmation}
        />
      )}
    </>
  );
};

export default ItemsListDialog;
