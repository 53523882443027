import { IColumn } from '../../../context/types';

// this function finds which column is last based on the merge property
const checkLastColumn = (columns: IColumn[]) => {
  let index = 0;

  // check which column is last
  columns.forEach((column: IColumn) => {
    if (column.merged === false) {
      index = column.position;
    }
  });

  // reset all columns
  columns.forEach((column: IColumn) => {
    column.last = false;
  });

  // set last column
  columns[index].last = true;
  return columns;
};

export default checkLastColumn;
