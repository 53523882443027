import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Player } from '../../api/player-management/types';
import PlayerModal from './components/PlayerModal';

type Props = {
  allPlayers: Player[];
};

const PlayerManagement = ({ allPlayers }: Props) => {
  const [playerModalInview, setPlayerModalInview] = useState<boolean>(false);
  return (
    <Box bgcolor={'#fff'} borderRadius={1} p={1}>
      <Grid container>
        <Grid item>
          <Typography>Player Management</Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => setPlayerModalInview(true)}>
            <Add />
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table
          sx={{ xs: { maxWidth: 320 }, md: { maxWidth: 720 }, lg: { maxWidth: 1100 }, xl: { maxWidth: 1400 } }}
          aria-label='player table'
        >
          <TableHead>
            <TableRow>
              <TableCell>Serial Number</TableCell>
              <TableCell align='right'>Name</TableCell>
              <TableCell align='right'>Location</TableCell>
              <TableCell>Client</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allPlayers.map((player) => (
              <TableRow key={player.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'>
                  {player.serialNumber}
                </TableCell>
                <TableCell align='right'>{player.name}</TableCell>
                <TableCell align='right'>{player.locatedAt}</TableCell>
                <TableCell align='right'>{player.client.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {playerModalInview && <PlayerModal handleClose={() => setPlayerModalInview(false)} />}
    </Box>
  );
};

export default PlayerManagement;
