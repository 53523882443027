import Locations from '../../components/locations/Locations';
import { Location } from '../../components/locations/types';

const LocationsPage = () => {
  const locations: Location[] = [
    {
      name: 'Sante Fe Elementary School',
      address: '2905 N Cole Rd',
      city: 'Boise',
      state: 'Idaho',
      zipCode: '83706',
    },
  ];

  return <Locations locations={locations} />;
};

export default LocationsPage;
