import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { useState } from 'react';

type Permission = {
  name: string;
  operationType: string;
};

type App = {
  name: string;
  permissions: Permission[];
};

type Role = {
  role: {
    name: string;
    code: number;
    id: string;
    apps: App[];
  };
};

// eslint-disable-next-line
const RolePermissionsSelector = ({ client, setParentPermissions }: any) => {
  const [rolesSelected, setRolesSelected] = useState<boolean[]>(
    client.roles.map(() => {
      return false;
    })
  );
  const [permissionsSelected, setPermissionsSelected] = useState<{
    [key: string]: { [key: string]: { [key: string]: boolean } };
  }>({});

  const handleRoleCheckboxClick = (roleName: string, index: number) => {
    const updatedCheckedState = rolesSelected.map((item, idx) => (idx === index ? !item : item));
    // eslint-disable-next-line
    const newRolePermissions: any = { ...permissionsSelected };
    // we need to check if the key already exist
    const keyExist = roleName in newRolePermissions;
    // if it exist then remove from the Object
    if (keyExist) {
      delete newRolePermissions[roleName];
    } else {
      // iterate through apps and create array of all false
      const roleIndex = client.roles.findIndex((role: Role) => role.role.name === roleName);
      // eslint-disable-next-line
      const newPermissions: any = {};
      for (let i = 0; i < client.roles[roleIndex].role.apps.length; i++) {
        const app = client.roles[roleIndex].role.apps[i];
        // eslint-disable-next-line
        const perms: any = {};
        for (let j = 0; j < app.permissions.length; j++) {
          const perm = app.permissions[j];
          perms[perm.name] = true;
        }
        // availablePermissions[app.name] = perms;
        // new testing object
        newPermissions[app.name] = perms;
      }
      newRolePermissions[roleName] = newPermissions;
      // updatedSelectedRolePermissions[roleName] = availablePermissions
    }
    setRolesSelected(updatedCheckedState);
    setPermissionsSelected(newRolePermissions);
    // setSelectedRolePermissions(updatedSelectedRolePermissions)
    setParentPermissions(client.name, newRolePermissions);
    // using object
  };

  const handlePermissionClicked = (roleName: string, appName: string, index: number, permissionName: string) => {
    const updatedSelectedRolePermissions = { ...permissionsSelected };
    // updatedSelectedRolePermissions[roleName] = {[appName]: updatedSelectedRolePermissions[roleName][appName].map((item, idx) => idx === index ? item : !item)};
    updatedSelectedRolePermissions[roleName][appName][permissionName] =
      !updatedSelectedRolePermissions[roleName][appName][permissionName];
    // setSelectedRolePermissions(updatedSelectedRolePermissions)
    setParentPermissions(client.name, updatedSelectedRolePermissions);
    // permissions object
    setPermissionsSelected(updatedSelectedRolePermissions);
  };

  return (
    <Box key={client.id} mb={1}>
      <Typography fontSize={'2vw'} fontWeight={'bold'} textTransform={'uppercase'}>
        {client.name}
      </Typography>

      {client.roles.map((role: Role, index: number) => {
        return (
          <Box key={`role-${index}`} display={'flex'} flexDirection={'column'} bgcolor={'#ececec'}>
            <Box display={'flex'} height={'4vh'}>
              <Typography>{role.role.name}</Typography>
              <Checkbox
                checked={rolesSelected[index]}
                onChange={() => handleRoleCheckboxClick(role.role.name, index)}
              />
            </Box>
            {rolesSelected[index] && (
              <Box display={'flex'} flexDirection={'column'} pl={4}>
                <Typography>Apps list:</Typography>
                {role.role.apps.map((app) => {
                  return (
                    <Box key={`app-${app.name}`} ml={2}>
                      <Typography textTransform={'uppercase'}>{app.name}</Typography>
                      <Stack direction={'row'}>
                        {app.permissions.map((perm, pIndex) => {
                          return (
                            <Box width={'25%'} display={'flex'} key={`${perm.name}-${pIndex}`}>
                              {/* <Checkbox checked={selectedRolePermissions[role.role.name][app.name][pIndex]} onChange={() => handlePermissionClicked(role.role.name, app.name, pIndex, perm.name)}/> */}
                              <Checkbox
                                checked={permissionsSelected[role.role.name][app.name][perm.name]}
                                onChange={() => handlePermissionClicked(role.role.name, app.name, pIndex, perm.name)}
                              />
                              <Typography>{perm.operationType}</Typography>
                            </Box>
                          );
                        })}
                      </Stack>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default RolePermissionsSelector;
