import { TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import s from '../styles.module.scss';
import { TableData } from '../types';

const Row = ({ data }: { data: TableData }) => (
  <TableRow key={data.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
    <TableCell component='th' scope='row'>
      <Link to={`/users/${data.id}`} className={s.link}>
        {data.name}
      </Link>
    </TableCell>
    <TableCell>{data.email}</TableCell>
    <TableCell>
      {data.blacklisted ? <p style={{ color: 'red' }}>No</p> : <p style={{ color: 'green' }}>Yes</p>}
    </TableCell>
  </TableRow>
);

export default Row;
