import { LaneContextProvider } from './context/LaneContext';
import ChangeScreens from './components/change-screens/ChangeScreens';
import { Box } from '@mui/material';
import CurrentlyPlaying from './components/currently-playing/CurrentlyPlaying';
import CustomMessage from './components/custom-message/CustomMessage';
import CustomLayout from './components/custom-layout/CustomLayout';
import QuickSelect from './components/quick-select/QuickSelect';
import s from './styles.module.scss';

const LaneManagement = () => {
  return (
    <LaneContextProvider>
      <Box className={s.container}>
        <ChangeScreens />
        <CurrentlyPlaying />
        <CustomMessage />
        <QuickSelect />
        <CustomLayout />
      </Box>
    </LaneContextProvider>
  );
};

export default LaneManagement;
