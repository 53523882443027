const textSizing = {
  xs: {
    title: '2vw',
    button: '1.2vw',
    sectionTitles: '1vw',
    sectionText: '1vw',
  },
  lg: {
    title: '2vw',
    button: '1vw',
    sectionTitles: '1vw',
    sectionText: '1vw',
  },
};

export default textSizing;
