import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { API_WHITEBOARD_APP_LOCATION_SITES } from '../../../../api/whiteboard/map/site';
import { SiteLocation, SiteLocationHistory } from '../../../../api/whiteboard/map/site/types';
import { formatDateMMDDYYY } from '../../../../utils/format-data';
import Loader from '../../../common/loader/Loader';

type Props = {
  siteLocation: SiteLocation;
  onClose: (res: boolean) => void;
};
const LabeledWBHistory = ({ siteLocation, onClose }: Props) => {
  // we need to know fetch the site information for this location
  const {
    isLoading,
    data: historyData,
    error,
  } = useQuery(['siteLocationHistory', siteLocation.id], () =>
    API_WHITEBOARD_APP_LOCATION_SITES.getSiteLocationHistory(siteLocation.id)
  );
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Site History</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>Site History</Typography>
          <Box>
            {isLoading ? (
              <Loader />
            ) : error ? (
              <Typography> Error fetching history.</Typography>
            ) : historyData ? (
              <Box>
                {console.log(historyData)}
                {historyData.map((history: SiteLocationHistory) => {
                  return <Typography key={history.id}>{formatDateMMDDYYY(String(history.createdAt))}</Typography>;
                })}
              </Box>
            ) : (
              <Typography>No History</Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LabeledWBHistory;
