import { ILocation } from '../../../components/menu-management/menu/context/MenuFormsContext';
import axios from '../../../config/axiosV2';
import { Location } from './types';

const createLocation = async (formData: ILocation): Promise<boolean | undefined> => {
  const url = '/menu-management/location';
  try {
    const res = await axios.post(url, formData);
    const { data } = res;
    return data;
  } catch {
    throw Error('Failed to create a new location.');
  }
};

const getAllLocations = async (): Promise<Location[]> => {
  const url = '/menu-management/location';
  try {
    const { data } = await axios.get(url);
    const { locations }: { locations: Location[] } = data;
    return locations;
  } catch {
    return [];
  }
};

export { createLocation, getAllLocations };
