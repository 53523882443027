import CallSplitIcon from '@mui/icons-material/CallSplit';
import MergeIcon from '@mui/icons-material/Merge';
import { Box, ClickAwayListener } from '@mui/material';
import { useState } from 'react';
import { useLaneContext } from '../../../context/LaneContext';
import { IColumn, IImageData } from '../../../context/types';
import s from '../styles.module.scss';
import mergeColumns from '../utils/merge-columns';
import splitColumn from '../utils/split-column';
import Image from './Image';

type Props = {
  column: IColumn;
};

const Column = ({ column }: Props) => {
  const { columns, setColumns, imageData, screen } = useLaneContext();
  const [dropdown, setDropdown] = useState(false);

  if (column.merged) return <></>; // if column is merged don't render it

  let borderStyle = 'none';
  if (!column.image) {
    borderStyle = '1px solid #8e8e8e';
  }

  const showImage = column.image ? `url('${column.image}')` : '';

  return (
    <ClickAwayListener onClickAway={() => setDropdown(false)}>
      <Box
        className={s.col}
        sx={{
          flex: column.width, // initially 1
          border: borderStyle, // show outline if no images is selected
          backgroundImage: showImage, // '' or url link to image
        }}
      >
        {/* width is determined by property on column object */}
        {!column.last && (
          <Box className={s.merge} onClick={() => mergeColumns(column, columns, setColumns)}>
            <MergeIcon sx={{ color: '#fff', fontSize: 15 }} />
          </Box>
        )}
        {/* If it is the last column don't show merge button */}
        {column.width > 1 && (
          <Box onClick={() => splitColumn(column, columns, setColumns)} className={s.delete}>
            <CallSplitIcon sx={{ color: '#fff', fontSize: 15 }} />
          </Box> // if column has merged columns show the split button
        )}

        <Box
          className={s.dropdown}
          sx={{
            visibility: dropdown ? 'inherit' : 'hidden',
            transform: dropdown ? 'translateY(0)' : 'translateY(-30px)',
          }}
        >
          {imageData.map((image: IImageData) => (
            <Image key={image.id} image={image} setDropdown={setDropdown} column={column} screen={screen} />
          ))}
        </Box>
        <Box className={s.colOverlay} onClick={() => setDropdown(!dropdown)}></Box>
      </Box>
    </ClickAwayListener>
  );
};

export default Column;
