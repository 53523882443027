import { Close } from '@mui/icons-material';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import axios from '../../config/axiosV2';
import { useAuthContext } from '../../context/AuthContext';
import { decrypt } from '../../utils/cryptojs-helper';

type FileError =
  | ''
  | 'Only jpeg, jpg, and png are allowed.'
  | 'File size should not exceed 5MB.'
  | 'No files selected for upload.'
  | 'Video size should not exceed 20MB.';

const AssetUploadFile = ({ callback }: { callback?: () => void }) => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const user = JSON.parse(decrypted);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [error, setError] = useState<FileError>('');
  // progress bar
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleDrop = async (acceptedFiles: File[]) => {
    const validFiles: File[] = [];
    let errorMsg: FileError = '';
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
        errorMsg = 'Only jpeg, jpg, and png are allowed.';
      }
      if (file.size > 5000000) {
        errorMsg = 'File size should not exceed 5MB.';
      } else {
        validFiles.push(file);
      }
    }

    if (errorMsg) {
      setError(errorMsg);
    } else {
      setError('');
      setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  };

  const handleRemove = (fileToRemove: File) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file: File) => file !== fileToRemove));
  };

  const handleUpload = () => {
    if (selectedFiles.length > 0) {
      // Logic to handle the file upload
      setUploading(true);
      setUploadProgress(0);
      const formData: FormData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        const _file = selectedFiles[i];
        formData.append('media[]', _file);
      }
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL_V2}/digitalsignage/media/assets/upload/${user.currentClient.client.id}`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setUploadProgress(percentCompleted);
            },
          }
        )
        .then(() => {
          setSelectedFiles([]);
          setError('');
          setUploading(false);
          setUploadProgress(0);
          toast.success('Successfully uploaded all Media');
          if (callback) {
            callback();
          }
        })
        .catch(() => {
          console.error('Error: Uploading Media.');
        });
    } else {
      setError('No files selected for upload.');
    }
  };

  const handleCancel = () => {
    setSelectedFiles([]);
    setError('');
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: '.jpg, .jpeg, .png',
    maxSize: 5000000,
    multiple: true,
    noClick: true, // Disable the click-to-select functionality in the dropzone
  });

  return (
    <Box pt={'2vh'} bgcolor={'#fff'} borderRadius={2} padding={1}>
      <Stack direction={'row'} spacing={'1vw'}>
        <Button onClick={() => document.getElementById('fileInput')?.click()} variant='contained' color={'primary'}>
          <Typography color={'#fff'} fontWeight={'bold'}>
            Choose
          </Typography>
        </Button>
        <Button onClick={handleUpload} variant='contained' color={'secondary'}>
          Upload
        </Button>
        <Button onClick={handleCancel} variant='contained' color={'error'}>
          Cancel
        </Button>
      </Stack>
      <input
        id='fileInput'
        type='file'
        style={{ display: 'none' }}
        accept='.jpg,.jpeg,.png'
        multiple
        onChange={(e) => handleDrop(Array.from(e.target.files || []))}
      />
      {error && (
        <Box display={'flex'} alignItems={'center'} width={'100%'} height={'5vh'} mt={2}>
          <Typography style={{ color: 'red' }}>{error}</Typography>
        </Box>
      )}
      <Box
        {...getRootProps({ className: 'dropzone' })}
        style={{
          border: '2px dashed #ccc',
          borderRadius: '4px',
          padding: '20px',
          textAlign: 'center',
          backgroundColor: '#f9f9f9',
          cursor: 'default', // Indicates that the area is non-clickable
          marginTop: '20px',
        }}
      >
        <input {...getInputProps()} />
        <Typography>Drag and drop files here to upload</Typography>
      </Box>
      <Box p={2} maxHeight={'50vh'} overflow={'scroll'} className={'hide-scrollbars'}>
        {selectedFiles.length > 0
          ? selectedFiles.map((file) => {
              return (
                <Box key={file.name} display={'flex'} p={1} mb={'10px'}>
                  <Box
                    flex={'1 1 auto'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    boxSizing={'border-box'}
                  >
                    <img src={URL.createObjectURL(file)} style={{ width: '50px', objectFit: 'contain' }} />
                  </Box>
                  <Grid container display={'flex'} flexDirection={'row'} flex={'1 1 auto'}>
                    <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <Typography>{file.name}</Typography>
                    </Grid>
                    <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <Typography>{(file.size / 1000).toFixed(2)} KB</Typography>
                    </Grid>
                  </Grid>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flex={'1 1 auto'}>
                    <Button
                      onClick={() => handleRemove(file)}
                      style={{
                        height: '48px',
                        width: '45px',
                        background: 'red',
                        color: 'white',
                        border: 'none',
                        padding: '0',
                        cursor: 'pointer',
                        minWidth: '25px',
                      }}
                    >
                      <Close />
                    </Button>
                  </Box>
                </Box>
              );
            })
          : null}
      </Box>
      {uploading && (
        <div
          style={{ marginTop: '20px', width: '100%', height: '10px', backgroundColor: '#e0e0e0', borderRadius: '5px' }}
        >
          <div
            style={{
              width: `${uploadProgress}%`,
              height: '100%',
              backgroundColor: uploadProgress === 100 ? '#4caf50' : '#3b82f6',
              transition: 'width 0.5s ease-in-out',
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default AssetUploadFile;
