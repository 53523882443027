import { ISubmenuItem } from '../../../components/menu-management/menu/context/MenuFormsContext';
import axios from '../../../config/axiosV2';
import { ISubmenuItem_API } from './types';

const createSubmenuItem = async (formData: ISubmenuItem): Promise<boolean | ISubmenuItem_API> => {
  const url = '/menu-management/submenu-item';
  try {
    const res = await axios.post(url, formData);
    const { data } = res;
    const itemData: ISubmenuItem_API = data.item;
    return itemData;
  } catch {
    return false;
  }
};

const getAllSubmenuItems = async (): Promise<ISubmenuItem_API[]> => {
  const url = '/menu-management/submenu-item';
  try {
    const res = await axios.get(url);
    const { data } = res;
    // TODO: this type will change for the new MtM relationship
    const allSubmenuItems: ISubmenuItem_API[] = data.submenuItems;
    return allSubmenuItems;
  } catch {
    return [];
  }
};

const addItemsToSubmenu = async (data: {
  submenuId: string;
  itemsIdList: { submenuItemId: string; position: number; name: string }[];
}) => {
  const { submenuId, itemsIdList } = data;
  const url = `/menu-management/submenu/add-items-to-submenu/${submenuId}`;
  try {
    const res = await axios.post(url, { submenuItemIDs: itemsIdList });
    const { data } = res;
    return data.menus;
  } catch {
    throw Error('Failed to add item to submenu.');
  }
};

const removeItemFromSubmenu = async ({
  submenuId,
  submenuItem,
}: {
  submenuId: string;
  submenuItem: {
    submenuId: string;
    submenuItemId: string;
    createdAt: Date;
    submenuItem: {
      id: string;
      name: string;
    };
  };
}) => {
  const url = `/menu-management/submenu-item/remove-from-submenu/${submenuId}`;
  try {
    const res = await axios.put(url, { submenuItem: submenuItem });
    // response will return the updated list of client menus
    const { data } = res;
    return data.menus;
  } catch {
    throw Error('Failed to remove item.');
  }
};

const deleteSubmenuItem = async (submenuItemId: string) => {
  const url = `/menu-management/submenu-item/${submenuItemId}`;
  try {
    const res = await axios.delete(url);
    const { data } = res;
    return data.menus;
  } catch {
    throw Error('Failed to delete submenu item');
  }
};

const editSubmenuItem = async (data: { newName: string; id: string; clientId: string }) => {
  const { newName, clientId, id } = data;
  const url = `/menu-management/submenu-item/${id}`;
  try {
    // this will return a new list of all client menus
    const res = await axios.put(url, { id: id, clientId: clientId, name: newName });
    const { data } = res;
    return data.menus;
  } catch {
    throw Error('Failed to edit submenu item.');
  }
};

export {
  addItemsToSubmenu,
  createSubmenuItem,
  deleteSubmenuItem,
  editSubmenuItem,
  getAllSubmenuItems,
  removeItemFromSubmenu,
};
