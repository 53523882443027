import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeIcon from '@mui/icons-material/Badge';
import BlockIcon from '@mui/icons-material/Block';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { Autocomplete, Box, Button, Card, Switch } from '@mui/material';
import { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ClientGetData } from '../../api/client/types';
import { API_USER } from '../../api/user';
import { emailRegex } from '../../constants';
import { ClientNames } from '../../types';
import BaseInput from '../common/BaseInput';
import Header from '../common/header';
import RolePermissionsSelector from './components/RolePermissionsSelector';
import { Data } from './types';

const AddUser = ({ clientData }: { clientData: ClientGetData[] }) => {
  const navigate = useNavigate();
  const selectedPermissions = useRef<{ [key: string]: { [key: string]: { [key: string]: boolean[] } } }>({});

  const [data, setData] = useState<Data>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordCheck: '',
    blacklisted: false,
    permissions: null,
  });

  const [errors, setErrors] = useState({
    email: false,
    password: false,
    passwordCheck: false,
  });

  const [clients, setClients] = useState<ClientNames[]>([]);

  const [clientNames] = useState<ClientNames[]>(
    clientData.map((client: ClientGetData) => {
      return { id: client.id, name: client.name, roles: client.roles };
    })
  );

  const handleClientChange = (clients: ClientNames[]) => {
    setClients(clients);
  };

  const submitData = async () => {
    const { password, passwordCheck } = data;
    if (!data.email.toLowerCase().match(emailRegex)) return setErrors({ ...errors, email: true });
    if (data.password.length < 8) return setErrors({ ...errors, password: true });
    if (password !== passwordCheck) return setErrors({ ...errors, passwordCheck: true });
    delete data.passwordCheck; // don't need to send password check field to api
    data.permissions = selectedPermissions.current;
    const _clients = clients.map((client) => {
      return { id: client.id, name: client.name };
    });

    const dataToSend = { ...data, clients: _clients };
    // remove the roles
    const success = await API_USER.register(dataToSend);
    success ? navigate('/users') : '';
  };

  const updateUserSelectedPermissions = (
    clientName: string,
    newSelectedPermissions: { [key: string]: { [key: string]: boolean[] } }
  ) => {
    // check if the key exist
    const _selectedPermissions = { ...selectedPermissions.current };
    _selectedPermissions[clientName] = newSelectedPermissions;
    selectedPermissions.current = _selectedPermissions;
  };

  return (
    <Box maxWidth={1400} marginX='auto'>
      <Link to='/users' style={{ textDecoration: 'none' }}>
        <Button color='warning' endIcon={<ArrowBackIcon />}>
          Cancel
        </Button>
      </Link>
      <Card>
        <Header text='Name' Icon={<BadgeIcon />} />
        <BaseInput
          label='First Name'
          value={data.firstName}
          onChange={(e) => setData({ ...data, firstName: e.target.value })}
        />
        <BaseInput
          label='Last Name'
          value={data.lastName}
          onChange={(e) => setData({ ...data, lastName: e.target.value })}
        />
      </Card>
      <Card sx={{ marginTop: 2 }}>
        <Header text='Email' Icon={<EmailIcon />} />
        <BaseInput
          label='Email'
          value={data.email}
          onChange={(e) => setData({ ...data, email: e.target.value })}
          error={errors.email}
          helperText={errors.email && 'Please enter a valid email'}
          onFocus={() => setErrors({ ...errors, email: false })}
        />
      </Card>
      <Card sx={{ marginTop: 2 }}>
        <Header text='Password' Icon={<LockIcon />} />
        <BaseInput
          label='Password'
          type='password'
          onChange={(e) => setData({ ...data, password: e.target.value })}
          error={errors.password}
          helperText={errors.password && 'Password must be longer than 8 characters'}
          onFocus={() => setErrors({ ...errors, password: false })}
        />
        <BaseInput
          label='Confirm Password'
          type='password'
          onChange={(e) => setData({ ...data, passwordCheck: e.target.value })}
          error={errors.passwordCheck}
          helperText={errors.passwordCheck && 'Passwords do not match'}
          onFocus={() => setErrors({ ...errors, passwordCheck: false })}
        />
      </Card>
      <Card sx={{ marginTop: 2 }}>
        <Header text='Client' Icon={<CorporateFareIcon />} />
        <Autocomplete
          onChange={(_, clients) => handleClientChange(clients)}
          fullWidth
          multiple
          options={clientNames}
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          renderInput={(params) => <BaseInput {...params} label='Pick Clients' />}
        />
      </Card>
      {clients.length > 0 ? (
        <Box display={'flex'} flexDirection={'column'} bgcolor={'#fff'} borderRadius={4} padding={1} mt={1}>
          {clients.map((client) => {
            return (
              <RolePermissionsSelector
                client={client}
                setParentPermissions={updateUserSelectedPermissions}
                key={client.id}
              />
            );
          })}
        </Box>
      ) : null}
      <Card sx={{ marginTop: 2 }}>
        <Header text='Blacklist' Icon={<BlockIcon />} />
        <Box sx={{ display: 'flex', width: '100%' }}>
          <div style={{ flex: '1' }}>
            <Switch
              onChange={() => {
                setData({ ...data, blacklisted: !data.blacklisted });
              }}
            />
          </div>
          {data.blacklisted ? (
            <p style={{ flex: '1' }}>User account exist but cannot log in.</p>
          ) : (
            <p style={{ flex: '1' }}>User can log in</p>
          )}
        </Box>
      </Card>
      <Button
        disabled={
          !data.firstName ||
          !data.lastName ||
          !data.email ||
          !data.password ||
          !data.passwordCheck ||
          clients.length < 1
        }
        variant='contained'
        size='small'
        sx={{ mt: 2, alignSelf: 'flex-start' }}
        onClick={() => submitData()}
      >
        Create User
      </Button>
    </Box>
  );
};

export default AddUser;
