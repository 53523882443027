const formatDate = (date: string): string => {
  const d = new Date(date);
  const formattedDate = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
  return formattedDate;
};

const formatDateYYYYMMDD = (date: string) => {
  const d = new Date(date);
  // Get year, month, and day part from the date
  const year = d.toLocaleString('default', { year: 'numeric' });
  const month = d.toLocaleString('default', { month: '2-digit' });
  const day = d.toLocaleString('default', { day: '2-digit' });
  return year + '-' + month + '-' + day;
};

const formatDateMMDDYYY = (date: string) => {
  const d = new Date(date);
  // Get year, month, and day part from the date
  const year = d.toLocaleString('default', { year: 'numeric' });
  const month = d.toLocaleString('default', { month: '2-digit' });
  const day = d.toLocaleString('default', { day: '2-digit' });
  return month + '-' + day + '-' + year;
};

const bytesToKilobytes = (bytes: number, precision = 2) => {
  // Check if input is a number and non-negative
  if (typeof bytes !== 'number' || bytes < 0) {
    throw new TypeError('Input must be a non-negative number');
  }

  // Convert bytes to kilobytes
  const kilobytes = bytes / 1024;

  // Return the result rounded to the specified precision
  return `${kilobytes.toFixed(precision)} KB`;
};

export { bytesToKilobytes, formatDate, formatDateMMDDYYY, formatDateYYYYMMDD };
