import { TableCell, TableRow } from '@mui/material';
import { RoleTableData } from '../types';

const RoleRow = ({ data }: { data: RoleTableData }) => (
  <TableRow key={data.label} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
    <TableCell component='th' scope='row'>
      {data.label}
    </TableCell>
  </TableRow>
);

export default RoleRow;
