import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LocationData } from '../../../../api/whiteboard/map/location/types';
import { SiteLocation, WBMedia } from '../../../../api/whiteboard/map/site/types';
import CompanyForm from './CompanyForm';
import IconForm from './IconForm';
import NewMapForm from './NewMapForm';
import PlayerManagement from './PlayerManagement';
import SiteLocationForm from './SiteLocationForm';

type Props = {
  type: string;
  mainBgColor: string;
  location: LocationData;
  siteLocation: SiteLocation | null;
  // eslint-disable-next-line
  itemToEdit: any | WBMedia | null;
  closeDialog: () => void;
};

const FormDialog = ({ type, mainBgColor, itemToEdit, location, siteLocation, closeDialog }: Props) => {
  const [title, setTitle] = useState<string>('Title Placeholder');
  const [formInViewIndex, setFormInViewIndex] = useState<number>(-1);
  // TODO: replace dummy location data with actual data
  const FORMS = [
    <NewMapForm key={'new-app-form'} locationData={location} close={closeDialog} siteLocaton={siteLocation} />,
    <CompanyForm key={'company-form'} locationData={location} itemToEdit={itemToEdit} close={closeDialog} />,
    <IconForm key={'icon-form'} locationData={location} itemToEdit={itemToEdit} close={closeDialog} />,
    <SiteLocationForm key={'add-site-location'} locationData={location} close={closeDialog} />,
    <PlayerManagement
      key={'player-management'}
      locationData={location}
      siteLocation={siteLocation}
      close={closeDialog}
    />,
  ];

  const handleClose = () => {
    closeDialog();
  };

  // setting up what kind of form will appear
  useEffect(() => {
    if (type === 'add-map') {
      setTitle('Upload New Map');
      setFormInViewIndex(0);
    } else if (type === 'company-form') {
      setTitle(itemToEdit ? 'Edit Company Name' : 'Add New Company');
      setFormInViewIndex(1);
    } else if (type === 'icon-form') {
      setTitle('Add Icon(s)');
      setFormInViewIndex(2);
    } else if (type === 'add-site-location') {
      setTitle('Add Site Location');
      setFormInViewIndex(3);
    } else if (type === 'player-management') {
      setTitle('Manage players');
      setFormInViewIndex(4);
    }
    // eslint-disable-next-line
  }, [type]);

  return (
    <>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle textAlign={'center'} style={{ backgroundColor: mainBgColor, color: '#ffffff' }}>
          {title}
        </DialogTitle>
        <DialogContent
          sx={{
            maxWidth: { xs: '60vw' },
            minWidth: { xs: '20vw' },
            minHeight: { xs: '15vh' },
            maxHeight: { xs: '50vh' },
          }}
        >
          {formInViewIndex !== -1 ? FORMS[formInViewIndex] : <Typography>No current form selected</Typography>}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormDialog;
