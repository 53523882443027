import { Box, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FileUpload, FileUploadErrorParams, FileUploadUploadParams } from 'primereact/fileupload';
import { useRef } from 'react';
import toast from 'react-hot-toast';
import { LocationData } from '../../../../api/whiteboard/map/location/types';
import { SiteLocation } from '../../../../api/whiteboard/map/site/types';

const ImageUploadRequirements = ['Image Type: PNG', 'Image Size: <= 5 MB'];

type Props = {
  locationData: LocationData;
  siteLocaton: SiteLocation | null;
  close: () => void;
};

const NewMapForm = ({ locationData, siteLocaton, close }: Props) => {
  const { location } = locationData;
  const queryClient = useQueryClient();
  const theme = useTheme();
  const mainBlueBg = theme.palette.primary.main;
  const mainErrorBg = theme.palette.error.main;
  const fileUploadRef = useRef<FileUpload | null>(null);

  // this function will close the form if the file upload is successful
  const handleFileSuccessUploaded = (event: FileUploadUploadParams) => {
    const newMediaBucket = JSON.parse(event.xhr.responseText).media[0];
    if (siteLocaton) {
      const _updatedSiteLocation = { ...siteLocaton };
      _updatedSiteLocation.backgroundImages = [newMediaBucket];
      const previousSiteLocations = queryClient.getQueryData<SiteLocation[]>(['locationSiteLocations', location.id]);
      if (previousSiteLocations) {
        const _previousSiteLocations = [...previousSiteLocations];
        const foundIndex = _previousSiteLocations.findIndex((sl) => {
          return sl.id === _updatedSiteLocation.id;
        });
        if (foundIndex) {
          _previousSiteLocations[foundIndex] = _updatedSiteLocation;
          // now we need to update this site location bucketURL
          queryClient.setQueryData<SiteLocation[]>(['locationSiteLocations', location.id], _previousSiteLocations);
        }
      }
    }
    toast.success('Succesfully uploaded the new map image!');
    close();
  };

  const handleFileErrorUpload = (event: FileUploadErrorParams) => {
    const errorMessage = event.xhr.responseText;
    toast.error(errorMessage);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} pt={2}>
      {siteLocaton ? (
        <>
          <Stack direction={'column'} spacing={2}>
            <Typography textAlign={'center'}>This new map image will be the active map for the location:</Typography>
            <Typography fontWeight={'bold'} textAlign={'center'}>
              {location.name}
            </Typography>
            <Typography fontWeight={'bold'} textAlign={'center'}>
              {siteLocaton.name}
            </Typography>
          </Stack>
          <Box border={`2px solid ${mainBlueBg}`} p={1} borderRadius={2}>
            <Typography>The map image must meet these requirements: </Typography>
            <List>
              {ImageUploadRequirements.map((requirement, index) => {
                return (
                  <ListItem key={`requirement-${index}`}>
                    <Typography fontSize={'12px'}>{requirement}</Typography>
                  </ListItem>
                );
              })}
            </List>
            <FileUpload
              ref={fileUploadRef}
              url={`${process.env.REACT_APP_API_BASE_URL_V2}/whiteboard/map/upload-new-map/${siteLocaton.id}`}
              withCredentials={true}
              name='media[]' // name 'media[]' is what is called in the api, also has to be array to handle multiple files
              accept='image/png'
              maxFileSize={5000000}
              emptyTemplate={<p>Drag and drop file to here to upload.</p>}
              chooseOptions={{ style: { background: mainBlueBg, border: 'none' } }}
              uploadOptions={{ label: 'Upload New Map', style: { background: mainBlueBg, border: 'none' } }}
              cancelOptions={{ style: { background: mainErrorBg, border: 'none' } }}
              onUpload={handleFileSuccessUploaded}
              onError={handleFileErrorUpload}
            />
          </Box>
        </>
      ) : (
        <Typography>A Site location needs to be selected</Typography>
      )}
    </Box>
  );
};

export default NewMapForm;
