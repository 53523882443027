import { Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import ConfirmDialog from '../../../common/ConfirmDialog';

type Props = {
  // eslint-disable-next-line
  children: any;
  br: number;
  handleDeleteClick: () => void;
  handleEditClick: () => void;
};
const HoverIcons = ({ children, br, handleDeleteClick, handleEditClick }: Props) => {
  const [confirmationModuleInView, setConfirmationModuleInView] = useState<boolean>(false);

  const handleConfirmationClick = (res: boolean) => {
    if (res) {
      handleDeleteClick();
    }
    setConfirmationModuleInView(false);
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          '&:hover': {
            '.icon-actions': {
              display: 'flex',
              backgroundColor: '#000000a1',
            },
          },
        }}
        width={'100%'}
      >
        {children}
        <Box
          className={'icon-actions'}
          borderRadius={br}
          sx={{
            display: 'none',
            position: 'absolute',
            top: 0,
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack direction={'row'} spacing={1} width={'100%'}>
            <IconButton onClick={handleEditClick} sx={{ flex: 1 }}>
              <Edit htmlColor={'green'} />
            </IconButton>
            <IconButton onClick={() => setConfirmationModuleInView(true)} sx={{ flex: 1 }}>
              <Delete htmlColor={'red'} />
            </IconButton>
          </Stack>
        </Box>
      </Box>
      {confirmationModuleInView && (
        <ConfirmDialog
          title={'Please confirm action'}
          message='Are you sure you want to delete this item?'
          handleClick={handleConfirmationClick}
        />
      )}
    </>
  );
};

export default HoverIcons;
