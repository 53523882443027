import { useQuery } from '@tanstack/react-query';
import { API_DS_MEDIA } from '../../../api/digital-signage-cms/media';
import Loader from '../../../components/common/loader/Loader';
import MediaView from '../../../components/digital-signage-cms/media/MediaView';
import { useAuthContext } from '../../../context/AuthContext';
import { decrypt } from '../../../utils/cryptojs-helper';

const MediaPage = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const userJSON = JSON.parse(decrypted);
  const { currentClient } = userJSON;
  const { error, data } = useQuery(['allClientMedia'], () => API_DS_MEDIA.getAllMedia(currentClient.client.id));
  if (error) return <p>error</p>;
  if (data) return <MediaView allMedia={data} loggedUser={userJSON} />;
  return <Loader />;
};

export default MediaPage;
