import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MessageIcon from '@mui/icons-material/Message';
import SaveIcon from '@mui/icons-material/Save';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card } from '@mui/material';
import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../context/AuthContext';
import { decrypt } from '../../../../utils/cryptojs-helper';
import BaseInput from '../../../common/BaseInput';
import Loader from '../../../common/loader';
import { API_LANE_MANAGEMENT } from '../../api';
import { useLaneContext } from '../../context/LaneContext';
import s from './styles.module.scss';
import createImageFromHtml from './utils/create-image-from-html';

const CustomMessage = () => {
  const { screen } = useLaneContext();
  // const { user } = useAuthContext();
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);
  const currentClient = user.currentClient.client.name;

  const exportRef = useRef() as MutableRefObject<HTMLInputElement>;
  const navigate = useNavigate();

  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  const createCustomImage = useCallback(
    async (element: HTMLInputElement) => {
      const string = await createImageFromHtml(element);
      const success = await API_LANE_MANAGEMENT.createCustom(setLoading, string, currentClient, screen);

      // if success refresh page else do nothing
      success
        ? setTimeout(() => {
            navigate(0);
          }, 2000)
        : '';
    },
    [navigate, currentClient, screen]
  );

  return (
    <Accordion className={s.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content'>
        <div className={s.title}>
          Custom Message <MessageIcon className={s.icon} />
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Card className={s.container}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Box className={s.layer}>
                <Box className={s.customImage} ref={exportRef}>
                  <h1>{text}</h1>
                </Box>
              </Box>
              <Box mt={1} width={550}>
                <BaseInput
                  placeholder='Enter Custom Text...'
                  size='medium'
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              </Box>
              <Button
                variant='contained'
                size='large'
                sx={{ mt: 1 }}
                endIcon={<SaveIcon />}
                onClick={() => createCustomImage(exportRef.current)}
              >
                Save
              </Button>
            </>
          )}
        </Card>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomMessage;
