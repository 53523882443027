import AddPlaylist from '../../../components/digital-signage-cms/add-playlist/AddPlaylist';
import { useAuthContext } from '../../../context/AuthContext';
import { decrypt } from '../../../utils/cryptojs-helper';

const AddPlaylistsPage = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const user = JSON.parse(decrypted);
  return <AddPlaylist user={user} />;
};

export default AddPlaylistsPage;
