import { Dispatch, SetStateAction } from 'react';
import { IColumn } from '../../../context/types';
import checkLastColumn from './check-last-column';

const mergeColumns = (column: IColumn, columns: IColumn[], setColumns: Dispatch<SetStateAction<IColumn[]>>) => {
  const columnToMerge = column.width;
  const position = column.position;
  const nextColumn = columns.find((col) => col.position === position + columnToMerge);
  if (nextColumn) {
    const columnsCopy = columns.slice(); // copy and .slice() makes a new reference of columns
    columnsCopy[position].width = columnToMerge + nextColumn?.width;
    columnsCopy[nextColumn.position].merged = true;
    nextColumn.width = 1;
    nextColumn.image = '';

    const columnsReplace = checkLastColumn(columnsCopy);

    setColumns(columnsReplace);
  }
};

export default mergeColumns;
