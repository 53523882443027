import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { LocationData } from '../../../../api/whiteboard/map/location/types';
import { API_WHITEBOARD_APP_LOCATION_SITES } from '../../../../api/whiteboard/map/site';
import { SiteLocation } from '../../../../api/whiteboard/map/site/types';
import checkIfEmptyString from '../../../../utils/check-if-empty';
import Loader from '../../../common/loader';

type Props = {
  locationData: LocationData;
  close: () => void;
};

const SiteLocationForm = ({ locationData, close }: Props) => {
  const queryClient = useQueryClient();
  const { location } = locationData;
  const [step, setStep] = useState<number>(0);
  const [name, setName] = useState<string>('');
  // error
  const [nameError, setNameError] = useState<{ isError: boolean; message: string }>({ isError: false, message: '' });

  // TODO: create an API file once the backend is created
  const handleSubmit = async () => {
    if (name.length < 3 || checkIfEmptyString(name)) {
      setNameError({ isError: true, message: 'Company name cannot be empty and greater than 3 characters.' });
    } else {
      setStep(1);
      addNewSiteLocationMutation.mutate({ locationId: location.id, name: name });
      // setTimeout(function () {
      //   close();
      //   toast.success('Succesfully created new company');
      // }, 2000);
    }
  };

  const isNameValid = (name: string) => {
    let isValid = true;
    if (name.length < 3) {
      isValid = false;
    } else if (checkIfEmptyString(name)) {
      isValid = false;
    } else if (name[0] === ' ') {
      isValid = false;
    }
    return isValid;
  };

  // adding new site mutation
  const addNewSiteLocationMutation = useMutation({
    mutationFn: ({ locationId, name }: { locationId: string; name: string }) =>
      API_WHITEBOARD_APP_LOCATION_SITES.createNewSiteLocation({ locationId, name }),
    // When mutate is called:
    onMutate: async ({ locationId, name }: { locationId: string; name: string }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['locationSiteLocations'] });
      const previousSiteLocations = queryClient.getQueryData<SiteLocation[]>(['locationSiteLocations', locationId]);
      const formattedSiteLocation: SiteLocation = {
        id: '',
        name: name,
        locationId: locationId,
        location: {
          players: [],
        },
        createdAt: new Date(),
        backgroundImages: [],
      };
      // Snapshot the previous value
      if (previousSiteLocations) {
        queryClient.setQueryData<SiteLocation[]>(
          ['locationSiteLocations', locationId],
          [...previousSiteLocations, formattedSiteLocation]
        );
      }
      return { previousSiteLocations };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables: any, context: any) => {
      // data is what the server returns. variables are from the mutationFn parameters
      if (context && context.previousSiteLocations) {
        const newSiteLocation: SiteLocation = { ...data.siteLocation };
        newSiteLocation.backgroundImages = [];
        // const formattedNewMenu: Menu = {...data.location}
        // Replace optimistic todo in the todos list with the result
        queryClient.setQueryData<SiteLocation[]>(
          ['locationSiteLocations', variables.locationId],
          [...context.previousSiteLocations, newSiteLocation]
        );
        toast.success('Succesfully created new submenu!');
        close();
      }
    },
    // eslint-disable-next-line
    onError: (err: any, newSiteLocation: any, context: any) => {
      if (context?.previousSiteLocations) {
        queryClient.setQueryData(['locationSiteLocations', location.id], context.previousSiteLocations);
      }
      toast.error('Failed to create new Site Location');
    },
  });

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} pt={2}>
      {step === 0 ? (
        <>
          <TextField
            value={name}
            label={'Site Location Name'}
            helperText={nameError.isError ? nameError.message : ''}
            onChange={(e) => setName(e.target.value)}
          />
          <Box display={'flex'} justifyContent={'center'}>
            <Button variant='contained' disabled={isNameValid(name) ? false : true} onClick={handleSubmit}>
              <Typography color={'#fff'} fontWeight={'bold'}>
                Create new Site Location
              </Typography>
            </Button>
          </Box>
        </>
      ) : (
        <Stack direction={'column'}>
          <Typography textAlign={'center'} fontWeight={'bold'}>
            Saving new site location...
          </Typography>
          <Box position={'relative'} height={'8vh'}>
            <Loader />
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default SiteLocationForm;
