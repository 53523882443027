import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { API_MENU_MANAGEMENT_MENU } from '../../../../../api/menu-management/menu';
import { Menu } from '../../../../../api/menu-management/menu/types';
import { API_MENU_MANAGEMENT_SUBMENU } from '../../../../../api/menu-management/submenu';
import { API_MENU_MANAGEMENT_ITEMS } from '../../../../../api/menu-management/submenu-item';
import { ISubmenuItem_API } from '../../../../../api/menu-management/submenu-item/types';
import checkIfEmptyString from '../../../../../utils/check-if-empty';
import ConfirmDialog from '../../../../common/ConfirmDialog';
import Loader from '../../../../common/loader/Loader';
import { FormTypes, IMenu, useMenuContext } from '../../context/MenuFormsContext';
// import LocationForm from '../Forms/LocationForm';
import MenuForm from '../Forms/MenuForm';
import SubmenuForm from '../Forms/SubmenuForm';

type Props = {
  menu: Menu;
  menuIndex: number;
  defaultSelectedOption: string;
  handleClose: () => void;
  setNewlyCreatedMenu: (name: string) => void;
};

type FormError = {
  isError: boolean;
  message: string;
};

const resetError = {
  isError: false,
  message: '',
};

const CreationModule = ({ menu, defaultSelectedOption, handleClose, setNewlyCreatedMenu }: Props) => {
  const { forms } = useMenuContext();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const mainBlue = theme.palette.primary.main;
  // to track if form is processing something
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  // const lightGray = theme.palette.secondary.light;
  const [confirmationDialogInView, setConfirmationDialogInView] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>(defaultSelectedOption);
  // general error state
  // this will have a state for the common field: Name
  const nameRef = useRef<HTMLInputElement>(null);
  const [nameError, setNameError] = useState<FormError>(resetError);
  // state for checking track of the current step
  const [currentFormStep, setCurrentFormStep] = useState<number>(0);
  // A function to get the child Component fields and values
  // a function to submit for creation
  const handleSelectOptionChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value as string);
  };

  /**
   * This function will handle deciding if a 'Confirmation' pop needs
   * to be shown to the user before they close out on the form.
   * All the forms information are checked.
   * If all forms are null then no confirmation needs to be shown
   * If at least one form has begun to be filled then a confirmation pop
   * needs to be shown.
   */
  const handleOnClose = () => {
    if (!isProcessing) {
      let allNull = true;
      // check to see if name value is null. If not null then we show pop
      if (nameRef && nameRef.current && !checkIfEmptyString(nameRef.current.value)) {
        allNull = false;
      }
      // if name is empty then we need to check that the another inputs of the form are empty
      else {
        // check to see if all forms are null. if so then close
        const objKeys = Object.keys(forms) as Array<keyof FormTypes>;
        objKeys.map((item: keyof FormTypes) => {
          if (forms[item] != null) allNull = false;
        });
      }
      // if one of the forms is not null then we need to do a popup confirmation
      if (allNull) handleClose();
      else setConfirmationDialogInView(true);
    }
  };
  /**
   * This function is passed to <ConfirmationDialog /> that is used for
   * accepting, declining or closing the confirmation dialog
   * @param {boolean} res - the user response to the popup
   */
  const handleConfirmationDialogClick = (res: boolean): void => {
    if (res) handleClose();
    setConfirmationDialogInView(false);
  };

  const handleCreateBtnClick = () => {
    let errorCount = 0;
    // this is the form data that we will send to the backend API
    const finalFormData: { [key: string]: string } = {};
    const errors: { [key: string]: { isError: boolean; message: string } } = {};
    // check that name is not empty
    if (nameRef && nameRef.current) {
      const trimmedName = nameRef.current.value.trim();
      if (trimmedName.length < 3) {
        errorCount++;
        setNameError({ isError: true, message: 'Must be greater than 3 characters and not empty.' });
      } else {
        finalFormData.name = trimmedName;
        setNameError(resetError);
      }
    }
    // the following code is structuring the formData
    // which will vary depending on the selected form selected
    // check to see which form is currently being viewed
    if (selectedOption === 'Location') {
      if (forms.location !== null) {
        const { address, aptNumber, city, state, zip } = forms.location;
        errors.address = resetError;
        errors.aptNumber = resetError;
        errors.city = resetError;
        errors.state = resetError;
        errors.zip = resetError;
        // creating the finalFormData object
        finalFormData.address = address;
        finalFormData.aptNumber = aptNumber;
        finalFormData.city = city;
        finalFormData.state = state;
        finalFormData.zip = zip;
        // creating the finalFormatData in case we need it
        if (address.length < 8) {
          errorCount++;
          errors.address = { isError: true, message: 'Address must be greater than 3 characters' };
        }
        if (city.length < 5) {
          errorCount++;
          errors.city = { isError: true, message: 'City cannot be empty' };
        }
        if (state.length < 5) {
          errorCount++;
          errors.state = { isError: true, message: 'State cannot be empty' };
        }
        if (zip.length < 5) {
          errorCount++;
          errors.zip = { isError: true, message: 'Zip cannot be empty' };
        }
      } else {
        errorCount = 5;
        // forms.location === null so we need to through all errors
        errors.address = { isError: true, message: 'Address cannot be empty' };
        errors.aptNumber = { isError: false, message: '' };
        errors.city = { isError: true, message: 'City cannot be empty' };
        errors.state = { isError: true, message: 'State cannot be empty' };
        errors.zip = { isError: true, message: 'Zip cannot be empty' };
      }
    }
    // the remainding forms do not have any validations needed
    // we are just structuring the finalFormData
    if (selectedOption === 'Menu') {
      if (forms.menu !== null) {
        const { description } = forms.menu;
        finalFormData.description = description;
      }
    }
    if (selectedOption === 'Submenu') {
      if (forms.submenu !== null) {
        const { description } = forms.submenu;
        finalFormData.description = description;
      }
    }
    // checking to see if they have errors
    if (errorCount > 0) {
      setIsProcessing(false);
    } else {
      setIsProcessing(true);
      // we should have a way to fetch the child component
      setCurrentFormStep(1);
      createObjectInBackend({ option: selectedOption, formData: finalFormData });
    }
  };

  const createObjectInBackend = async ({
    option,
    formData,
  }: {
    option: string;
    formData: { [key: string]: string };
  }) => {
    // check to see what api will need to be called
    if (option === 'Menu') {
      const finalStructuredFormData = {
        name: formData.name,
        description: formData.description ? formData.description : '',
      };
      addNewMenuMutation.mutate(finalStructuredFormData);
    } else if (option === 'Submenu') {
      const finalStructuredFormData = {
        name: formData.name,
        description: formData.description ? formData.description : '',
        menuId: menu.id,
        position: menu.submenus && menu.submenus.length > 0 ? menu.submenus.length : 0,
      };
      addNewSubmenuMutation.mutate(finalStructuredFormData);
    } else if (option === 'Item') {
      const finalStructuredFormData = {
        name: formData.name,
        description: formData.description ? formData.description : '',
      };
      const success = await API_MENU_MANAGEMENT_ITEMS.createSubmenuItem(finalStructuredFormData);
      if (success) {
        toast.success('Successfully created item!');
        const previousSubmenuItems = queryClient.getQueryData<ISubmenuItem_API[]>(['allClientSubmenuItems']);
        // checking to see if the data has alrady been fetched
        if (previousSubmenuItems) {
          queryClient.setQueryData<ISubmenuItem_API[]>(
            ['allClientSubmenuItems'],
            [...previousSubmenuItems, success as ISubmenuItem_API]
          );
        }
      } else {
        toast.error('Failed to create item. Please try again.');
      }
      handleClose();
    }
  };
  const addNewMenuMutation = useMutation({
    mutationFn: (newMenu: IMenu) => API_MENU_MANAGEMENT_MENU.createMenu(newMenu),
    // When mutate is called:
    onMutate: async (newMenu: IMenu) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['allClientMenus'] });
      // Snapshot the previous value
      const previousPresentations = queryClient.getQueryData<Menu[]>(['allClientMenus']);
      // Optimistically update to the new value
      const formattedNewMenu: Menu = {
        id: '',
        clientId: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        locations: [],
        submenus: [],
        players: [],
        ...newMenu,
      };
      if (previousPresentations) {
        queryClient.setQueryData<Menu[]>(['allClientMenus'], [...previousPresentations, formattedNewMenu]);
      }
      // Return a context object with the snapshotted value
      return { previousPresentations };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      if (context && context.previousPresentations) {
        const newMenu: Menu = { ...data };
        // const formattedNewMenu: Menu = {...data.location}
        // Replace optimistic todo in the todos list with the result
        queryClient.setQueryData<Menu[]>(['allClientMenus'], [...context.previousPresentations, newMenu]);
        // changing the menu selected to the new menu just created
        setNewlyCreatedMenu(newMenu.name);
        toast.success('Succesfully created new menu!');
        setIsProcessing(false);
        handleClose();
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newMenu, context) => {
      if (context?.previousPresentations) {
        queryClient.setQueryData(['allClientMenus'], context.previousPresentations);
        toast.error('Failed to create new menu. Please make sure Menu name is not already in use.');
        setIsProcessing(false);
        setCurrentFormStep(0);
      }
    },
  });
  const addNewSubmenuMutation = useMutation({
    mutationFn: (newSubmenu: { name: string; description: string; menuId: string; position: number }) =>
      API_MENU_MANAGEMENT_SUBMENU.createSubmenu(newSubmenu),
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['allClientMenus'] });
      // Snapshot the previous value
      const previousMenus = queryClient.getQueryData<Menu[]>(['allClientMenus']);
      return { previousMenus };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      if (context && context.previousMenus) {
        queryClient.setQueryData<Menu[]>(['allClientMenus'], [...data]);
        toast.success('Succesfully created new submenu!');
        setIsProcessing(false);
        handleClose();
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newSubmenu, context) => {
      if (context?.previousMenus) {
        queryClient.setQueryData(['allClientMenus'], context.previousMenus);
        toast.error('Failed to create new submenu :( Please try again.');
        setIsProcessing(false);
        setCurrentFormStep(0);
      }
    },
  });
  return (
    <Dialog open={true} onClose={handleOnClose} fullWidth>
      <DialogTitle boxShadow={'1px 1px 4px #dedede'}>
        <Grid container width={'100%'}>
          <Grid item xs={10}>
            <Grid container alignItems={'center'}>
              {currentFormStep === 0 && (
                <>
                  <Grid item xs={4}>
                    <Typography fontWeight={'bold'} fontSize={{ xs: '5vw', md: '2vw' }}>
                      Create
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Select
                      id='selected-creation'
                      value={selectedOption}
                      label='Type'
                      onChange={handleSelectOptionChange}
                      fullWidth
                      variant='standard'
                      disableUnderline
                      sx={{ fontSize: { xs: '5vw', md: '2vw' } }}
                      style={{ border: 'none !important', color: mainBlue, fontWeight: 'bold' }}
                    >
                      {/* <MenuItem value={'Location'}>Location</MenuItem> */}
                      <MenuItem value={'Menu'}>Menu</MenuItem>
                      <MenuItem value={'Submenu'}>Submenu</MenuItem>
                      <MenuItem value={'Item'}>Item</MenuItem>
                    </Select>
                  </Grid>
                </>
              )}
              {currentFormStep === 1 && (
                <Typography fontWeight={'bold'} fontSize={{ xs: '5vw', md: '2vw' }} textAlign={'center'}>
                  Creating <span style={{ color: mainBlue }}>{selectedOption}</span>
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={handleOnClose}>
              <Close />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {currentFormStep === 0 && (
          <Stack pt={2}>
            <TextField inputRef={nameRef} label='Name' error={nameError.isError} helperText={nameError.message} />
            {selectedOption === 'Menu' && <MenuForm />}
            {selectedOption === 'Submenu' && <SubmenuForm />}
          </Stack>
        )}
        {currentFormStep === 1 && (
          <Box height={'20vh'}>
            <Loader />
          </Box>
        )}
      </DialogContent>
      <Box p={1} boxShadow={'1px 1px 4px #dedede'}>
        {currentFormStep === 0 && (
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item>
              <Button variant='contained' onClick={handleCreateBtnClick}>
                <Typography color={'#fff'} fontWeight={'bold'}>
                  CREATE
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleOnClose}>Cancel</Button>
            </Grid>
          </Grid>
        )}
      </Box>
      {confirmationDialogInView && (
        <ConfirmDialog
          title='Please confirm this action'
          message='Are you sure you would like to close this form? All data will be lost.'
          handleClick={handleConfirmationDialogClick}
        />
      )}
    </Dialog>
  );
};

export default CreationModule;
