import { IconButton, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useLaneContext } from '../../context/LaneContext';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate } from 'react-router-dom';
import resetLayout from '../custom-layout/utils/reset-columns';

const ChangeScreens = () => {
  const { screen, setScreen, setColumns, setIcons, setPreview } = useLaneContext();

  const handleScreenChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, newScreen: number) => {
    // if already selected keep that value
    if (newScreen !== null) {
      setScreen(newScreen);
      resetLayout(setColumns, setPreview, setIcons);
    }
  };

  const navigate = useNavigate();

  const refresh = () => {
    navigate(0);
  };

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' width='100%' sx={{ maxWidth: 1300 }}>
      <ToggleButtonGroup
        color='primary'
        exclusive
        value={screen}
        onChange={handleScreenChange}
        sx={{ alignSelf: 'flex-start', mb: 2 }}
      >
        <ToggleButton value={1}>North SSSCP</ToggleButton>
        <ToggleButton value={2}>South SSSCP</ToggleButton>
      </ToggleButtonGroup>
      <IconButton onClick={refresh}>
        <RefreshIcon />
      </IconButton>
    </Stack>
  );
};

export default ChangeScreens;
