import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage';
import checkIfEmptyString from './check-if-empty';

const RequiredLocalStoreValue = ({ storeageKey }: { storeageKey: string }) => {
  const location = useLocation();
  const [drawSiteId] = useLocalStorage(storeageKey, {} as { siteId: string; siteName: string });
  const { siteId } = drawSiteId;
  if (siteId) {
    const isEmpty = checkIfEmptyString(siteId);
    if (!isEmpty) {
      return <Outlet />;
    } else {
      return <Navigate to='/map-whiteboard' state={{ from: location }} replace />;
    }
  } else {
    return <Navigate to='/map-whiteboard' state={{ from: location }} replace />;
  }
};

export default RequiredLocalStoreValue;
