import { FormattedColumns, IColumn } from '../../../context/types';

const formatColumnsForImage = (columnsState: IColumn[]) => {
  const singleColumnWidth = 575;
  const findColumnsNotMerged = columnsState.filter((col) => col.merged === false);
  const formattedColumns: FormattedColumns = [];
  let index = 0;
  const spacing = (findColumnsNotMerged.length - 1) * 10;
  const spacingPerColumn = Math.round(spacing / findColumnsNotMerged.length);

  findColumnsNotMerged.forEach((col) => {
    const columnWidth = col.width * singleColumnWidth;
    const obj = {
      id: col.imageId,
      width: columnWidth - spacingPerColumn,
      left: index === 0 ? index : (index = index + 10), // if first, don't add spacing
    };
    index = index + columnWidth - spacingPerColumn;
    formattedColumns.push(obj);
  });
  return formattedColumns;
};

export default formatColumnsForImage;
