import { Box, ClickAwayListener, TextField, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

type Props = {
  keyName: string;
  initialValue: string;
  updateParentValue: (keyName: string, newValue: string) => void;
};

const InputEditOnClick = ({ keyName, initialValue, updateParentValue }: Props) => {
  const theme = useTheme();
  const darkBlue = theme.palette.primary.main;
  const [value, setValue] = useState<string>(initialValue);
  const [inFocus, setInFocus] = useState<boolean>(false);
  const [isError, setIsError] = useState<{ isError: boolean; message: string }>({ isError: false, message: '' });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.trim().length < 3) {
      setIsError({ isError: true, message: 'Must be greater than 3 characters and not empty.' });
    } else {
      setIsError({ isError: false, message: '' });
    }
    setValue(newValue);
  };

  const handleClickAway = () => {
    if (isError.isError) {
      setValue(initialValue);
    } else {
      updateParentValue(keyName, value);
    }
    setInFocus(false);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Box
      style={{ cursor: 'pointer' }}
      onClick={() => setInFocus(true)}
      pt={1}
      pb={1}
      display={'flex'}
      width={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {inFocus ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <TextField
            variant='outlined'
            sx={{
              '& .MuiInputBase-root': {
                input: { textAlign: 'center' },
                '>*': { border: 'none', borderRadius: '0', borderBottom: `1px solid ${darkBlue}` },
              },
              '.MuiFormHelperText-root': { color: 'red' },
            }}
            value={value}
            onChange={handleChange}
            helperText={isError.isError ? isError.message : ''}
          />
        </ClickAwayListener>
      ) : (
        <Typography
          sx={{ width: '100%', '&:hover': { backgroundColor: '#ffffff40' } }}
          pt={'2vh'}
          pb={'2vh'}
          textAlign={'center'}
          fontWeight={'bold'}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};

export default InputEditOnClick;
