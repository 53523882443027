import axios from '../../config/axios';
import toast from 'react-hot-toast';
import { WeatherCreateOptions, WeatherTokenGetData } from './types';

const create = async (options: WeatherCreateOptions): Promise<string | undefined> => {
  if (!options.location) {
    toast.error('Please Enter a Location');
    return;
  }
  try {
    const { data } = await axios.post<string>('/weather/create', options);
    return data;
  } catch (error) {
    return;
  }
};

const getAllTokens = async (): Promise<WeatherTokenGetData[] | undefined> => {
  try {
    const { data } = await axios.get<WeatherTokenGetData[]>('/weather/token');
    return data;
  } catch (error) {
    toast.error('Error Getting Weather Tokens');
  }
};

const generateToken = async (
  customer: string,
  zipCode: string,
  numberOfPlayers: number,
  callsExpected: number
): Promise<string | undefined> => {
  const body = {
    customer,
    zipCode,
    numberOfPlayers,
    callsExpected,
  };

  try {
    const res = await axios.post<{ token: string }>('/weather/token', { ...body });
    return res.data.token;
  } catch (error) {
    toast.error('Error Generating Widget');
  }
};

const removeToken = async (id: string): Promise<boolean> => {
  try {
    await axios.delete(`/weather/token/${id}`);
    toast.success('Token Deleted Successfully');
    return true;
  } catch (error) {
    toast.error('Error Please Try Again');
    return false;
  }
};

export default removeToken;

export { create, getAllTokens, generateToken, removeToken };
