import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { API_PRESENTATION } from '../../../api/digital-signage-cms/presentation';
import Loader from '../../../components/common/loader/Loader';
import Presentations from '../../../components/digital-signage-cms/presentations/Presentations';
import { useAuthContext } from '../../../context/AuthContext';
import { decrypt } from '../../../utils/cryptojs-helper';

const PresentationsPage = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const user = JSON.parse(decrypted);
  const { error, data } = useQuery(['allClientPresentations'], () =>
    API_PRESENTATION.getAllPresentations(user.currentClient.client.id)
  );
  if (error) {
    return <Typography>Error</Typography>;
  }
  if (data) {
    return <Presentations allPresentations={data} user={user} />;
  }
  return <Loader />;
};

export default PresentationsPage;
