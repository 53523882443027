import { Archive, Delete } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { API_WHITEBOARD_APP_DRAWING } from '../../../../api/whiteboard/drawing';
import { archiveSiteLocation, deleteSiteLocation } from '../../../../api/whiteboard/map/site/api';
import { SiteLocation } from '../../../../api/whiteboard/map/site/types';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { encrypt } from '../../../../utils/cryptojs-helper';
import ConfirmDialog from '../../../common/ConfirmDialog';
import s from '../../style.module.scss';
import textSizing from '../../textSizing';
import LabeledWBHistory from './LabeledWBHistory';

type Props = {
  siteId: string | null;
  activeSite: SiteLocation | null;
  lightGray: string;
  locationId: string;
  resetActiveSite: () => void;
  handleNewMapClick: (dialogType: string) => void;
};

const MapSection = ({ siteId, activeSite, lightGray, locationId, handleNewMapClick, resetActiveSite }: Props) => {
  // eslint-disable-next-line
  const [drawSiteId, setDrawSiteId] = useLocalStorage('draw_site', {
    siteId: '',
    siteName: '',
  } as { siteId: string; siteName: string });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [actionType, setActionType] = useState<string>('delete');
  const [moduleInView, setModuleInView] = useState<boolean>(false);
  const [deletionModuleInView, setDeletionModuleInView] = useState<boolean>(false);
  const [historyModuleInView, setHistoryModuleInView] = useState<boolean>(false);
  const handleOverwriteConfirmation = (res: boolean) => {
    // they want to overwrite, so we can show the form
    if (res) {
      handleNewMapClick('add-map');
    }
    setModuleInView(false);
  };

  // function for delete button clicked
  const handleSiteLocationDelete = async (res: boolean) => {
    if (actionType == 'delete' && res && activeSite) {
      try {
        await deleteSiteLocation(locationId, activeSite.id);
        // we need to filter out the deleted
        const previousSiteLocations = queryClient.getQueryData<SiteLocation[]>(['locationSiteLocations', locationId]);
        if (previousSiteLocations) {
          const _previousSiteLocations = [...previousSiteLocations];
          const _filteredSiteLocations = _previousSiteLocations.filter((site) => {
            return site.id !== activeSite.id;
          });
          queryClient.setQueryData<SiteLocation[]>(['locationSiteLocations', locationId], _filteredSiteLocations);
          resetActiveSite();
        }
        toast.success('Succesfully deleted site location.');
      } catch (error) {
        toast.error('Failed to delete site location.');
      }
    }
    if (actionType == 'archive' && res && activeSite) {
      try {
        await archiveSiteLocation(locationId, activeSite.id);
        toast.success('Succesfully archived site location.');
      } catch (error) {
        toast.error('Failed to archive site location.');
      }
    }
    setDeletionModuleInView(false);
  };

  const handleArchiveOrDeleteBtnClick = (type: string) => {
    setDeletionModuleInView(true);
    setActionType(type);
  };

  const handleHistoryButtonClick = () => {
    setHistoryModuleInView(true);
  };

  const handleDrawModeClick = async () => {
    if (activeSite) {
      // before sending the user to the draw mode we have to check availability
      // `/drawing/${activeSite.id}`
      const inDrawMode = await API_WHITEBOARD_APP_DRAWING.checkWBMapDrawingAvailability({ locationId: activeSite.id });
      if (!inDrawMode) {
        // first we need to set the local storage value of the siteId they
        // want to draw
        const encryptedSiteId = encrypt(JSON.stringify(activeSite.id));
        setDrawSiteId({ siteId: encryptedSiteId, siteName: activeSite.name });
        navigate('/drawing');
      } else {
        toast.error('Map is currently being used. Please wait until it is released to try again.');
      }
    }
  };

  return (
    <>
      <Grid container width={{ xs: '85%', md: '80%', lg: '86%' }} height={'70%'}>
        {activeSite ? (
          // check to see if this site has an active background image
          activeSite.backgroundImages.length > 0 ? (
            <>
              <Grid
                item
                xs={12}
                pl={4}
                pr={2}
                height={'8%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                flexDirection={'row'}
                borderBottom={`2px solid ${lightGray}`}
              >
                <Typography fontSize={{ xs: textSizing.xs.sectionTitles }} width={'40%'}>
                  Site Name: {siteId}
                </Typography>
                <Stack direction={'row'} width={'5%'} justifyContent={'center'} alignItems={'center'}>
                  <Box className={s.live}>
                    <div className={s.activeDot}></div>
                  </Box>
                  <Typography fontSize={{ xs: textSizing.xs.sectionTitles }} pl={1}>
                    Active
                  </Typography>
                </Stack>
                <Box display={'flex'} gap={1}>
                  <Button
                    onClick={handleDrawModeClick}
                    variant='contained'
                    sx={{
                      background:
                        'linear-gradient(45deg, #8b0000, #8b4513, #808000, #006400, #00008b, #2f4f4f, #4b0082);',
                    }}
                  >
                    <Typography
                      color={'#fff'}
                      fontWeight={'bold'}
                      fontSize={{ xs: textSizing.xs.button, lg: textSizing.lg.button }}
                    >
                      Draw Mode
                    </Typography>
                  </Button>
                  <Button variant='contained' onClick={() => setModuleInView(true)}>
                    <Typography
                      color={'#fff'}
                      fontWeight={'bold'}
                      fontSize={{ xs: textSizing.xs.button, lg: textSizing.lg.button }}
                    >
                      Add Map
                    </Typography>
                  </Button>
                  <Button variant='contained' onClick={handleHistoryButtonClick}>
                    <Typography
                      color={'#fff'}
                      fontWeight={'bold'}
                      fontSize={{ xs: textSizing.xs.button, lg: textSizing.lg.button }}
                    >
                      History
                    </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} height={'82%'}>
                <img src={activeSite.backgroundImages[0].bucketURL} width={'100%'} height={'100%'} />
              </Grid>
              <Grid item xs={12} height={'10%'} display={'flex'} alignItems={'center'} justifyContent={'right'}>
                <Stack direction={'row'} width={'20%'} alignItems={'center'} justifyContent={'right'}>
                  <IconButton onClick={() => handleArchiveOrDeleteBtnClick('archive')}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                      <Typography>Archive</Typography>
                      <Archive />
                    </Stack>
                  </IconButton>

                  <IconButton onClick={() => handleArchiveOrDeleteBtnClick('delete')}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                      <Typography>Delete</Typography>
                      <Delete />
                    </Stack>
                  </IconButton>
                </Stack>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} height={'90%'}>
                <Box
                  height={'100%'}
                  display={'flex'}
                  flexDirection={'column'}
                  gap={2}
                  alignItems={'center'}
                  justifyContent={'center'}
                  width={'100%'}
                >
                  <Typography fontWeight={'bold'}>
                    There is no active map associated with this site location. Please connect a map.
                  </Typography>
                  <Button variant='contained' onClick={() => handleNewMapClick('add-map')}>
                    <Typography
                      color={'#fff'}
                      fontWeight={'bold'}
                      fontSize={{ xs: textSizing.xs.button, lg: textSizing.lg.button }}
                    >
                      Add Map
                    </Typography>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} height={'10%'} display={'flex'} alignItems={'center'} justifyContent={'right'}>
                <Stack direction={'row'} width={'20%'} alignItems={'center'} justifyContent={'right'}>
                  <IconButton onClick={() => handleArchiveOrDeleteBtnClick('delete')}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                      <Typography>Delete</Typography>
                      <Delete />
                    </Stack>
                  </IconButton>
                </Stack>
              </Grid>
            </>
          )
        ) : (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'100%'}>
            <Typography fontWeight={'bold'} textAlign={'center'}>
              Please select a site location
            </Typography>
          </Box>
        )}
      </Grid>
      {moduleInView && (
        <ConfirmDialog
          title={'Please confirm this action'}
          message={
            'There is an active map for this site location. Uploading a new map will overwrite the current map. Would you like to overwrite the current active map?'
          }
          handleClick={handleOverwriteConfirmation}
        />
      )}
      {deletionModuleInView && (
        <ConfirmDialog
          title={'Please confirm this action'}
          message={`Are you sure you would like to ${actionType} this site location?`}
          handleClick={handleSiteLocationDelete}
        />
      )}
      {historyModuleInView && activeSite && (
        <LabeledWBHistory siteLocation={activeSite} onClose={() => setHistoryModuleInView(false)} />
      )}
    </>
  );
};

export default MapSection;
