import { createTheme } from '@mui/material';

const MUITheme = createTheme({
  palette: {
    primary: {
      main: 'rgb(23, 133, 196)',
      contrastText: 'rbg(255,255,255)',
    },
    secondary: {
      main: 'rgb(86, 184, 92)',
      light: 'rgb(222, 222, 222)',
    },
    error: {
      main: 'rgb(204, 0, 71)',
    },
    info: {
      main: '#fff',
      light: 'rgba(0, 0, 0, 0.6)',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.4rem',
          boxShadow: 'none',
          fontWeight: 'bold',
          padding: '5px 20px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '0.4rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '0.6rem',
          boxShadow: '0 1rem 1.5rem rgba(129, 138, 152, 0.1);',
          border: '1px solid #d2d8e4',
          padding: '3.5rem 2rem 2rem 2rem',
          width: '100%',
          position: 'relative',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          position: 'absolute',
          left: '0px',
          top: '0px',
          width: '100%',
          fontSize: 18,
          fontWeight: 'bold',
          borderBottom: '1px solid #d2d8e4',
          padding: '10px 10px 10px 15px',
        },
      },
    },
  },
});

export default MUITheme;
