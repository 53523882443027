import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useState } from 'react';
import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import DIMENSIONS_NUMBERS from '../../data/dimensions-numbers';
import toast from 'react-hot-toast';
import Preview from '../Preview';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Props } from './types';
import s from '../../styles.module.scss';
import BaseInput from '../../../common/BaseInput';
import { API_WEATHER } from '../../../../api/weather';

const StepTwo = ({
  dimensions,
  setDimensions,
  setSteps,
  setShowCity,
  setHtml,
  location,
  setLocation,
  showCity,
  showFiveDay,
  setShowFiveDay,
  coords,
  zipCode,
  customer,
}: Props) => {
  const [numberOfPlayers, setNumberOfPlayers] = useState(1);

  const changeNumberOfPlayers = (type: string) => {
    if (type === 'add') setNumberOfPlayers(numberOfPlayers + 1);
    if (type === 'sub' && numberOfPlayers > 1) setNumberOfPlayers(numberOfPlayers - 1);
  };

  const generateWidget = async () => {
    let calls = 720;
    if (showFiveDay) calls = 960;

    const callsExpected = calls * numberOfPlayers;

    const token = await API_WEATHER.generateToken(customer, zipCode, numberOfPlayers, callsExpected);
    if (!token) return toast.error('Error Please Try Again');
    const options = {
      width: dimensions.width,
      height: dimensions.height,
      location,
      coordinates: coords,
      showFiveDay,
      showLocation: showCity,
      token,
    };

    const htmlData = await API_WEATHER.create(options);

    if (!htmlData) return toast.error('Error Please Try Again');

    setHtml(htmlData);
    setSteps(3);
  };

  return (
    <>
      <Divider sx={{ width: '100%', mb: 1 }} />
      <h3>Settings</h3>
      <FormGroup>
        <FormControlLabel
          label='Five Day'
          control={<Checkbox defaultChecked onChange={() => setShowFiveDay((prev) => !prev)} />}
        />
        <FormControlLabel
          label='Location'
          control={<Checkbox defaultChecked onChange={() => setShowCity((prev) => !prev)} />}
        />
      </FormGroup>
      <Stack alignItems='center' my={2}>
        Number Of Players:
        <Box display='flex' justifyContent='center' alignItems='center'>
          <IconButton onClick={() => changeNumberOfPlayers('sub')}>
            <RemoveIcon />
          </IconButton>
          <Box className={s.playersNumber}>{numberOfPlayers}</Box>
          <IconButton onClick={() => changeNumberOfPlayers('add')}>
            <AddIcon />
          </IconButton>
        </Box>
      </Stack>
      <Stack direction='row' alignItems='center'>
        <BaseInput
          label='Location'
          size='medium'
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          sx={{ width: 300, mb: 1 }}
        />
        <FormControl sx={{ minWidth: 100 }}>
          <InputLabel id='dimensions'>Dimensions</InputLabel>
          <Select
            labelId='dimensions'
            label='Dimensions'
            value={JSON.stringify(dimensions)}
            onChange={(e) => setDimensions(JSON.parse(e.target.value))}
          >
            {DIMENSIONS_NUMBERS.map((dimensions) => {
              if (dimensions.width <= 400 && dimensions.height <= 400 && showFiveDay) return;
              return (
                <MenuItem value={JSON.stringify(dimensions)} key={dimensions.width}>
                  {dimensions.width}px by {dimensions.height}px
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack>

      <Divider sx={{ width: '100%', my: 2 }} />

      <h3 style={{ marginTop: 10 }}>Preview</h3>
      <span>(the preview is smaller for viewing purposes)</span>
      <Preview dimensions={dimensions} showFiveDay={showFiveDay} showCity={showCity} location={location} />
      <Box mt={1}>
        <Button startIcon={<ArrowBackIcon />} sx={{ mr: 2 }} onClick={() => setSteps(1)}>
          Go Back
        </Button>
        <Button variant='contained' color='error' endIcon={<NavigateNextIcon />} onClick={() => generateWidget()}>
          Generate
        </Button>
      </Box>
    </>
  );
};

export default StepTwo;
