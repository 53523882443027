import { Dispatch, SetStateAction } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
// import { IUser } from '../../context/AuthContext';
import { encrypt } from '../../utils/cryptojs-helper';
import { UserGetData, UserLoginFormData, UserRegisterData, UserUpdateData, UserUpdatePasswordData } from './types';

// gets all users if admin and gets users by specific client if not admin
const getAll = async ({ queryKey }: { queryKey: [string, string] }): Promise<UserGetData[] | undefined> => {
  // the second item of array of queryKey contains the url passed to function
  const url = queryKey[1];

  try {
    const { data } = await axios.get<UserGetData[]>(url);
    return data;
  } catch (err) {
    toast.error('Error Getting Users');
  }
};

const getSingle = async ({ queryKey }: { queryKey: [string, string] }): Promise<UserGetData | undefined> => {
  // the second item of array of queryKey contains the id passed to function
  const id = queryKey[1];

  try {
    const { data } = await axios.get<UserGetData>(`/user/one/${id}`);
    return data;
  } catch (err) {
    toast.error('Error Getting User Data');
  }
};

const register = async (data: UserRegisterData): Promise<boolean> => {
  try {
    await axios.post('/auth/register', { ...data });
    toast.success('User Created Successfully');
    return true;
  } catch (error) {
    toast.error('Failed to create User. Please Try Again');
    return false;
  }
};

const login = async (
  formData: UserLoginFormData,
  // setUser: Dispatch<SetStateAction<IUser>>,
  setUserNew: Dispatch<SetStateAction<string>>,
  setLoading: Dispatch<SetStateAction<boolean>>
): Promise<boolean> => {
  setLoading(true);
  try {
    const { data } = await axios.post('/auth/login', {
      email: formData.email,
      password: formData.password,
    });
    // the roles received from the backend are Objects. We will iterate through the list
    // and grab the code to make an array of numbers.
    // eslint-disable-next-line
    data.user.clients.forEach(
      (cc: {
        client: {
          id: string;
          name: string;
          limitedTokenAccess: boolean;
          expirationTime: string | null;
          // eslint-disable-next-line
          roles: any;
        };
      }) => {
        const roleCodes: number[] = [];
        cc.client.roles.forEach((role: { role: { code: number } }) => {
          roleCodes.push(role.role.code);
        });
        cc.client.roles = roleCodes;
      }
    );
    data.user.currentClient = data.user.clients[0];

    const formattedPermissions: { [key: string]: string[] } = {};
    // eslint-disable-next-line
    data.user.permissions.map(({ client, permission }: any) => {
      const tempClient = client.name;
      if (tempClient in formattedPermissions) {
        formattedPermissions[tempClient].push(permission.name);
      } else {
        formattedPermissions[tempClient] = [permission.name];
      }
    });
    data.user.permissions = formattedPermissions;

    const encryptedUser = encrypt(JSON.stringify(data.user));
    setUserNew(encryptedUser);
    setLoading(false);
    return true;
  } catch (error) {
    toast.error('Invalid Credentials');
    setLoading(false);
    return false;
  }
};

const update = async (data: UserUpdateData, id: string): Promise<boolean> => {
  try {
    await axios.put(`/user/update/${id}`, { ...data });
    toast.success('User Updated Successfully');
    return true;
  } catch (error) {
    toast.error('Error Updating User. Please Try Again');
    return false;
  }
};

const remove = async (id: string): Promise<boolean> => {
  try {
    await axios.delete(`/user/delete/${id}`);
    toast.success('User Deleted Successfully');
    return true;
  } catch (error) {
    toast.error('Error Please Try Again');
    return false;
  }
};

const updatePassword = async (id: string, data: UserUpdatePasswordData) => {
  try {
    await axios.put(`/user/update/${id}`, { ...data });
    toast.success('Password Updated Successfully');
    return true;
  } catch (error) {
    toast.error('Error Please Try Again');
    return false;
  }
};

export { getAll, getSingle, login, register, remove, update, updatePassword };
