import { Box, Button, Checkbox, Grid, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { connectPlayersToSiteLocation } from '../../../../api/player-management/api';
import { LocationData } from '../../../../api/whiteboard/map/location/types';
import { SiteLocation } from '../../../../api/whiteboard/map/site/types';
import ConfirmDialog from '../../../common/ConfirmDialog';

type Props = {
  locationData: LocationData;
  siteLocation: SiteLocation | null;
  close: () => void;
};

const PlayerManagement = ({ locationData, siteLocation, close }: Props) => {
  const queryClient = useQueryClient();
  const { players } = locationData.location;
  const [selectedPlayers, setSelectedPlayers] = useState<
    { id: string; name: string; siteLocation: { id: string; name: string }; isSelected: boolean }[]
  >(
    players.map((player) => {
      return {
        ...player,
        isSelected: player.siteLocation && siteLocation && player.siteLocation.id === siteLocation.id ? true : false,
      };
    })
  );
  const [moduleInView, setModuleInView] = useState<boolean>(false);
  const handlePlayerConnectConfirmation = async (res: boolean) => {
    // make an API call to update the players
    if (res && siteLocation) {
      // iterating through the players to check which ones have been selected for this site
      const finalSelectedPlayers = selectedPlayers.filter((player) => {
        return player.isSelected;
      });
      const successfullyConnectedPlayers = await connectPlayersToSiteLocation(finalSelectedPlayers, siteLocation);
      if (successfullyConnectedPlayers) {
        // we need to iterate through finalSelectedPlayers
        const _locationData = { ...locationData };
        const _updatedPlayers = [];
        for (let i = 0; i < finalSelectedPlayers.length; i++) {
          const foundIndex = locationData.location.players.findIndex((player) => {
            return player.id === finalSelectedPlayers[i].id;
          });
          if (foundIndex) {
            const _player = { ...locationData.location.players[foundIndex] };
            _player.siteLocation = finalSelectedPlayers[i].siteLocation;
            _updatedPlayers.push(_player);
          }
        }
        _locationData.location.players = _updatedPlayers;
        queryClient.setQueryData<LocationData>(['clientLocation'], _locationData);
        toast.success('Player is now connected to this site!');
        close();
      } else {
        toast.error('Failed to connect player to site location!');
      }
    }
    setModuleInView(false);
  };

  const updateSelectedPlayerStatus = (index: number) => {
    const _selectedPlayers = [...selectedPlayers];
    const _checkedPlayer = _selectedPlayers[index];
    _checkedPlayer.isSelected = !_checkedPlayer.isSelected;
    _selectedPlayers[index] = _checkedPlayer;
    setSelectedPlayers(_selectedPlayers);
  };

  return (
    <>
      <Box height={'25vh'}>
        <Stack direction={'row'} gap={1} height={'10%'}>
          <Typography>Connecting to site location</Typography>
          <Typography fontWeight={'bold'}>{siteLocation?.name}</Typography>
        </Stack>
        <Box height={'75%'} overflow={'scroll'}>
          {selectedPlayers.map(
            (
              player: { id: string; name: string; siteLocation: { id: string; name: string }; isSelected: boolean },
              index: number
            ) => {
              return (
                <Grid container key={player.id} justifyContent={'center'} alignItems={'center'}>
                  <Grid item xs={2}>
                    <Checkbox checked={player.isSelected} onClick={() => updateSelectedPlayerStatus(index)} />
                  </Grid>
                  <Grid item xs={5}>
                    <Typography textAlign={'center'}>{player.name}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {player.siteLocation && <Typography color={'gray'}>({player.siteLocation.name})</Typography>}
                  </Grid>
                </Grid>
              );
            }
          )}
        </Box>
        <Box height={'15%'} display={'flex'} justifyContent={'center'}>
          <Button variant='contained' onClick={() => setModuleInView(true)}>
            <Typography color={'#fff'}>Connect Players</Typography>
          </Button>
        </Box>
      </Box>
      {moduleInView && (
        <ConfirmDialog
          title={'Please confirm your action'}
          message={
            'Updating player connections will overwrite any previous connections players may already have. Are you sure you want to update these connections?'
          }
          handleClick={handlePlayerConnectConfirmation}
        />
      )}
    </>
  );
};

export default PlayerManagement;
