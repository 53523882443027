import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { Box, Card } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../../context/AuthContext';
import { decrypt } from '../../../../utils/cryptojs-helper';
import Header from '../../../common/header';
import Loader from '../../../common/loader';
import { API_LANE_MANAGEMENT } from '../../api';
import { useLaneContext } from '../../context/LaneContext';
import Buttons from './components/Buttons';
import Column from './components/Column';
import Icon from './components/Icon';
import Preview from './components/Preview';
import SaveImageModal from './components/SaveImageModal';
import s from './styles.module.scss';
import checkColumnsNull from './utils/check-columns-null';
import formatColumnsForImage from './utils/format-columns-for-image';
import resetLayout from './utils/reset-columns';

const CustomLayout = () => {
  const { columns, setColumns, preview, setPreview, screen, icons, setIcons } = useLaneContext();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);
  const currentClient = user.currentClient.client.name.toLowerCase();

  const publishImage = async () => {
    const config = [{ columns }, { icons }];
    const formattedColumns = formatColumnsForImage(columns);
    const args = {
      client: currentClient,
      screen,
      config,
      setLoading,
      setColumns,
      setPreview,
      setIcons,
      formattedColumns,
    };
    const success = await API_LANE_MANAGEMENT.publish(args);

    // if success refresh page else do nothing
    success
      ? setTimeout(() => {
          navigate(0);
          window.scrollTo(0, 0);
        }, 3000)
      : '';
  };

  const saveImage = async () => {
    const isNull = checkColumnsNull(columns);
    if (isNull) return toast.error('Please Fill All Columns');

    const columnsData = formatColumnsForImage(columns);

    await API_LANE_MANAGEMENT.create(columnsData, icons, setLoading, setPreview, currentClient, screen);
  };

  const openModal = () => {
    const isNull = checkColumnsNull(columns);
    if (isNull) return toast.error('Please Fill All Columns');
    setIsOpen(true);
  };

  const moveImage = async (selectNumber: number) => {
    const config = [{ columns }, { icons }];
    const formattedColumns = formatColumnsForImage(columns);

    const args = { config, formattedColumns, selectNumber, client: currentClient, screen };

    const success = await API_LANE_MANAGEMENT.replace(args, setLoading);
    setIsOpen(false);
    // if success refresh page else do nothing
    success
      ? setTimeout(() => {
          navigate(0);
        }, 3000)
      : '';
  };
  return (
    <>
      <Card className={s.container} id='custom-layout'>
        <Header text='Custom Layout' Icon={<DashboardCustomizeIcon />} />
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box className={s.row}>
              {columns.map((column, index) => (
                <Column key={index} column={column} />
              ))}
            </Box>
            <Box className={s.colorGroupRow}>
              {icons.map((icon) => (
                <Icon key={icon.column} data={icon} />
              ))}
            </Box>
            <Buttons resetLayout={resetLayout} openModal={openModal} saveImage={saveImage} />
            {preview && <Preview preview={preview} publishImage={publishImage} />}
          </>
        )}
      </Card>
      <SaveImageModal isOpen={isOpen} setIsOpen={setIsOpen} moveImage={moveImage} />
    </>
  );
};

export default CustomLayout;
