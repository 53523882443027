import axios from '../../../../config/axiosV2';
import { WBMedia } from './types';

const getAllLocationMedia = async (locationID: string): Promise<WBMedia[]> => {
  const url = `/whiteboard/map/all-media/${locationID}`;
  try {
    const { data } = await axios.get(url);
    const { media }: { media: WBMedia[] } = data;
    return media;
  } catch {
    return [];
  }
};

const deleteWBMedia = async (locationId: string, mediaId: string): Promise<string | null> => {
  const url = `/whiteboard/map/delete/icon/${locationId}/${mediaId}`;
  try {
    const { data } = await axios.delete(url);
    const { mediaIdDeleted }: { mediaIdDeleted: string } = data;
    return mediaIdDeleted;
  } catch (error) {
    return null;
  }
};

export { deleteWBMedia, getAllLocationMedia };
