import { Delete } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, FormGroup, TableCell, TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { MediaInPlaylist } from '../../../../api/digital-signage-cms/types';

type Props = {
  index: number;
  includeValidityDate: boolean;
  mediaDetails: MediaInPlaylist;
  handleRowDeletion: (index: number) => void;
  addValidityDates: (index: number, hasDates: boolean) => void;
  handleMediaDurationChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
};

const TableRowData = ({
  index,
  includeValidityDate,
  mediaDetails,
  handleRowDeletion,
  addValidityDates,
  handleMediaDurationChange,
}: Props) => {
  const [validityCheckbox, setValidityCheckbox] = useState<boolean>(false);

  const handleMediaValidityCheck = () => {
    const _validityCheckbox = !validityCheckbox;
    addValidityDates(index, _validityCheckbox);
    setValidityCheckbox(_validityCheckbox);
  };

  // if validityDate is removed we need to uncheck the Checkbox
  useEffect(() => {
    if (!includeValidityDate) {
      setValidityCheckbox(false);
    }
  }, [includeValidityDate]);

  useEffect(() => {
    if (mediaDetails.validityEndDate || mediaDetails.validityStartDate) {
      setValidityCheckbox(true);
    } else {
      setValidityCheckbox(false);
    }
  }, [mediaDetails.validityEndDate, mediaDetails.validityStartDate]);

  return (
    <>
      {includeValidityDate ? (
        <TableCell>
          <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FormControlLabel
              control={<Checkbox checked={validityCheckbox} onChange={handleMediaValidityCheck} />}
              label=''
            />
          </FormGroup>
        </TableCell>
      ) : (
        ''
      )}
      <TableCell component='th' scope='row' width={'10%'}>
        {index + 1}
      </TableCell>
      <TableCell align='center' width={'35%'}>
        <img src={mediaDetails.media?.previewBucketURL}></img>
      </TableCell>
      <TableCell align='center' width={'30%'}>
        {mediaDetails.media?.name}
      </TableCell>
      <TableCell align='center' width={'15%'}>
        <TextField
          style={{ padding: 0, height: '100%' }}
          type={'number'}
          value={
            mediaDetails.media?.mimeType == 'video/mp4'
              ? Math.floor(mediaDetails.duration as number)
              : mediaDetails.duration
          }
          onChange={(event) => handleMediaDurationChange(event, index)}
          disabled={mediaDetails.media?.mimeType == 'video/mp4' ? true : false}
        />
      </TableCell>
      {includeValidityDate ? (
        <>
          <TableCell align='center' width={'15%'}>
            <Typography>
              {mediaDetails.validityStartDate ? mediaDetails.validityStartDate.toString() : 'N/A'}
            </Typography>
          </TableCell>
          <TableCell align='center' width={'15%'}>
            <Typography>{mediaDetails.validityEndDate ? mediaDetails.validityEndDate.toString() : 'N/A'}</Typography>
          </TableCell>
        </>
      ) : (
        ''
      )}
      <TableCell align='center' width={'10%'}>
        <Button onClick={() => handleRowDeletion(index)}>
          <Delete htmlColor='red' />
        </Button>
      </TableCell>
    </>
  );
};

export default TableRowData;
