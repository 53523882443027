import { Box, Button, Grid, List, ListItem, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { LocationData } from '../../../../api/whiteboard/map/location/types';
import { deleteWBMedia } from '../../../../api/whiteboard/map/media/api';
import { WBMedia } from '../../../../api/whiteboard/map/media/types';
import { SiteLocation } from '../../../../api/whiteboard/map/site/types';
import Loader from '../../../common/loader';
import textSizing from '../../textSizing';
import HoverIcons from '../commmon/HoverIcons';
import FormDialog from '../forms/FormDialog';

type Props = {
  media: WBMedia[] | null;
  lightGray: string;
  location: LocationData;
  siteLocation: SiteLocation | null;
  handleAddIconClick: (type: string) => void;
};

const IconsSection = ({ media, location, siteLocation, lightGray, handleAddIconClick }: Props) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const mainBlue = theme.palette.primary.main;
  const [editFormInView, setEditFormInView] = useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<WBMedia | null>(null);

  const handleEditButtonClick = (item: WBMedia) => {
    setEditFormInView(true);
    setItemToEdit(item);
  };

  const handleDeleteButtonClick = async (item: WBMedia) => {
    const deletedMedia = await deleteWBMedia(location.location.id, item.id);
    if (deletedMedia) {
      // making a copy and removing that media
      const _locationData = { ...location };
      const _media = [..._locationData.media];
      const _filteredMedia = _media.filter((media) => {
        return media.id !== item.id;
      });
      _locationData.media = _filteredMedia;
      queryClient.setQueryData<LocationData>(['clientLocation'], _locationData);
      toast.success('succesfully deleted Icon!');
    } else {
      toast.error('Failed to delete Icon');
    }
  };

  return (
    <Box width={{ xs: '15%', md: '20%', lg: '14%' }} height={'100%'} borderLeft={`4px solid ${lightGray}`}>
      {media ? (
        <>
          <Box height={{ xs: '6%' }} borderBottom={`4px solid ${lightGray}`} display={'flex'} alignItems={'center'}>
            <Typography fontSize={'1.2vw'} fontWeight={'bold'} textAlign={'center'} width={'100%'}>
              Icons ({media.length})
            </Typography>
          </Box>
          <Box
            height={{ xs: '86%' }}
            overflow={'scroll'}
            sx={{ scrollbarWidth: 'none', '&::-webkit-scrollbar': { display: 'none' } }}
          >
            <List>
              {media.map((data: WBMedia) => {
                return (
                  <ListItem key={data.id}>
                    <HoverIcons
                      br={8}
                      handleEditClick={() => handleEditButtonClick(data)}
                      handleDeleteClick={() => handleDeleteButtonClick(data)}
                    >
                      <Grid
                        container
                        bgcolor={'#fff'}
                        height={'14vh'}
                        width={'100%'}
                        borderRadius={8}
                        border={'2px solid #000'}
                      >
                        <Grid item xs={12} height={'20%'}>
                          <Typography fontSize={'1vw'} fontWeight={'bold'} textAlign={'center'} width={'100%'}>
                            {data.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} height={'80%'}>
                          <Box overflow={'hidden'} height={'100%'} width={'100%'} borderRadius={8}>
                            <img src={data.bucketURL} alt='placeholder icon' width={'100%'} height={'100%'} />
                          </Box>
                        </Grid>
                      </Grid>
                    </HoverIcons>
                  </ListItem>
                );
              })}
            </List>
          </Box>
          <Box
            height={{ xs: '8%' }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            borderTop={`4px solid ${lightGray}`}
          >
            <Button variant='contained' onClick={() => handleAddIconClick('icon-form')}>
              <Typography
                fontWeight={'bold'}
                color={'#fff'}
                fontSize={{ xs: textSizing.xs.button, lg: textSizing.lg.button }}
              >
                Add Icon
              </Typography>
            </Button>
          </Box>
          {editFormInView && (
            <FormDialog
              type={'icon-form'}
              location={location}
              siteLocation={siteLocation}
              closeDialog={() => setEditFormInView(false)}
              mainBgColor={mainBlue}
              itemToEdit={itemToEdit}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default IconsSection;
