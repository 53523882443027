import { useQuery } from '@tanstack/react-query';
import PlayerManagement from '../../components/player-management/PlayerManagement';
import { API_PLAYER_MANAGEMENT } from '../../api/player-management';
import { Typography } from '@mui/material';
import Loader from '../../components/common/loader';

const PlayerManagementPage = () => {
  const { error, data } = useQuery(['AllPlayers'], API_PLAYER_MANAGEMENT.getAllPlayers);
  if (error) {
    return <Typography>Failed to Load PLayers</Typography>;
  }
  if (data) {
    return <PlayerManagement allPlayers={data} />;
  }
  return <Loader />;
};

export default PlayerManagementPage;
