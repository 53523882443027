import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { API_PLAYLIST } from '../../../api/digital-signage-cms/playlist';
import { PlaylistData } from '../../../api/digital-signage-cms/types';
import Loader from '../../../components/common/loader/Loader';
import EditPlaylistView from '../../../components/digital-signage-cms/edit-playlist/EditPlaylistView';
import { useAuthContext } from '../../../context/AuthContext';
import { decrypt } from '../../../utils/cryptojs-helper';

const EditPlaylistPage = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const user = JSON.parse(decrypted);
  const { id } = useParams();
  const { error, data: playlistData } = useQuery<PlaylistData>(['playlistDetails', id], () =>
    API_PLAYLIST.getPlaylistById(id, user.currentClient.client.id)
  );

  if (error) return <Typography>Failed to load </Typography>;
  if (playlistData) {
    return <EditPlaylistView playlist={playlistData} user={user} />;
  } else return <Loader />;
};

export default EditPlaylistPage;
