import { TableData } from '../types';

const createData = (
  id: string,
  customer: string,
  zipCode: string,
  numberOfPlayers: number,
  callsExpected: number,
  callsActual: number,
  resetCallsDate: Date
): TableData => {
  return { id, customer, zipCode, numberOfPlayers, callsExpected, callsActual, resetCallsDate };
};

export default createData;
