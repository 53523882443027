import { createContext, ReactNode, useContext } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import useLocalStorage from '../hooks/useLocalStorage';
import { IClient } from '../types';

export interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  token: string;
  clients: IClient[];
  currentClient: IClient;
  passwordExpiresAt: Date;
  // eslint-disable-next-line
  permissions: any;
}

interface IAuthContext {
  user: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser>>;
  logout: () => void;
  userNew: string;
  setUserNew: React.Dispatch<React.SetStateAction<string>>;
}

const AuthContext = createContext({} as IAuthContext);

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useLocalStorage<IUser>('user', {} as IUser);

  const [userNew, setUserNew] = useLocalStorageState('u_details', {
    defaultValue: '',
  });

  const logout = () => {
    // setUser({} as IUser);
    setUserNew('');
    localStorage.removeItem('u_details');
  };

  return <AuthContext.Provider value={{ user, setUser, logout, userNew, setUserNew }}>{children}</AuthContext.Provider>;
};
