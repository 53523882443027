import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { Playlist, PlaylistData, Zone, ZoneSimplePlaylist } from '../../../../api/digital-signage-cms/types';

type Props = {
  playlistsData: Playlist;
  previousZones: Zone[];
  updateParentZones: Dispatch<SetStateAction<Zone[]>>;
};

const ZoneCreation = ({ previousZones, playlistsData, updateParentZones }: Props) => {
  const theme = useTheme();
  const secondaryLightGray = theme.palette.secondary.light;
  const factor = 8;
  const [allZones, setAllZones] = useState<Zone[]>(previousZones);
  const [selectedZone, setSelectedZone] = useState<number>(-1);
  const [currenltySelectedPlaylist, setCurrentlySelectedPlaylist] = useState<string>('');

  const handleZoneClick = (index: number) => {
    let _selectedZone = -1;
    if (index === -1) {
      setCurrentlySelectedPlaylist('');
    } else if (index !== selectedZone) {
      _selectedZone = index;
    }
    setSelectedZone(_selectedZone);
  };

  const handleChangePlaylistSelect = (event: SelectChangeEvent) => {
    const newSelectedPlaylistIdIndex = event.target.value as string;
    const selectedPlaylists = newSelectedPlaylistIdIndex.split('_');
    const selectedPlaylistId = selectedPlaylists[0];
    const selectedPlaylistIndex = parseInt(selectedPlaylists[1]);
    const _allZones: Zone[] = [...allZones];
    // check if playlists already in that zone
    const playlistFound = _allZones[selectedZone].playlists.findIndex(
      ({ playlist }) => playlist.id == selectedPlaylistId
    );
    // playlist not found. need to add to the list of playlist for zone
    if (playlistFound == -1) {
      const playlistDetails = {
        updatedAt: new Date().toISOString(),
        playlist: {
          id: playlistsData[selectedPlaylistIndex].id,
          name: playlistsData[selectedPlaylistIndex].name,
          _count: {
            media: playlistsData[selectedPlaylistIndex]._count.media,
          },
        },
      };
      _allZones[selectedZone].updatedAt = new Date().toISOString();
      _allZones[selectedZone].playlists.push(playlistDetails);
      setAllZones(_allZones);
      setCurrentlySelectedPlaylist(newSelectedPlaylistIdIndex);
      updateParentZones(_allZones);
    }
  };

  const handleRemovePlaylistFromZone = (zoneIndex: number, playlistId: string) => {
    const _allZones = [...allZones];
    const tempZone = _allZones[zoneIndex];
    const filteredPlaylist = tempZone.playlists.filter(({ playlist }) => playlist.id !== playlistId);
    tempZone.playlists = filteredPlaylist;
    tempZone.updatedAt = new Date().toISOString();
    setAllZones(_allZones);
    updateParentZones(_allZones);
    setCurrentlySelectedPlaylist('');
  };

  // useEffect(() => {

  // }, [])

  return (
    <Box borderTop={`2px solid ${secondaryLightGray}`} pt={1}>
      <Typography textAlign={'center'} pb={1}>
        Presentation Zones
      </Typography>
      <Typography fontSize={11}>Output Resolution</Typography>
      <Typography fontSize={11} mb={1}>
        1920x1080
      </Typography>
      {/* The Box to select what zone to add too */}
      <Box
        style={{}}
        position={'relative'}
        sx={{ width: 240, height: 135 }}
        border={'1px solid teal'}
        bgcolor={secondaryLightGray}
      >
        {allZones.map((zone: Zone, index) => {
          return (
            <Box
              key={`zone-${index}`}
              onClick={() => handleZoneClick(index)}
              height={zone.height / factor}
              width={zone.width / factor}
              style={{ cursor: 'pointer' }}
              border={selectedZone == index ? '1px solid red' : 'none'}
            >
              <Typography
                position={'absolute'}
                top={'40%'}
                width={'100%'}
                textAlign={'center'}
                fontWeight={'bold'}
                color={selectedZone == index ? 'red' : '#000'}
              >
                {zone.name}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box>
        {selectedZone !== -1 ? (
          <Box mt={1} mb={1}>
            <Typography textAlign={'center'} fontSize={11} mb={1}>
              Please Select the playlist(s) for this zone
            </Typography>
            <FormControl fullWidth>
              <InputLabel id='playlist-select-label'>Playlist</InputLabel>
              <Select
                labelId='playlist-select-label'
                id='playlist-simple-select'
                value={currenltySelectedPlaylist}
                label='Playlist'
                onChange={handleChangePlaylistSelect}
              >
                {playlistsData.map((playlist: PlaylistData, index) => {
                  return (
                    <MenuItem key={`playlist-option-${index}`} value={`${playlist.id}_${index}`}>
                      {playlist.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Typography fontSize={11} mt={1} mb={1}>
              Current Playlists for this zone
            </Typography>
            {allZones[selectedZone].playlists.length > 0 ? (
              allZones[selectedZone].playlists.map(({ playlist }: ZoneSimplePlaylist) => {
                return (
                  <Grid container key={playlist.id}>
                    <Grid item xs={10}>
                      <Typography>{playlist.name}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Button onClick={() => handleRemovePlaylistFromZone(selectedZone, playlist.id)}>
                        <Delete htmlColor='red' />
                      </Button>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Typography>No Currently Selected Playlists</Typography>
            )}
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

export default ZoneCreation;
