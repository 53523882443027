import { Box, CardHeader } from '@mui/material';
import { JSXElementConstructor, ReactElement } from 'react';
import s from './styles.module.scss';
type Props = {
  text: string;
  Icon: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
  index: number;
};

const MultiHeader = (props: { options: Props[]; handleClick: (index: number) => void }) => {
  return (
    <Box
      display={'flex'}
      width={'100%'}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '.6rem .6rem 0 0',
        borderTop: '1px solid #d2d8e4',
        borderLeft: '1px solid #d2d8e4',
        borderRight: '1px solid #d2d8e4',
      }}
    >
      {props.options.map((header: Props) => {
        return (
          <CardHeader
            key={header.index}
            disableTypography
            className={s.container}
            sx={{ '&:hover': { background: '#1785c4', color: '#fff', svg: { fill: '#fff' } } }}
            title={
              <Box
                display='flex'
                alignItems='center'
                sx={{ cursor: 'pointer' }}
                onClick={() => props.handleClick(header.index)}
              >
                {header.text} {header.Icon}
              </Box>
            }
          />
        );
      })}
    </Box>
  );
};

export default MultiHeader;
