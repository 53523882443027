import { Dispatch, SetStateAction } from 'react';
import { useLaneContext } from '../../../context/LaneContext';
import { IColumn, IImageData } from '../../../context/types';

type Props = {
  column: IColumn;
  image: IImageData;
  screen: number;
  setDropdown: Dispatch<SetStateAction<boolean>>;
};

const Image = ({ image, setDropdown, screen, column }: Props) => {
  const { setColumns } = useLaneContext();

  const handleClick = () => {
    setDropdown(false);
    setColumns((prevState) => {
      const newState = [...prevState];
      newState[column.position].image = image.image;
      newState[column.position].imageId = image.id;
      return newState;
    });
  };

  const ImgMsg = ({ message }: { message: string }) => {
    return (
      <p
        style={{
          position: 'absolute',
          bottom: '20%',
          fontSize: 11,
          fontWeight: 'bold',
          width: '100%',
          textAlign: 'left',
          paddingLeft: 6,
          overflow: 'hidden',
          color: '#0D4D84',
        }}
      >
        {message}
      </p>
    );
  };

  return (
    <div onClick={() => handleClick()} style={{ aspectRatio: '57 / 25', padding: 0, position: 'relative' }}>
      <img src={image.image} alt='lane option' style={{ objectFit: 'cover', objectPosition: 'left' }} />
      {image.id === 3 ? screen === 1 ? <ImgMsg message={'North'} /> : <ImgMsg message={'South/Employees'} /> : ''}
    </div>
  );
};

export default Image;
