import { createContext, ReactNode, useContext, useState } from 'react';
import { useAuthContext } from '../../../context/AuthContext';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { decrypt } from '../../../utils/cryptojs-helper';
import ColumnsInitialValue from '../constants/columns-initial-value';
import IconsInitialValue from '../constants/icons-initial-value';
import { IIcon, ILaneContext } from './types';

const LaneContext = createContext({} as ILaneContext);

export const useLaneContext = () => useContext(LaneContext);

export const LaneContextProvider = ({ children }: { children: ReactNode }) => {
  const clonedColumns = JSON.parse(JSON.stringify(ColumnsInitialValue));
  const [columns, setColumns] = useState(clonedColumns);

  const clonedIcons = JSON.parse(JSON.stringify(IconsInitialValue));
  const [icons, setIcons] = useState<IIcon[]>(clonedIcons);

  const [screen, setScreen] = useLocalStorage('screen', 1);
  const [preview, setPreview] = useState('');
  // const { user } = useAuthContext();
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);

  const currentClient = user.currentClient.client.name.toLowerCase();

  // length of array determines how many images client has
  let amount;
  switch (currentClient) {
    case 'ewr':
      amount = Array(4).fill(undefined);
      break;
    default:
      amount = Array(1).fill(undefined);
  }
  // this will fetch the images from the digitalocean s3 bucket
  const clientImageData = amount.map((_, index) => {
    const id = index + 1;
    return {
      id,
      image:
        `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_ENV}/lane-management/${currentClient}/${id}.png?` +
        new Date().getTime(),
    };
  });
  // add custom image created from "custom message" component
  clientImageData.push({
    id: 1000,
    image:
      `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_ENV}/lane-management/${currentClient}/1000.png?` +
      new Date().getTime(),
  });

  const [imageData] = useState(clientImageData);

  return (
    <LaneContext.Provider
      value={{
        columns,
        setColumns,
        preview,
        setPreview,
        imageData,
        screen,
        setScreen,
        icons,
        setIcons,
      }}
    >
      {children}
    </LaneContext.Provider>
  );
};
