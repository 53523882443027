import { useQuery } from '@tanstack/react-query';
import { API_WEATHER } from '../../api/weather';
import Loader from '../../components/common/loader';
import WeatherTable from '../../components/weather-table';

const WeatherTablePage = () => {
  const { error, data } = useQuery(['GetAllWeatherTokens'], API_WEATHER.getAllTokens);

  if (error) return <p>error</p>;
  if (data) return <WeatherTable data={data} />;
  return <Loader />;
};

export default WeatherTablePage;
