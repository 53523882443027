import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  moveImage: (selectNumber: number) => Promise<void>;
};

const SaveImageModal = ({ isOpen, setIsOpen, moveImage }: Props) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Which Quick Select would you like to save the image to?</DialogTitle>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={() => moveImage(1)}>1</Button>
        <Button onClick={() => moveImage(2)}>2</Button>
        <Button onClick={() => moveImage(3)}>3</Button>
      </DialogActions>
      <Button color='error' onClick={() => setIsOpen(false)}>
        Cancel
      </Button>
    </Dialog>
  );
};

export default SaveImageModal;
