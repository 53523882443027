import { Box } from '@mui/material';
import { useEffect } from 'react';

const NotFoundPage = () => {
  useEffect(() => {
    document.title = '404 - Not Found';

    window.location.href = '/dashboard';
  }, []);
  return <Box>Not Found</Box>;
};

export default NotFoundPage;
