const IconsInitialValue = [
  { column: 1, imageId: 1 },
  { column: 2, imageId: 1 },
  { column: 3, imageId: 1 },
  { column: 4, imageId: 1 },
  { column: 5, imageId: 1 },
  { column: 6, imageId: 1 },
];

export default IconsInitialValue;
