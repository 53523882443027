import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { API_PLAYLIST } from '../../../../api/digital-signage-cms/playlist';
import { API_PRESENTATION } from '../../../../api/digital-signage-cms/presentation';
import { PresentationDetails, Zone } from '../../../../api/digital-signage-cms/types';
import { UserAuthData } from '../../../../api/user/types';
import ConfirmDialog from '../../../common/ConfirmDialog';
import Loader from '../../../common/loader/Loader';
import ZoneCreation from './ZoneCreation';

type Props = {
  user: UserAuthData;
  presentationDetails: PresentationDetails;
  handleClose: () => void;
};

const defaultZone: Zone = {
  createdAt: new Date().toISOString(),
  createdBy: '',
  height: 1080,
  id: '',
  left: 0,
  name: 'Default',
  playlists: [],
  presentationId: '',
  top: 0,
  type: '',
  updatedAt: new Date().toISOString(),
  user: {
    firstName: '',
    lastName: '',
  },
  width: 1920,
};

const resetError = { isError: false, message: '' };

const EditPresentation = ({ user, presentationDetails, handleClose }: Props) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [confirmationDialogInView, setConfirmationDialogInView] = useState<boolean>(false);
  const [masterZoneWithPlaylists, setMasterZoneWithPlaylists] = useState<Zone[]>(
    presentationDetails.zones ? presentationDetails.zones : [defaultZone]
  );
  const { data: playlistsData } = useQuery(['AllUserPlaylist'], () =>
    API_PLAYLIST.getAllPlaylist(user.currentClient.client.id)
  );
  const [nameError, setNameError] = useState<{ isError: boolean; message: string }>(resetError);
  const [descriptionError, setDescriptionError] = useState<{ isError: boolean; message: string }>(resetError);
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  const handleDialogClose = () => {
    if (currentStep !== 2) {
      setConfirmationDialogInView(true);
    }
  };

  const confirmationDialogClick = (res: boolean) => {
    if (res) {
      handleClose();
    } else {
      setConfirmationDialogInView(false);
    }
  };

  const handleReviewClick = () => {
    let errorCount = 0;
    if (nameRef.current && nameRef.current.value.length < 3) {
      errorCount++;
      setNameError({ isError: true, message: 'Must be more than 3 characters' });
    } else {
      setNameError(resetError);
    }
    if (descriptionRef.current && descriptionRef.current.value.length < 3) {
      errorCount++;
      setDescriptionError({ isError: true, message: 'Must be more than 3 characters' });
    } else {
      setDescriptionError(resetError);
    }
    if (errorCount === 0) setCurrentStep(1);
  };

  const handleEditClick = async () => {
    setCurrentStep(2);
    if (nameRef.current && descriptionRef.current) {
      const data = {
        id: presentationDetails.id,
        name: nameRef.current.value,
        description: descriptionRef.current.value,
        url: presentationDetails.url,
        createdAt: presentationDetails.createdAt,
        createdBy: presentationDetails.createdBy,
        height: presentationDetails.height,
        updatedAt: new Date().toISOString(),
        width: presentationDetails.width,
        zones: masterZoneWithPlaylists,
        user: {
          firstName: presentationDetails.user.firstName,
          lastName: presentationDetails.user.lastName,
        },
        _count: {
          zones: masterZoneWithPlaylists.length,
        },
      };
      editPresentationMutation.mutate({ editedPresentation: data, clientId: user.currentClient.client.id });
    }
  };

  // Query Code to update the local cache
  const editPresentationMutation = useMutation({
    mutationFn: ({ editedPresentation, clientId }: { editedPresentation: PresentationDetails; clientId: string }) =>
      API_PRESENTATION.edit(editedPresentation, clientId),
    // When mutate is called:
    onMutate: async ({ editedPresentation }: { editedPresentation: PresentationDetails; clientId: string }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['allClientPresentations'] });
      // Snapshot the previous value
      const previousPresentations = queryClient.getQueryData<PresentationDetails[]>(['allClientPresentations']);
      // Optimistically update to the new value
      if (previousPresentations) {
        const editedPresentationIndex = previousPresentations.findIndex((p) => p.id === editedPresentation.id);
        if (editedPresentationIndex > -1) {
          const _previousPresentations = [...previousPresentations];
          _previousPresentations[editedPresentationIndex] = editedPresentation;
          queryClient.setQueryData<PresentationDetails[]>(['allClientPresentations'], [..._previousPresentations]);
        }
      }
      // Return a context object with the snapshotted value
      return { previousPresentations };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      handleClose();
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newPresentation, context) => {
      setCurrentStep(1);
      if (context?.previousPresentations) {
        queryClient.setQueryData(['allClientPresentations'], context.previousPresentations);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['allClientPresentations'] });
    },
  });

  return (
    <>
      <Dialog open={true} onClose={handleDialogClose}>
        <DialogTitle textAlign={'center'}>
          {currentStep === 0 && 'Edit Presentation Details'}
          {currentStep === 1 && 'Review Presentation Details'}
          {currentStep === 2 && 'Saving Presentation Details'}
        </DialogTitle>
        <DialogContent style={{ paddingTop: 5 }}>
          {currentStep < 2 ? (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <TextField
                defaultValue={presentationDetails.name}
                error={nameError.isError}
                helperText={nameError.message}
                inputRef={nameRef}
                disabled={currentStep > 0 ? true : false}
                label={'Name'}
                fullWidth
              />
              <TextField
                defaultValue={presentationDetails.description}
                error={descriptionError.isError}
                helperText={descriptionError.message}
                inputRef={descriptionRef}
                disabled={currentStep > 0 ? true : false}
                label={'Description'}
                fullWidth
              />
              {currentStep == 0 ? (
                <ZoneCreation
                  playlistsData={playlistsData}
                  previousZones={masterZoneWithPlaylists}
                  updateParentZones={setMasterZoneWithPlaylists}
                />
              ) : (
                <Box
                  border={'1px solid rgba(133, 133, 133, 0.6)'}
                  borderRadius={1}
                  pt={1}
                  pl={1}
                  pr={1}
                  position={'relative'}
                >
                  <Typography
                    position={'absolute'}
                    top={-10}
                    left={15}
                    bgcolor={'#fff'}
                    fontSize={12}
                    color={'rgba(0, 0, 0, 0.38)'}
                  >
                    Zones
                  </Typography>
                  {masterZoneWithPlaylists.map((zone, index) => {
                    return (
                      <Stack key={`zone-playlist-${index}`}>
                        <Typography color={'rgba(0, 0, 0, 0.38)'} textTransform={'uppercase'}>
                          {zone.name}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Box>
              )}
            </Box>
          ) : (
            <Box height={150} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Loader />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {currentStep === 0 && (
            <Button style={{ backgroundColor: theme.palette.primary.main }} onClick={handleReviewClick}>
              <Typography color={'#fff'}>Review</Typography>
            </Button>
          )}
          {currentStep === 1 && (
            <>
              <Button onClick={() => setCurrentStep(0)}>
                <Typography>Go Back</Typography>
              </Button>
              <Button onClick={handleEditClick} style={{ backgroundColor: theme.palette.primary.main }}>
                <Typography color={'#fff'}>Submit</Typography>
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {confirmationDialogInView && (
        <ConfirmDialog
          title={'Are you sure you would like to close the form?'}
          message='All data filled out will be lost'
          handleClick={confirmationDialogClick}
        />
      )}
    </>
  );
};

export default EditPresentation;
