import { IMenu } from '../../../components/menu-management/menu/context/MenuFormsContext';
import axios from '../../../config/axiosV2';
import { Menu } from './types';

const getAllMenus = async () => {
  const url = '/menu-management/menu';
  try {
    const { data } = await axios.get(url);
    const { menus }: { menus: Menu[] } = data;
    return menus;
  } catch {
    return false;
  }
};

const createMenu = async (menuData: IMenu) => {
  const url = '/menu-management/menu';
  try {
    const { data } = await axios.post(url, menuData);
    const { menu }: { menu: Menu } = data;
    return menu;
  } catch (error) {
    throw Error('Failed to create menu. Please try again.');
  }
};

const editMenu = async (menuData: Menu) => {
  const url = `/menu-management/menu/${menuData.id}`;
  try {
    const { data } = await axios.put(url, menuData);
    const menus = data.menus;
    return menus;
  } catch {
    throw Error('Failed to update menu. Please try again.');
  }
};

const deleteMenu = async (menuId: string) => {
  const url = `/menu-management/menu/${menuId}`;
  try {
    await axios.delete(url);
    return true;
  } catch {
    throw Error('Failed to delete Menu');
  }
};

export { createMenu, deleteMenu, editMenu, getAllMenus };
