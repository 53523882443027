import { Dispatch, SetStateAction } from 'react';
import { IIcon } from '../../../context/types';

const changeIcon = (column: number, imageId: number, icons: IIcon[], setIcons: Dispatch<SetStateAction<IIcon[]>>) => {
  const iconClicked = icons.find((icon) => {
    return icon.column === column;
  });
  const iconsCopy = icons.slice(); // copy and .slice() makes a new reference of icons
  if (iconClicked) {
    switch (imageId) {
      case 1:
        iconsCopy[iconClicked?.column - 1].imageId = 2;
        break;
      case 2:
        iconsCopy[iconClicked?.column - 1].imageId = 3;
        break;
      default:
        iconsCopy[iconClicked?.column - 1].imageId = 1;
    }
  }
  setIcons(iconsCopy);
};

export default changeIcon;
