import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useState } from 'react';

type Props = {
  text: string;
};

const CopyText = ({ text }: Props) => {
  const theme = useTheme();
  const [toolTipInView, setToolTipInView] = useState<boolean>(false);

  const handleCopyButtonClick = () => {
    setToolTipInView(true);
    navigator.clipboard.writeText(text);
    setTimeout(() => setToolTipInView(false), 3000);
  };

  return (
    <Box
      width={{ xs: 200, md: 300, lg: 500 }}
      border={'2px solid #dedede'}
      borderRadius={1}
      bgcolor={'#dfdfdf'}
      position={'relative'}
    >
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Grid item xs={10} bgcolor={'#fff'}>
          <Typography p={1} noWrap overflow={'hidden'} textOverflow={'ellipsis'}>
            {text}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Box display={'flex'} justifyContent={'center'}>
            <Button onClick={handleCopyButtonClick} sx={{ minWidth: 'initial', padding: 0 }}>
              <ContentCopyIcon />
            </Button>
          </Box>
        </Grid>
      </Grid>
      {toolTipInView && (
        <Typography position={'absolute'} top={'-25px'} color={theme.palette.primary.main}>
          URL copied!
        </Typography>
      )}
    </Box>
  );
};

export default CopyText;
