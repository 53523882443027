import { useAuthContext } from '../../../../../context/AuthContext';
import { decrypt } from '../../../../../utils/cryptojs-helper';
import { useLaneContext } from '../../../context/LaneContext';
import { IIcon } from '../../../context/types';
import s from '../styles.module.scss';
import changeIcon from '../utils/change-icon';

type Props = {
  data: IIcon;
};

const Icon = ({ data }: Props) => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);
  const { icons, setIcons } = useLaneContext();
  const currentClient = user.currentClient.client.name.toLowerCase();

  const url = `${process.env.REACT_APP_API_PUBLIC_BASE_URL}/lane-management/${currentClient}/icons/${data.imageId}.png`;
  const path = `url('${url}')`;

  const styles = {
    backgroundImage: path,
  };

  return (
    <div
      className={s.colorGroupImage}
      style={styles}
      onClick={() => changeIcon(data.column, data.imageId, icons, setIcons)}
    />
  );
};

export default Icon;
