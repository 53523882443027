const DIMENSIONS_NUMBERS = [
  {
    width: 700,
    height: 100,
  },
  {
    width: 300,
    height: 500,
  },

  {
    width: 1500,
    height: 100,
  },
  {
    width: 300,
    height: 300,
  },
];

export default DIMENSIONS_NUMBERS;
