import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { API_PLAYLIST } from '../../../../api/digital-signage-cms/playlist';
import { API_PRESENTATION } from '../../../../api/digital-signage-cms/presentation';
import { PresentationDetails, Zone } from '../../../../api/digital-signage-cms/types';
import ConfirmDialog from '../../../common/ConfirmDialog';
import Loader from '../../../common/loader/Loader';
// import { Zone } from '../types';
import useLocalStorageState from 'use-local-storage-state';
import { UserAuthData } from '../../../../api/user/types';
import ZoneCreation from './ZoneCreation';

type Props = {
  user: UserAuthData;
  handleClose: () => void;
};

const resetError = { isError: false, message: '' };

const NewPresentationDialog = ({ user, handleClose }: Props) => {
  const defaultZone: Zone = {
    createdAt: new Date().toISOString(),
    createdBy: '',
    height: 1080,
    id: '',
    left: 0,
    name: 'Default',
    playlists: [],
    presentationId: '',
    top: 0,
    type: '',
    updatedAt: new Date().toISOString(),
    user: {
      firstName: '',
      lastName: '',
    },
    width: 1920,
  };

  const theme = useTheme();
  const queryClient = useQueryClient();
  const [tutorialStep, setTutorialStep] = useLocalStorageState<number>('tutorial-step');
  const [tutorialInView, setTutorialInView] = useState<boolean>(tutorialStep === 7 ? true : false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [confirmationDialogInView, setConfirmationDialogInView] = useState<boolean>(false);
  const [nameError, setNameError] = useState<{ isError: boolean; message: string }>(resetError);
  const [descriptionError, setDescriptionError] = useState<{ isError: boolean; message: string }>(resetError);
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  // fetching the playlist of a user
  const { data: playlistsData } = useQuery(['AllUserPlaylist'], () =>
    API_PLAYLIST.getAllPlaylist(user.currentClient.client.id)
  );
  // zone playlist
  const [masterZoneWithPlaylists, setMasterZoneWithPlaylists] = useState<Zone[]>([defaultZone]);

  const handleDialogClose = () => {
    if (currentStep !== 2) {
      setConfirmationDialogInView(true);
    }
  };

  const confirmationDialogClick = (res: boolean) => {
    if (res) {
      setMasterZoneWithPlaylists([defaultZone]);
      handleClose();
    } else {
      setConfirmationDialogInView(false);
    }
  };

  const handleReviewClick = () => {
    if (tutorialInView) {
      setTutorialInView(false);
    } else {
      let errorCount = 0;
      if (nameRef.current && nameRef.current.value.length < 3) {
        errorCount++;
        setNameError({ isError: true, message: 'Must be more than 3 characters' });
      } else {
        setNameError(resetError);
      }
      if (descriptionRef.current && descriptionRef.current.value.length < 3) {
        errorCount++;
        setDescriptionError({ isError: true, message: 'Must be more than 3 characters' });
      } else {
        setDescriptionError(resetError);
      }
      if (errorCount === 0) setCurrentStep(1);
    }
  };

  const handleCreateClick = async () => {
    setCurrentStep(2);
    if (nameRef.current && descriptionRef.current) {
      const data = {
        id: '',
        name: nameRef.current.value,
        description: descriptionRef.current.value,
        url: '',
        createdAt: new Date().toISOString(),
        createdBy: '',
        height: 1080,
        updatedAt: new Date().toISOString(),
        width: 1920,
        zones: masterZoneWithPlaylists,
        user: {
          firstName: '',
          lastName: '',
        },
        _count: {
          zones: masterZoneWithPlaylists.length,
        },
      };
      addNewPresentationMutation.mutate({ newPresentation: data, clientId: user.currentClient.client.id });
    }
  };

  // Query Code to update the local cache
  const addNewPresentationMutation = useMutation({
    mutationFn: ({ newPresentation, clientId }: { newPresentation: PresentationDetails; clientId: string }) =>
      API_PRESENTATION.create(newPresentation, clientId),
    // When mutate is called:
    onMutate: async ({ newPresentation }: { newPresentation: PresentationDetails; clientId: string }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['allClientPresentations'] });
      // Snapshot the previous value
      const previousPresentations = queryClient.getQueryData<PresentationDetails[]>(['allClientPresentations']);
      // Optimistically update to the new value
      if (previousPresentations) {
        queryClient.setQueryData<PresentationDetails[]>(
          ['allClientPresentations'],
          [...previousPresentations, newPresentation]
        );
      }
      // Return a context object with the snapshotted value
      return { previousPresentations };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      if (context && context.previousPresentations) {
        const newPresentation: PresentationDetails = { ...data };
        setMasterZoneWithPlaylists([defaultZone]);
        // Replace optimistic todo in the todos list with the result
        queryClient.setQueryData<PresentationDetails[]>(
          ['allClientPresentations'],
          [...context.previousPresentations, newPresentation]
        );
      }
      if (tutorialStep === 7) {
        setTutorialStep(8);
      }
      handleClose();
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newPresentation, context) => {
      console.log('ERROR');
      console.log(err);
      setCurrentStep(1);
      if (context?.previousPresentations) {
        queryClient.setQueryData(['allClientPresentations'], context.previousPresentations);
      }
    },
    // Always refetch after error or success:
    // onSettled: () => {
    //   console.log('onSettled')
    //   queryClient.invalidateQueries({ queryKey: ['allClientPresentations'] });
    // },
  });
  return (
    <>
      <Dialog open={true} onClose={handleDialogClose}>
        <DialogTitle textAlign={'center'}>
          {currentStep === 0 && 'New Presentation Details'}
          {currentStep === 1 && 'Review Presentation Details'}
          {currentStep === 2 && 'Saving Presentation Details'}
        </DialogTitle>
        <DialogContent style={{ paddingTop: 5 }}>
          {currentStep < 2 ? (
            <Box display={'flex'} flexDirection={'column'} gap={2} position={'relative'}>
              {/* TUTORIAL OVERLAY */}
              {tutorialInView && (
                <Box
                  position={'absolute'}
                  zIndex={2}
                  display={'flex'}
                  p={1}
                  borderRadius={2}
                  flexDirection={'column'}
                  gap={1}
                  height={'100%'}
                  width={'100%'}
                  bgcolor={'#000000'}
                >
                  <Box border={'2px solid #fff'} borderRadius={2}>
                    <Typography p={2} color={'#fff'}>
                      Presentation Name
                    </Typography>
                  </Box>
                  <Box border={'2px solid #fff'} borderRadius={2}>
                    <Typography p={2} color={'#fff'}>
                      Description
                    </Typography>
                  </Box>
                  <Box borderTop={'2px solid #fff'} p={1} textAlign={'center'}>
                    <Typography color={'#fff'}>These are the zones in a presentation.</Typography>
                    <Typography color={'#fff'} mt={3} pt={3} border={'1px solid #fff'} height={'80%'}>
                      Click the Zone box to connect a playlist(s) to that zone!
                    </Typography>
                  </Box>
                </Box>
              )}
              {/* FORMS */}
              <TextField
                error={nameError.isError}
                helperText={nameError.message}
                inputRef={nameRef}
                disabled={currentStep > 0 ? true : false}
                label={'Name'}
                fullWidth
              />
              <TextField
                error={descriptionError.isError}
                helperText={descriptionError.message}
                inputRef={descriptionRef}
                disabled={currentStep > 0 ? true : false}
                label={'Description'}
                fullWidth
              />
              {currentStep == 0 ? (
                <ZoneCreation
                  playlistsData={playlistsData}
                  previousZones={masterZoneWithPlaylists}
                  updateParentZones={setMasterZoneWithPlaylists}
                />
              ) : (
                <Box
                  border={'1px solid rgba(133, 133, 133, 0.6)'}
                  borderRadius={1}
                  pt={1}
                  pl={1}
                  pr={1}
                  position={'relative'}
                >
                  <Typography
                    position={'absolute'}
                    top={-10}
                    left={15}
                    bgcolor={'#fff'}
                    fontSize={12}
                    color={'rgba(0, 0, 0, 0.38)'}
                  >
                    Zones
                  </Typography>
                  {masterZoneWithPlaylists.map((zone, index) => {
                    return (
                      <Stack key={`zone-playlist-${index}`}>
                        <Typography color={'rgba(0, 0, 0, 0.38)'} textTransform={'uppercase'}>
                          {zone.name}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Box>
              )}
            </Box>
          ) : (
            <Box height={150} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Loader />
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          {currentStep === 0 && (
            <Button style={{ backgroundColor: theme.palette.primary.main }} onClick={handleReviewClick}>
              <Typography color={'#fff'}>{tutorialInView ? 'Got it!' : 'Review'}</Typography>
            </Button>
          )}
          {currentStep === 1 && (
            <>
              <Button onClick={() => setCurrentStep(0)}>
                <Typography>Edit</Typography>
              </Button>
              <Button onClick={handleCreateClick} style={{ backgroundColor: theme.palette.primary.main }}>
                <Typography color={'#fff'}>Create</Typography>
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {confirmationDialogInView && (
        <ConfirmDialog
          title={'Are you sure you would like to close the form?'}
          message='All data filled out will be lost'
          handleClick={confirmationDialogClick}
        />
      )}
    </>
  );
};

export default NewPresentationDialog;
