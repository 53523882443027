import { IColumn } from '../../../context/types';

const checkColumnsNull = (columns: IColumn[]) => {
  const doesHaveNullValue = columns.some((column) => {
    return column.imageId === null && column.merged === false; // column is empty
  });
  if (doesHaveNullValue) {
    return true;
  } else {
    return false;
  }
};

export default checkColumnsNull;
