import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import RoleRow from './components/RoleRow';

import s from './styles.module.scss';
import { RoleTableData } from './types';

const RowsTable = ({ data }: { data: RoleTableData[] }) => {
  return (
    <TableContainer className={s.container}>
      <MUITable>
        <TableHead className={s.header}>
          <TableRow>
            <TableCell className={s.cell}>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <RoleRow key={`role-row${index}`} data={row} />
          ))}
        </TableBody>
      </MUITable>
    </TableContainer>
  );
};

export default RowsTable;
