import axios from '../../config/axiosV2';
import { SiteLocation } from '../whiteboard/map/site/types';
import { NewPlayer } from './types';

const getAllPlayers = async () => {
  const url = '/player-management/all';
  try {
    const { data } = await axios.get(url);
    const { players } = data;
    return players;
  } catch {
    return false;
  }
};

const createPlayer = async (newPlayerData: NewPlayer) => {
  const url = '/player-management';
  try {
    const { data } = await axios.post(url, newPlayerData);
    const { player } = data;
    return player;
  } catch {
    throw Error;
  }
};

const getAllClientPlayers = async (clientId: string) => {
  const url = `/player-management/${clientId}`;
  try {
    const { data } = await axios.get(url);
    const { players } = data;
    return players;
  } catch {
    return false;
  }
};

const connectPlayersToSiteLocation = async (
  players: { id: string; name: string; siteLocation: { id: string; name: string } }[],
  siteLocation: SiteLocation
) => {
  const url = `/player-management/connect-to-site-location/${siteLocation.id}`;
  try {
    await axios.post(url, { players: players });
    return true;
  } catch {
    return false;
  }
};

export { connectPlayersToSiteLocation, createPlayer, getAllClientPlayers, getAllPlayers };
