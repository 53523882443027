import LoginIcon from '@mui/icons-material/Login';
import { Button, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_USER } from '../../api/user';
import BaseInput from '../../components/common/BaseInput';
import { emailRegex } from '../../constants';
import { useAuthContext } from '../../context/AuthContext';
import { decrypt } from '../../utils/cryptojs-helper';
import s from './loginPage.module.scss';

const LoginPage = () => {
  useEffect(() => {
    if (user !== '') navigate('/dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { userNew, setUserNew } = useAuthContext();

  const decrypted = decrypt(userNew);
  let user = '';
  if (decrypted) user = JSON.parse(decrypted);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!formData.email.toLowerCase().match(emailRegex)) return setErrors({ ...errors, email: true });
    if (formData.password.length < 8) return setErrors({ ...errors, password: true });

    const success = await API_USER.login(formData, setUserNew, setLoading);

    success ? navigate('/dashboard') : '';
  };

  return (
    <main className={s.container}>
      <div className={s.content}>
        <Card>
          <img src='/logos/logo.png' alt='Curio Logo' />
          <form onSubmit={handleSubmit}>
            <BaseInput
              label='Email'
              name='email'
              onChange={handleChange}
              error={errors.email}
              helperText={errors.email && 'Please enter a valid email'}
              onFocus={() => setErrors({ ...errors, email: false })}
            />
            <BaseInput
              label='Password'
              name='password'
              type='password'
              onChange={handleChange}
              error={errors.password}
              helperText={errors.password && 'Password must be longer than 8 characters'}
              onFocus={() => setErrors({ ...errors, password: false })}
            />
            <Button
              disabled={!formData.email || !formData.password || loading}
              variant='contained'
              type='submit'
              endIcon={<LoginIcon />}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              Login
            </Button>
          </form>
        </Card>
      </div>
    </main>
  );
};

export default LoginPage;
