import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { API_MENU_MANAGEMENT_LOCATION } from '../../../api/menu-management/location';
import { API_MENU_MANAGEMENT_MENU } from '../../../api/menu-management/menu';
import { API_PLAYER_MANAGEMENT } from '../../../api/player-management';
import Loader from '../../../components/common/loader/Loader';
import Menus from '../../../components/menu-management/menu/Menus';
import { useAuthContext } from '../../../context/AuthContext';
import { decrypt } from '../../../utils/cryptojs-helper';

const MenuPage = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);
  // fetching the user locations
  const { error: locationError, data: locationData } = useQuery(
    ['allClientLocations'],
    API_MENU_MANAGEMENT_LOCATION.getAllLocations
  );
  const { error: menuError, data: menuData } = useQuery(['allClientMenus'], API_MENU_MANAGEMENT_MENU.getAllMenus);
  const { error: playersError, data: allPlayersData } = useQuery(['AllClientPlayers'], () =>
    API_PLAYER_MANAGEMENT.getAllClientPlayers(user.currentClient.client.id)
  );

  if (locationError || menuError || playersError) {
    return <Typography>Error Loading location or menu data. Please try again.</Typography>;
  }
  if (locationData && menuData && allPlayersData)
    return <Menus locations={locationData} menus={menuData} players={allPlayersData} />;
  return <Loader />;
};

export default MenuPage;
