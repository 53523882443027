// the string value should be changed to the codes
export enum Roles {
  ADMIN = 4000,
  USERS = 2000,
  LANE_MANAGEMENT = 5001,
  WEATHER_WIDGET = 5002,
  DIGITAL_SIGNAGE_CMS = 5003,
  CONTENT_CREATION = 5004,
  MENU_CMS = 5005,
  MAP_WHITEBOARD_CMS = 5006,
}

export type Role = {
  code: number;
};

export interface IClient {
  client: { id: string; name: string; roles: number[]; permissions: string[] };
}

export type ClientNames = {
  id: string;
  name: string;
};
