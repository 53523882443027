import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { MediaInPlaylist } from '../../../../api/digital-signage-cms/types';

type Props = {
  index: number;
  includeValidityDate: boolean;
  mediaDetails: MediaInPlaylist;
  addValidityDates: (index: number, hasDates: boolean) => void;
  handleMediaDurationChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => void;
};

const EditMediaSelectedCard = ({
  index,
  includeValidityDate,
  mediaDetails,
  addValidityDates,
  handleMediaDurationChange,
}: Props) => {
  const theme = useTheme();
  const lightGray = theme.palette.info.light;
  const secondaryLightGray = theme.palette.secondary.light;
  const [validityCheckbox, setValidityCheckbox] = useState<boolean>(false);
  const { duration, validityEndDate, validityStartDate } = mediaDetails;
  const { name, previewBucketURL } = mediaDetails.media ? mediaDetails.media : { name: '', previewBucketURL: '' };

  const handleMediaValidityCheck = () => {
    const _validityCheckbox = !validityCheckbox;
    addValidityDates(index, _validityCheckbox);
    setValidityCheckbox(_validityCheckbox);
  };
  // if validityDate is removed we need to uncheck the Checkbox
  useEffect(() => {
    if (!includeValidityDate) {
      setValidityCheckbox(false);
    }
  }, [includeValidityDate]);

  useEffect(() => {
    if (mediaDetails.validityEndDate || mediaDetails.validityStartDate) {
      setValidityCheckbox(true);
    } else {
      setValidityCheckbox(false);
    }
  }, [mediaDetails.validityEndDate, mediaDetails.validityStartDate]);

  const formatDate = (d: string | Date) => {
    let formattedDate = '';
    if (typeof d === 'string') {
      const s = d.split('T');
      formattedDate = s[0];
    } else {
      formattedDate = d.toDateString();
    }
    return formattedDate;
  };

  return (
    <Stack
      border={`2px solid ${secondaryLightGray}`}
      alignItems={'center'}
      justifyContent={'center'}
      borderRadius={2}
      spacing={2}
      pb={2}
    >
      <Grid
        container
        height={{ xs: '12vw', md: '2vw' }}
        alignItems={'center'}
        bgcolor={secondaryLightGray}
        p={1}
        borderRadius={'4px 4px 0 0'}
      >
        <Grid item xs={1}>
          <Typography textAlign={'center'} color={'#000'} bgcolor={'#fff'} borderRadius={'50%'}>
            {index + 1}
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography textAlign={'center'} fontWeight={'bold'}>
            {name}
          </Typography>
        </Grid>
      </Grid>
      <Box border={`2px solid ${secondaryLightGray}`}>
        <img src={previewBucketURL} alt={name} />
      </Box>
      <Grid container alignItems={'center'} gap={1} pl={1} pr={1} borderTop={`2px solid ${secondaryLightGray}`} pt={1}>
        <Grid item xs={5}>
          <Typography>Duration: </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            style={{ padding: 0, height: '100%' }}
            type={'number'}
            value={duration}
            onChange={(event) => handleMediaDurationChange(event, index)}
          />
        </Grid>
      </Grid>
      {includeValidityDate && (
        <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormControlLabel
            control={<Checkbox checked={validityCheckbox} onChange={handleMediaValidityCheck} />}
            label='Validity Date'
          />
        </FormGroup>
      )}
      {validityStartDate || validityEndDate ? (
        <Grid container pl={1} pr={1}>
          {validityStartDate && (
            <Grid item xs={6}>
              <Typography color={lightGray} fontSize={12} textAlign={'center'}>
                Start Date
              </Typography>
              <Typography textAlign={'center'}>{formatDate(validityStartDate)}</Typography>
            </Grid>
          )}
          {validityEndDate && (
            <Grid item xs={6}>
              <Typography color={lightGray} fontSize={12} textAlign={'center'}>
                End Date
              </Typography>
              <Typography textAlign={'center'}>{formatDate(validityEndDate)}</Typography>
            </Grid>
          )}
        </Grid>
      ) : (
        ''
      )}
    </Stack>
  );
};

export default EditMediaSelectedCard;
