import { useQuery } from '@tanstack/react-query';
import { API_CLIENT } from '../../api/client';
import Loader from '../../components/common/loader';
import NewAccessToken from '../../components/new-access-token';
import { useAuthContext } from '../../context/AuthContext';
import { Roles } from '../../types';
import { decrypt } from '../../utils/cryptojs-helper';

const NewAccessTokenPage = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);

  let url = `client/one/${user.currentClient.client.id}`; // url to get one client
  if (user.currentClient.client.roles.includes(Roles.ADMIN)) {
    url = '/client'; // url to get all clients
  }

  const { error, data } = useQuery(['GetAllClients', url], API_CLIENT.getAll);

  if (error) return <p>error</p>;
  if (data) return <NewAccessToken loggedUser={user} clientData={data} />;
  return <Loader />;
};

export default NewAccessTokenPage;
