import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { decrypt } from '../../utils/cryptojs-helper';
import CustomAppBar from './components/CustomAppBar';
import CustomSideBar from './components/CustomSidebar';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Layout = () => {
  const { userNew } = useAuthContext();

  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <CustomAppBar open={open} setOpen={setOpen} />
      <CustomSideBar open={open} setOpen={setOpen} roles={user.currentClient.client.roles} loggedUser={user} />
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
