import ArticleIcon from '@mui/icons-material/Article';
import BusinessIcon from '@mui/icons-material/Business';
import CameraIcon from '@mui/icons-material/Camera';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import FlightIcon from '@mui/icons-material/Flight';
import FolderIcon from '@mui/icons-material/Folder';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PeopleIcon from '@mui/icons-material/People';
import RadioIcon from '@mui/icons-material/Radio';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import TableViewIcon from '@mui/icons-material/TableView';
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import TokenIcon from '@mui/icons-material/Token';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import { Roles } from '../types';

const LINK_ITEMS = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon />,
    link: '/dashboard',
    roles: [
      Roles.ADMIN,
      Roles.USERS,
      Roles.LANE_MANAGEMENT,
      Roles.WEATHER_WIDGET,
      Roles.DIGITAL_SIGNAGE_CMS,
      Roles.MENU_CMS,
      Roles.MAP_WHITEBOARD_CMS,
    ],
    permissions: [],
  },
  {
    title: 'Player Management',
    icon: <RadioIcon />,
    link: '/player-management',
    roles: [Roles.ADMIN],
  },
  {
    title: 'Logging',
    icon: <ArticleIcon />,
    link: '/logging',
    roles: [Roles.ADMIN],
  },
  {
    title: 'Lane Management',
    icon: <FlightIcon />,
    link: '/lane-management',
    roles: [Roles.ADMIN, Roles.LANE_MANAGEMENT],
  },
  {
    title: 'Users',
    icon: <PeopleIcon />,
    link: '/users',
    roles: [Roles.USERS, Roles.ADMIN],
    permissions: ['users-view'],
  },
  {
    title: 'Clients',
    icon: <BusinessIcon />,
    link: '/clients',
    roles: [Roles.ADMIN],
  },
  {
    title: 'Weather',
    icon: <ThunderstormIcon />,
    link: '/weather-widget',
    roles: [Roles.ADMIN, Roles.WEATHER_WIDGET],
  },
  {
    title: 'Weather Table',
    icon: <TableViewIcon />,
    link: '/weather-table',
    roles: [Roles.ADMIN],
  },
  {
    title: 'Access Tokens',
    icon: <TokenIcon />,
    link: '/access-tokens',
    roles: [Roles.ADMIN],
  },
  {
    title: 'Curio Slides',
    icon: <CameraIcon />,
    link: '/content-creation',
    roles: [Roles.ADMIN, Roles.DIGITAL_SIGNAGE_CMS, Roles.CONTENT_CREATION],
    permissions: ['content-creation-view'],
  },
  {
    title: 'Media',
    icon: <FolderIcon />,
    link: '/digital-signage/media',
    roles: [Roles.ADMIN, Roles.DIGITAL_SIGNAGE_CMS, Roles.CONTENT_CREATION],
    permissions: ['media-view'],
  },
  {
    title: 'Playlists',
    icon: <FeaturedPlayListIcon />,
    link: '/digital-signage/playlist',
    roles: [Roles.ADMIN, Roles.DIGITAL_SIGNAGE_CMS, Roles.CONTENT_CREATION],
    permissions: ['playlist-view'],
  },
  {
    title: 'Presentations',
    icon: <SmartDisplayIcon />,
    link: '/digital-signage/presentations',
    roles: [Roles.ADMIN, Roles.DIGITAL_SIGNAGE_CMS],
    permissions: ['presentation-view'],
  },
  // {
  //   title: 'Locations',
  //   icon: <StoreIcon />,
  //   link: '/locations',
  //   roles: [Roles.ADMIN, Roles.MENU_CMS],
  // },
  {
    title: 'Menus',
    icon: <MenuBookIcon />,
    link: '/menus',
    roles: [Roles.ADMIN, Roles.MENU_CMS],
  },
  {
    title: 'Map WB',
    icon: <WebAssetIcon />,
    link: '/map-whiteboard',
    roles: [Roles.ADMIN, Roles.MAP_WHITEBOARD_CMS],
  },
];

export default LINK_ITEMS;
