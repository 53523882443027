import { Box, Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { Link } from 'react-router-dom';
import { IUser } from '../../context/AuthContext';
import Row from './components/Row';
import s from './styles.module.scss';
import { TableData, UserGetData } from './types';
import createData from './utils/create-data';

const UsersTable = ({ data, loggedUser }: { data: UserGetData[]; loggedUser: IUser }) => {
  const rows: TableData[] = [];

  data.forEach((item: UserGetData) => {
    if (item.id === loggedUser.id) {
      return; // if the user id matches user logged in do not push
    }
    rows.push(createData(item.id, `${item.firstName} ${item.lastName}`, item.email, item.blacklisted));
  });
  return (
    <TableContainer className={s.container}>
      <MUITable>
        <TableHead className={s.header}>
          <TableRow>
            <TableCell className={s.cell}>Name</TableCell>
            <TableCell className={s.cell}>Email</TableCell>
            <TableCell className={s.cell}>Access</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.id} data={row} />
          ))}
        </TableBody>
      </MUITable>
      {loggedUser.permissions[loggedUser.currentClient.client.name].includes('users-create') ? (
        <Box className={s.bottom}>
          <Link to='/users/add' style={{ textDecoration: 'none' }}>
            <Button variant='contained' sx={{ alignSelf: 'flex-start' }}>
              Add User
            </Button>
          </Link>
        </Box>
      ) : null}
    </TableContainer>
  );
};

export default UsersTable;
