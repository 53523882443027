import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { Card } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../../../context/AuthContext';
import { decrypt } from '../../../../utils/cryptojs-helper';
import Header from '../../../common/header';
import { API_LANE_MANAGEMENT } from '../../api';
import { useLaneContext } from '../../context/LaneContext';
import s from './styles.module.scss';

const QuickSelect = () => {
  const { screen, setColumns, setIcons } = useLaneContext();
  // const { user } = useAuthContext();
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);
  const currentClient = user.currentClient.client.name.toLowerCase();

  const { data } = useQuery(['GetQuickSelect', { client: currentClient, screen }], API_LANE_MANAGEMENT.getQuickSelect);

  const handleClick = (number: number) => {
    const index = number - 1;
    if (data) {
      setColumns(data[index][0].columns);
      setIcons(data[index][1].icons);
    }
  };
  return (
    <Card className={s.container}>
      <Header text='Quick Select' Icon={<HighlightAltIcon />} />
      <img
        src={
          `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_ENV}/lane-management/${currentClient}/${screen}/select/1/image.png?` +
          new Date().getTime()
        } // cache breaker
        alt='Quick Select Option One'
        onClick={() => handleClick(1)}
      />
      <img
        src={
          `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_ENV}/lane-management/${currentClient}/${screen}/select/2/image.png?` +
          new Date().getTime()
        }
        alt='Quick Select Option Two'
        onClick={() => handleClick(2)}
      />
      <img
        src={
          `${process.env.REACT_APP_BUCKET_URL}/${process.env.REACT_APP_BUCKET_ENV}/lane-management/${currentClient}/${screen}/select/3/image.png?` +
          new Date().getTime()
        }
        alt='Quick Select Option Three'
        onClick={() => handleClick(3)}
      />
    </Card>
  );
};

export default QuickSelect;
