import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { decrypt } from './cryptojs-helper';

const ProtectedRouteRolesPermissions = ({
  allowedRoles,
  allowedPermissions,
}: {
  allowedRoles: number[];
  allowedPermissions: string[];
}) => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const location = useLocation();
  if (decrypted) {
    const user = JSON.parse(decrypted);
    // first check the Roles of the current client being viewed
    let hasAccess = user?.currentClient?.client?.roles?.some((role: number) => allowedRoles?.includes(role));
    // second check the permissions
    // hasAccess = user?.currentClient?.client?.permissions?.some((permission: string) => allowedPermissions?.includes(permission))
    hasAccess = user.permissions[user?.currentClient?.client?.name].includes(allowedPermissions[0]);
    // iterating through the roles of a current users
    return hasAccess ? (
      <Outlet />
    ) : user?.currentClient ? (
      <Navigate to='/dashboard' state={{ from: location }} replace />
    ) : (
      <Navigate to='/login' state={{ from: location }} replace />
    );
  } else {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }
};

export default ProtectedRouteRolesPermissions;
