import { Stack, TextField } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { IMenu, useMenuContext } from '../../context/MenuFormsContext';

const MenuForm = () => {
  const { forms, setForms } = useMenuContext();
  const [menuFormData, setMenuFormData] = useState<IMenu>({
    name: '',
    description: '',
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const _menuFormData: IMenu = { ...menuFormData };
    _menuFormData.description = inputValue;

    setMenuFormData(_menuFormData);
    const contextForms = { ...forms };
    contextForms.menu = _menuFormData;
    setForms(contextForms);
  };

  return (
    <Stack mt={1}>
      <TextField placeholder='Description' multiline minRows={3} maxRows={6} onChange={handleInputChange} />
    </Stack>
  );
};

export default MenuForm;
