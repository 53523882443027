import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LINK_ITEMS from '../../../data/link-items';
import { Roles } from '../../../types';
import s from '../styles.module.scss';
import Item from './Item';

const drawerWidth = 220;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

type Props = {
  open: boolean;
  // eslint-disable-next-line
  loggedUser: any;
  setOpen: (open: boolean) => void;
  roles: Roles[];
};

const CustomSideBar = ({ open, loggedUser, setOpen, roles }: Props) => {
  const theme = useTheme();
  const { pathname: currentLink } = useLocation();
  // filter what nav items to show based on roles
  // const [items] = useState(LINK_ITEMS.filter((item) => roles.some((role) => item.roles.includes(role))));
  const [items, setItems] = useState(
    LINK_ITEMS.filter((item) => {
      if (roles.some((role) => item.roles.includes(role))) {
        if (item.permissions && item.permissions.length > 0) {
          if (
            loggedUser.permissions[loggedUser.currentClient.client.name] &&
            loggedUser.permissions[loggedUser.currentClient.client.name].includes(item.permissions[0])
          ) {
            return item;
          }
        } else {
          return item;
        }
      }
    })
  );

  useEffect(() => {
    const _items = LINK_ITEMS.filter((item) => {
      if (roles.some((role) => item.roles.includes(role))) {
        if (item.permissions && item.permissions.length > 0) {
          if (
            loggedUser.permissions[loggedUser.currentClient.client.name] &&
            loggedUser.permissions[loggedUser.currentClient.client.name].includes(item.permissions[0])
          ) {
            return item;
          }
        } else {
          return item;
        }
      }
    });
    setItems(_items);
  }, [loggedUser, roles]);

  return (
    <Drawer variant='permanent' open={open} style={{ display: 'inherit' }}>
      <DrawerHeader
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        {open && <img src='/logos/logo-flat.png' className={s.logoBig} alt='logo' />}
        {!open && <img src='/logos/logo-small.png' className={s.logoSmall} alt='small logo' />}
        <IconButton onClick={() => setOpen(false)} sx={{ display: open ? 'inherit' : 'none' }}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      {/* <Box sx={{position:' absolute', zIndex: 2, height: '90%', width: '100%', backgroundColor: '#0000008c', top: '5%', left: 0}}></Box> */}
      <List className={s.list}>
        {items.map((item) => (
          <Item key={item.title} item={item} open={open} currentLink={currentLink} />
        ))}
      </List>
    </Drawer>
  );
};

export default CustomSideBar;
