import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { API_CLIENT } from '../../api/client';
import { API_USER } from '../../api/user';
import Loader from '../../components/common/loader';
import SingleUser from '../../components/single-user';
import { useAuthContext } from '../../context/AuthContext';
import { Roles } from '../../types';
import { decrypt } from '../../utils/cryptojs-helper';

const SingleUserPage = () => {
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);

  const { error, data, refetch } = useQuery(['GetSingleUser', id], API_USER.getSingle);

  let url = '/client/all-client-clients';
  if (user.currentClient.client.roles.includes(Roles.ADMIN)) {
    url = '/client'; // url to get all clients
  }
  // Depending on the user permissions we might need
  const { error: clientError, data: clientsData } = useQuery(['GetAllClients', url], API_CLIENT.getAll);

  if (error || clientError) return <p>error</p>;
  if (data && clientsData) return <SingleUser data={data} id={id} refetch={refetch} allClients={clientsData} />;
  return <Loader />;
};

export default SingleUserPage;
