import LockIcon from '@mui/icons-material/Lock';
import { Box, Button, Card } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_USER } from '../../api/user';
import BaseInput from '../../components/common/BaseInput';
import Header from '../../components/common/header';
import { useAuthContext } from '../../context/AuthContext';
import { decrypt } from '../../utils/cryptojs-helper';

const UpdatePasswordPage = () => {
  const navigate = useNavigate();

  // const { user } = useAuthContext();
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);

  const [data, setData] = useState({
    password: '',
    passwordCheck: '',
  });

  const [errors, setErrors] = useState({
    password: false,
    passwordCheck: false,
  });

  const submitData = async () => {
    const { password, passwordCheck } = data;

    if (password.length < 8) return setErrors({ ...errors, password: true });
    if (password !== passwordCheck) return setErrors({ ...errors, passwordCheck: true });

    const dataToSend = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      password,
    };

    const success = await API_USER.updatePassword(user.id, dataToSend);
    success ? navigate('/logout') : '';
  };

  return (
    <Box maxWidth={1400} marginX='auto'>
      <Card sx={{ marginTop: 2 }}>
        <Header text='Update Password' Icon={<LockIcon />} />
        <BaseInput
          label='New Password'
          type='password'
          onChange={(e) => setData({ ...data, password: e.target.value })}
          error={errors.password}
          helperText={errors.password && 'Password must be longer than 8 characters'}
          onFocus={() => setErrors({ ...errors, password: false })}
        />
        <BaseInput
          label='Confirm New Password'
          type='password'
          onChange={(e) => setData({ ...data, passwordCheck: e.target.value })}
          error={errors.passwordCheck}
          helperText={errors.passwordCheck && 'Passwords do not match'}
          onFocus={() => setErrors({ ...errors, passwordCheck: false })}
        />
      </Card>
      <Button
        disabled={!data.password || !data.passwordCheck}
        variant='contained'
        size='small'
        sx={{ mt: 2, alignSelf: 'flex-start' }}
        onClick={() => submitData()}
      >
        Update
      </Button>
    </Box>
  );
};

export default UpdatePasswordPage;
