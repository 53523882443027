import { Box, Button } from '@mui/material';
import s from '../styles.module.scss';

type Props = {
  preview: string;
  publishImage: () => Promise<void>;
};

const Preview = ({ preview, publishImage }: Props) => {
  return (
    <Box>
      <img src={preview} alt='Preview' className={s.previewImage} />
      <Button
        variant='contained'
        color='primary'
        sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}
        onClick={() => publishImage()}
      >
        Publish
      </Button>
    </Box>
  );
};

export default Preview;
