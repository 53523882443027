import { List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { deleteLabelForLocation } from '../../../../api/whiteboard/map/location/api';
import { LocationData, WBLabel } from '../../../../api/whiteboard/map/location/types';
import { SiteLocation } from '../../../../api/whiteboard/map/site/types';
import textSizing from '../../textSizing';
import HoverIcons from '../commmon/HoverIcons';
import FormDialog from '../forms/FormDialog';

type Props = {
  location: LocationData;
  labels: WBLabel[];
  siteLocation: SiteLocation | null;
};
const LabelsSection = ({ labels, location, siteLocation }: Props) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const mainBlue = theme.palette.primary.main;
  const [editFormInView, setEditFormInView] = useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<WBLabel | null>(null);
  // Label functions . Will move to own component
  const handleLabelDeleteButtonClick = async (labelId: string) => {
    try {
      await deleteLabelForLocation(location.location.id, labelId);
      // making a copy and removing that media
      const _locationData = { ...location };
      const _labels = [..._locationData.labels];
      const _filteredLabels = _labels.filter((label) => {
        return label.id !== labelId;
      });
      _locationData.labels = _filteredLabels;
      queryClient.setQueryData<LocationData>(['clientLocation'], _locationData);
      toast.success('Sucessfully deleted the label!');
    } catch (error) {
      toast.error('Failed to delete label.');
    }
  };

  const handleLabelEditButtonClick = (label: WBLabel) => {
    setItemToEdit(label);
    setEditFormInView(true);
  };

  return (
    <>
      <Typography fontWeight={'bold'} fontSize={{ xs: textSizing.xs.sectionTitles }} pl={2}>
        Companies Working at Site ({labels.length})
      </Typography>
      <Stack direction={'row'} alignItems={'center'} paddingLeft={'1.7vw'} width={'100%'}>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: { xs: '100%' },
            padding: 0,
            margin: 0,
            overflowX: 'scroll',
          }}
        >
          {labels.map((label) => {
            return (
              <ListItem key={label.id} style={{ padding: 0 }}>
                <HoverIcons
                  handleDeleteClick={() => handleLabelDeleteButtonClick(label.id)}
                  br={0}
                  handleEditClick={() => handleLabelEditButtonClick(label)}
                >
                  <Typography width={'14vw'} fontSize={{ xs: textSizing.xs.sectionText }} textAlign={'center'}>
                    {label.name}
                  </Typography>
                </HoverIcons>
              </ListItem>
            );
          })}
        </List>
      </Stack>
      {editFormInView && (
        <FormDialog
          type={'company-form'}
          location={location}
          siteLocation={siteLocation}
          closeDialog={() => setEditFormInView(false)}
          mainBgColor={mainBlue}
          itemToEdit={itemToEdit}
        />
      )}
    </>
  );
};

export default LabelsSection;
