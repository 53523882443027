import { LunchDining } from '@mui/icons-material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Location } from '../../../api/menu-management/location/types';
import { API_MENU_MANAGEMENT_MENU } from '../../../api/menu-management/menu';
import { Menu, Submenu, SubmenuData } from '../../../api/menu-management/menu/types';
import { API_MENU_MANAGEMENT_SUBMENU } from '../../../api/menu-management/submenu';
import { Player } from '../../../api/player-management/types';
import ConfirmDialog from '../../common/ConfirmDialog';
import s from '../../digital-signage-cms/add-playlist/styles.module.scss';
import CreationModule from './components/CreationModule/CreationModule';
import InputEditOnClick from './components/InputEditOnClick';
import ItemsListDialog from './components/ItemsListDialog';
import SubmenuCard from './components/SubMenu/SubmenuCard';
import { MenuContextProvider } from './context/MenuFormsContext';

type Props = {
  locations: Location[];
  menus: Menu[];
  players: Player[];
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Menus = ({ menus, players }: Props) => {
  const theme = useTheme();
  const lightGray = theme.palette.secondary.light;
  const queryClient = useQueryClient();
  const [allMenus, setAllMenus] = useState<Menu[]>([...menus]);
  const [creationModuleInView, setCreationModuleInView] = useState<boolean>(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState<number>(0);
  const [menuSelected, setMenuSelected] = useState<string>(allMenus.length > 0 ? allMenus[0].name : 'Create a Menu');
  const [menuPlayers, setMenuPlayers] = useState<string[]>([]);
  // menu name
  const [menuName, setMenuName] = useState<string>(allMenus[selectedMenuIndex] ? allMenus[selectedMenuIndex].name : '');
  // this state will track if there are any changes that have been made that need to be saved
  // currently it is only to track if there is a change in the menu name or submenu names.
  const [changeHistory, setChangeHistory] = useState<{ [submenuId: string]: string }>({});
  // dialogs
  const [itemsDialogInview, setItemsDialogInview] = useState<boolean>(false);
  const [deleteConfirmationDialogInview, setDeleteConfirmationDialogInview] = useState<boolean>(false);
  // menu select
  const handleMenuSelectedChange = (event: SelectChangeEvent) => {
    setChangeHistory({});
    setMenuSelected(event.target.value as string);
  };

  const handlePlayerSelectionChange = (event: SelectChangeEvent<typeof menuPlayers>) => {
    const {
      target: { value },
    } = event;
    const newMenuPlayers = typeof value === 'string' ? value.split(',') : value;
    newMenuPlayers.sort();
    setMenuPlayers(newMenuPlayers);
    updateChangeHistory('menuPlayers', 'true');
  };
  // create btn pop up
  const handleCreateBtnClick = () => {
    setCreationModuleInView(true);
  };
  // this function is passed to the component <InputEditOnClick />
  // and is called when that component is no longer in focus
  const updateParentValue = (keyName: string, newValue: string) => {
    if (keyName === 'menuName') {
      setMenuName(newValue);
    }
    updateChangeHistory(keyName, newValue);
  };

  const updateMenuSubmenu = (index: number, submenuData: SubmenuData) => {
    const _allMenus = [...allMenus];
    // copying the menu currently in view
    const _menu = { ..._allMenus[selectedMenuIndex] };
    // copy submenus of currently in view menu
    const _submenus = [..._menu.submenus];
    // submenuData.updatedAt = new Date();
    // overwriting previous submenu data via index
    _submenus[index].submenu = submenuData;
    _menu.submenus = _submenus;
    // updating state of allMenus
    _allMenus[selectedMenuIndex] = _menu;
    setAllMenus(_allMenus);
    updateChangeHistory(submenuData.id, 'true');
  };

  // function to update the change history. it will have logic for checking if
  // the value is the same as the original value. used for displaying 'save' button
  const updateChangeHistory = (key: string, value: string) => {
    const _changeHistory = { ...changeHistory };
    _changeHistory[key] = value;
    setChangeHistory(_changeHistory);
  };

  // handling the button save click
  const handleSaveClick = async () => {
    const _menu = { ...allMenus[selectedMenuIndex] };
    const changeHistoryKeys = Object.keys(changeHistory);
    const _submenus = _menu.submenus;
    // let _locations = _menu.locations;
    let _menuPlayers = _menu.players;
    if (changeHistoryKeys.length > 0) {
      for (let i = 0; i < changeHistoryKeys.length; i++) {
        const currentKey = changeHistoryKeys[i];
        // if (currentKey === 'location') {
        //   const newlocations: { location: { id: string; name: string } }[] = [];
        //   menuLocation.forEach((locationName) => {
        //     const locationIndex = locations.findIndex((loc) => loc.name === locationName);
        //     if (locationIndex > -1) {
        //       newlocations.push({ location: { id: locations[locationIndex].id, name: locations[locationIndex].name } });
        //     }
        //   });
        //   _locations = newlocations;
        // }
        if (currentKey === 'menuPlayers') {
          const newPlayersSerialNumbers: { id: string; name: string }[] = [];
          menuPlayers.forEach((playerName) => {
            const playerIndex = players.findIndex((player) => {
              return player.name === playerName;
            });
            if (playerIndex > -1) {
              newPlayersSerialNumbers.push({ id: players[playerIndex].id, name: players[playerIndex].name });
            }
          });
          _menuPlayers = newPlayersSerialNumbers;
        }
      }
    }
    // updating the menu name
    _menu.name = menuName;
    _menu.submenus = _submenus;
    // _menu.locations = _locations;
    _menu.players = _menuPlayers;
    updateMenuMutation.mutate(_menu);
  };

  const updateMenuSubmenus = ({ menuId, submenuId }: { menuId: string; submenuId: string }) => {
    updateMenuSubmenusMutation.mutate({ menuId, submenuId });
  };

  const handleMenuDelete = async (res: boolean) => {
    if (res) {
      deleteMenuMutation.mutate(menus[selectedMenuIndex].id);
    }
    setDeleteConfirmationDialogInview(false);
  };

  const updateMenuMutation = useMutation({
    mutationFn: (updatedMenu: Menu) => API_MENU_MANAGEMENT_MENU.editMenu(updatedMenu),
    // When mutate is called:
    onMutate: async (updatedMenu: Menu) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['allClientMenus'] });
      // Snapshot the previous value
      const previousMenus = queryClient.getQueryData<Menu[]>(['allClientMenus']);
      if (previousMenus) {
        // iterate through and find the menu that was updated
        const _menus = [...previousMenus];
        _menus[selectedMenuIndex] = updatedMenu;
        queryClient.setQueryData<Menu[]>(['allClientMenus'], _menus);
      }
      // Return a context object with the snapshotted value
      return { previousMenus };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      if (context && context.previousMenus) {
        // this will return the new list of Menus updated
        const _updatedMenus = [...data];
        // now we need to find that menu that we were looking at
        const menuIndex = _updatedMenus.findIndex((menu) => {
          return menu.name === variables.id;
        });
        if (menuIndex > -1) {
          setMenuSelected(_updatedMenus[menuIndex].name);
        }
        // Replace optimistic todo in the todos list with the result
        queryClient.setQueryData<Menu[]>(['allClientMenus'], _updatedMenus);
        setChangeHistory({});
        toast.success('Successfully updated menu!');
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newLocation, context) => {
      if (context?.previousMenus) {
        queryClient.setQueryData(['allClientMenus'], context.previousMenus);
        toast.error('Failed to update menu');
      }
    },
  });

  const updateMenuSubmenusMutation = useMutation({
    mutationFn: (data: { menuId: string; submenuId: string }) =>
      API_MENU_MANAGEMENT_SUBMENU.removeSubmenuFromMenu(data),
    // When mutate is called:
    onMutate: async (data: { menuId: string; submenuId: string }) => {
      const { menuId, submenuId } = data;
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['allClientMenus'] });
      // Snapshot the previous value
      const previousMenus = queryClient.getQueryData<Menu[]>(['allClientMenus']);
      if (previousMenus) {
        // iterate through and find the menu that was updated
        const _menus = [...previousMenus];
        const menuIndex = _menus.findIndex((m: Menu) => m.id === menuId);
        if (menuIndex > -1) {
          const _submenus = _menus[menuIndex].submenus;
          const submenuIndex = _submenus.findIndex((sm: Submenu) => sm.submenu.id === submenuId);
          if (submenuIndex > -1) {
            _submenus.splice(submenuIndex, 1);
            _menus[menuIndex].submenus = _submenus;
            queryClient.setQueryData<Menu[]>(['allClientMenus'], _menus);
          }
        }
      }
      // Return a context object with the snapshotted value
      return { previousMenus };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      if (context && context.previousMenus) {
        const _menus = [...context.previousMenus];
        queryClient.setQueryData<Menu[]>(['allClientMenus'], _menus);
        toast.success('Successfully deleted submenu!');
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newLocation, context) => {
      if (context?.previousMenus) {
        queryClient.setQueryData(['allClientMenus'], context.previousMenus);
        toast.error('Failed to delete submenu');
      }
    },
  });

  const deleteMenuMutation = useMutation({
    mutationFn: (menuId: string) => API_MENU_MANAGEMENT_MENU.deleteMenu(menuId),
    // When mutate is called:
    onMutate: async (menuId: string) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['allClientMenus'] });
      // Snapshot the previous value
      const previousMenus = queryClient.getQueryData<Menu[]>(['allClientMenus']);
      if (previousMenus) {
        // iterate through and find the menu that was updated
        const _menus = [...previousMenus];
        const index = _menus.findIndex((menu) => menu.id == menuId);
        if (index > -1) {
          _menus.splice(index, 1);
          setMenuSelected(_menus.length > 0 ? _menus[0].name : 'Create a Menu');
          setChangeHistory({});
          queryClient.setQueryData<Menu[]>(['allClientMenus'], _menus);
        }
      }
      // Return a context object with the snapshotted value
      return { previousMenus };
    },
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      if (context && context.previousMenus) {
        const _menus = [...context.previousMenus];
        const index = _menus.findIndex((menu) => menu.id == variables);
        if (index > -1) {
          _menus.splice(index, 1);
          queryClient.setQueryData<Menu[]>(['allClientMenus'], _menus);
        }
        toast.success('Successfully deleted menu.');
        setMenuSelected(_menus.length > 0 ? _menus[0].name : 'Create a Menu');
        setChangeHistory({});
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newLocation, context) => {
      if (context?.previousMenus) {
        queryClient.setQueryData(['allClientMenus'], context.previousMenus);
        toast.error('Failed to delete menu');
      }
    },
  });

  // DRAGGABLE FUNCTIONS
  // saving references for dragItem and dragOverItem
  // eslint-disable-next-line
  const dragItem = useRef<any>(null);
  // eslint-disable-next-line
  const dragOverItem = useRef<any>(null);
  // handling sorting drag & drop
  const handleSort = () => {
    const _menus = [...allMenus];
    // duplicate items
    const _itemsSelected = [...menus[selectedMenuIndex].submenus];
    // remove and save the dragged item content
    const draggedItemContent = _itemsSelected.splice(dragItem.current, 1)[0];
    // switch the position
    _itemsSelected.splice(dragOverItem.current, 0, draggedItemContent);
    // reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;
    // update the actual array
    _menus[selectedMenuIndex].submenus = _itemsSelected;
    setAllMenus(_menus);
    const _changeHistory = { ...changeHistory };
    _changeHistory['submenu-order'] = 'true';
    setChangeHistory(_changeHistory);
  };

  useEffect(() => {
    const menuDataIndex = allMenus.findIndex((menu) => menu.name === menuSelected);
    if (menuDataIndex > -1) {
      // const mLocations = allMenus[menuDataIndex].locations.map(({ location }) => location.name);
      // setMenuLocation(mLocations);
      setSelectedMenuIndex(menuDataIndex);
      setMenuName(allMenus[menuDataIndex].name);
      const _menuPlayers: string[] = [];
      players.forEach((player) => {
        if (player.menu && allMenus.length > 0 && player.menu.id === allMenus[menuDataIndex].id) {
          _menuPlayers.push(player.name);
        }
      });
      setMenuPlayers(_menuPlayers);
    }
    // eslint-disable-next-line
  }, [menuSelected]);

  useEffect(() => {
    setAllMenus([...menus]);
  }, [menus]);

  useEffect(() => {
    const _menuPlayers: string[] = [];
    players.forEach((player) => {
      if (player.menu && allMenus.length > 0 && player.menu.id === allMenus[selectedMenuIndex].id) {
        _menuPlayers.push(player.name);
      }
    });
    setMenuPlayers(_menuPlayers);
    // eslint-disable-next-line
  }, [players]);

  return (
    <Box bgcolor={'#fff'} borderRadius={1} p={1}>
      <Stack
        maxHeight={{ xs: '40vh' }}
        flexDirection={{ md: 'row' }}
        sx={{ alignItems: { md: 'center' } }}
        spacing={{ xs: 2, md: 0 }}
        gap={{ xs: 0, md: 2 }}
        pb={2}
        borderBottom={'1px solid #000'}
      >
        <Box>
          <FormControl sx={{ maxWidth: { xs: 360, md: '35vw' }, minWidth: { md: '30vw' } }}>
            <InputLabel id='menu-select-label'>Menu</InputLabel>
            <Select
              labelId='menu-select-label'
              id='menu-select'
              value={menuSelected}
              label='Menu'
              onChange={handleMenuSelectedChange}
            >
              {allMenus.length > 0 ? (
                allMenus.map((menu: Menu) => {
                  return (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem value={'Create a Menu'}>Create a Menu</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Box>
          {players.length > 0 ? (
            <FormControl sx={{ minWidth: { md: '10vw' }, maxWidth: { md: '40vw' } }}>
              <InputLabel id='menu-player-multi-select'>Players</InputLabel>
              <Select
                labelId='menu-player-multi-select'
                id='multi-select-menu-player'
                multiple
                value={menuPlayers}
                onChange={handlePlayerSelectionChange}
                input={<OutlinedInput label='Tag' />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {players.map((player: Player) => {
                  return (
                    <MenuItem key={player.id} value={player.name}>
                      <Checkbox checked={menuPlayers.indexOf(player.name) > -1} />
                      <ListItemText
                        primary={player.name}
                        secondary={
                          player.menu && player.menu.name !== menuName ? (
                            <Typography color={'red'}>({player.menu.name})</Typography>
                          ) : null
                        }
                      />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <Typography>There are no players. Please contact support.</Typography>
          )}
        </Box>
        <Box>
          <Button onClick={() => setItemsDialogInview(true)}>
            <LunchDining />
          </Button>
        </Box>
        <Box>
          <Button variant='contained' onClick={handleCreateBtnClick} fullWidth>
            CREATE
          </Button>
        </Box>
      </Stack>
      <Stack mt={1} minHeight={{ xs: '60vh' }} flexDirection={{ md: 'row' }} bgcolor={'#fff'}>
        {allMenus.length > 0 ? (
          <Box border={'1px solid #000'} width={{ md: '100%' }} bgcolor={lightGray}>
            <Box pl={'25%'} pr={'25%'}>
              <InputEditOnClick keyName='menuName' updateParentValue={updateParentValue} initialValue={menuName} />
            </Box>
            <Stack
              bgcolor={'#fff'}
              flexDirection={{ md: 'row' }}
              gap={{ md: 2 }}
              p={1}
              spacing={{ xs: 1 }}
              justifyContent={'center'}
              alignItems={'center'}
            >
              {allMenus[selectedMenuIndex].submenus && allMenus[selectedMenuIndex].submenus.length > 0 ? (
                allMenus[selectedMenuIndex].submenus.map((submenu: Submenu, index: number) => {
                  return (
                    <Stack
                      key={submenu.submenu.id}
                      direction={'column'}
                      draggable
                      onDragStart={() => (dragItem.current = index)}
                      onDragEnter={() => (dragOverItem.current = index)}
                      onDragEnd={handleSort}
                      onDragOver={(e) => e.preventDefault()}
                      className={s.grabbable}
                      width={`${100 / allMenus[selectedMenuIndex].submenus.length}%`}
                    >
                      <SubmenuCard
                        index={index}
                        menuId={allMenus[selectedMenuIndex].id}
                        submenuData={submenu}
                        updateParentSubmenuData={updateMenuSubmenu}
                        removeItemFromParentSubmenu={updateMenuSubmenus}
                      />
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        p={1}
                        sx={{ '&:hover': { cursor: 'grab' } }}
                      >
                        <DragIndicatorIcon />
                      </Box>
                    </Stack>
                  );
                })
              ) : (
                <Typography fontWeight={'bold'} textAlign={'center'}>
                  There are no submenus connected to this menu. please create one.
                </Typography>
              )}
            </Stack>
          </Box>
        ) : (
          <Typography width={'100%'} fontWeight={'bold'} textAlign={'center'}>
            No menu data found. Please create a menu.
          </Typography>
        )}
      </Stack>
      {creationModuleInView && (
        <MenuContextProvider>
          <CreationModule
            menu={menus[selectedMenuIndex]}
            menuIndex={selectedMenuIndex}
            defaultSelectedOption={'Menu'}
            handleClose={() => setCreationModuleInView(false)}
            setNewlyCreatedMenu={setMenuSelected}
          />
        </MenuContextProvider>
      )}
      {itemsDialogInview && <ItemsListDialog handleClose={() => setItemsDialogInview(false)} />}
      {deleteConfirmationDialogInview && (
        <ConfirmDialog
          title={'Please confirm this action.'}
          message='Are you sure you want to delete this menu?'
          handleClick={handleMenuDelete}
        />
      )}
      {allMenus.length > 0 && selectedMenuIndex > -1 && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={1} mt={2} mb={2}>
          <Button
            variant='contained'
            onClick={handleSaveClick}
            disabled={Object.keys(changeHistory).length > 0 ? false : true}
          >
            Save
          </Button>
          <Button variant='contained' color='error' onClick={() => setDeleteConfirmationDialogInview(true)}>
            Delete
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Menus;
