import BackupTableIcon from '@mui/icons-material/BackupTable';
import { Box, Card, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MUITable from '@mui/material/Table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_WEATHER } from '../../api/weather';
import { WeatherTokenGetData } from '../../api/weather/types';
import Header from '../common/header';
import Modal from '../common/modal';
import Row from './components/Row';
import s from './styles.module.scss';
import { Props, TableData } from './types';
import createData from './utils/create-data';

const WeatherTable = ({ data }: Props) => {
  const rows: TableData[] = [];
  data.forEach((item: WeatherTokenGetData) => {
    rows.push(
      createData(
        item.id,
        item.customer,
        item.zipCode,
        item.numberOfPlayers,
        item.callsExpected,
        item.callsActual,
        item.resetCallsDate
      )
    );
  });

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState('');

  const deleteToken = async () => {
    const success = await API_WEATHER.removeToken(id);
    success
      ? setTimeout(() => {
          navigate(0);
        }, 2000)
      : '';
  };

  return (
    <>
      {rows.length > 0 ? (
        <TableContainer component={Card} className={s.container}>
          <Header text='Weather Tokens' Icon={<BackupTableIcon />} />
          <MUITable sx={{ marginTop: 5.5 }}>
            <TableHead className={s.header}>
              <TableRow>
                <TableCell className={s.cell}>Customer</TableCell>
                <TableCell className={s.cell}>Zip</TableCell>
                <TableCell className={s.cell}># Of Players</TableCell>
                <TableCell className={s.cell}>Expected Calls</TableCell>
                <TableCell className={s.cell}>Actual Calls</TableCell>
                <TableCell className={s.cell}>Resets In</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return <Row key={row.id} data={row} setIsOpen={setIsOpen} setId={setId} />;
              })}
            </TableBody>
          </MUITable>
        </TableContainer>
      ) : (
        <Box sx={{ padding: 5 }}>No Weather Tokens</Box>
      )}
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} fn={deleteToken} />
    </>
  );
};

export default WeatherTable;
