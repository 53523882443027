import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { Dimensions } from '../types';
import FiveDay from './five-day/FiveDay';
import s from '../styles.module.scss';

type Props = {
  dimensions: Dimensions;
  showFiveDay: boolean;
  showCity: boolean;
  location: string;
};

const Preview = ({ dimensions, showFiveDay, showCity, location }: Props) => {
  return (
    <Box width={dimensions.width || ''} height={dimensions.height || ''} className={s.preview}>
      {showCity && (
        <Box className={s.location} sx={{ position: dimensions.height <= 400 ? '' : 'absolute' }}>
          {location}
        </Box>
      )}
      <Stack alignItems='center' direction={dimensions.height <= 400 ? 'row' : 'column'}>
        <Stack direction='row' alignItems='center' mr={showFiveDay && dimensions.height <= 400 ? 5 : 0}>
          <img src='/icons/cloud.svg' className={s.icon} />
          <Box className={s.temp}>87&#xb0;</Box>
        </Stack>

        {showFiveDay && <FiveDay height={dimensions.height} />}
      </Stack>
    </Box>
  );
};

export default Preview;
