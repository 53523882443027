import { Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Dispatch, SetStateAction } from 'react';
import PlacesAutocomplete from './places-autocomplete/PlacesAutocomplete';
import BaseInput from '../../common/BaseInput';

type Props = {
  location: string;
  setLocation: Dispatch<SetStateAction<string>>;
  setSteps: Dispatch<SetStateAction<number>>;
  setCoords: Dispatch<SetStateAction<number[]>>;
  setZipCode: Dispatch<SetStateAction<string>>;
  customer: string;
  setCustomer: Dispatch<SetStateAction<string>>;
};

const StepOne = ({ location, setLocation, setSteps, setCoords, setZipCode, customer, setCustomer }: Props) => {
  const handleClick = () => {
    if (!location || !customer) return;
    setSteps(2);
  };

  return (
    <>
      <PlacesAutocomplete setCoords={setCoords} setLocation={setLocation} setZipCode={setZipCode} />
      <BaseInput label='Customer' size='medium' onChange={(e) => setCustomer(e.target.value)} />
      <Button variant='contained' color='error' endIcon={<NavigateNextIcon />} sx={{ mt: 5 }} onClick={handleClick}>
        Next
      </Button>
    </>
  );
};

export default StepOne;
