import { useQuery } from '@tanstack/react-query';
import { API_USER } from '../../api/user';
import Loader from '../../components/common/loader';
import UsersGroupsTables from '../../components/users-table/UsersGroupsTables';
import { useAuthContext } from '../../context/AuthContext';
import { Roles } from '../../types';
import { decrypt } from '../../utils/cryptojs-helper';
import rolesData from '../../utils/roles-data';

const UsersPage = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);
  // pass different url based off if admin or not. If admin get all users, else get only users by current client
  let url = `user/client/${user.currentClient.client.id}`;
  if (user.currentClient.client.roles.includes(Roles.ADMIN)) {
    url = '/user';
  }
  // pass arg to function by passing through second argument an array - (queryKey)
  const { error, data } = useQuery(['GetAllUsers', url], API_USER.getAll);

  // TODO: fetch the roles data as well
  if (error) return <p>error</p>;
  if (data) return <UsersGroupsTables loggedUser={user} usersData={data} rolesData={rolesData} />;
  return <Loader />;
};

export default UsersPage;
