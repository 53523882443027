import axios from '../../../config/axiosV2';

const createSubmenu = async (formData: {
  name: string;
  description: string;
  menuId: string;
  position: number;
}): Promise<boolean | undefined> => {
  const url = '/menu-management/submenu';
  try {
    const res = await axios.post(url, formData);
    const { data } = res;
    return data.menus;
  } catch {
    throw Error('Failed to create a new submenu.');
  }
};

const removeSubmenuFromMenu = async (data: { menuId: string; submenuId: string }): Promise<boolean | undefined> => {
  const url = `/menu-management/submenu/${data.menuId}/${data.submenuId}`;
  try {
    await axios.delete(url);
    return true;
  } catch {
    throw Error('Failed to remove item from submenu');
  }
};

export { createSubmenu, removeSubmenuFromMenu };
