/* eslint-disable camelcase */
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Dispatch, SetStateAction } from 'react';
import BaseInput from '../../../common/BaseInput';
import { Box } from '@mui/material';
import s from './styles.module.scss';

type Props = {
  setCoords: Dispatch<SetStateAction<number[]>>;
  setLocation: Dispatch<SetStateAction<string>>;
  setZipCode: Dispatch<SetStateAction<string>>;
};

// uses google places api to autocomplete address search
const PlacesAutocomplete = ({ setCoords, setLocation, setZipCode }: Props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const handleInput = (e: string) => {
    setValue(e);
  };

  const handleSelect =
    ({ description, structured_formatting }: google.maps.places.AutocompletePrediction) =>
    () => {
      setValue(structured_formatting.main_text, false);
      setLocation(structured_formatting.main_text);
      clearSuggestions();

      // get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => {
          const zip = results[0].address_components.find((c) => c.types.includes('postal_code'))?.long_name || 'NA';
          setZipCode(zip);

          // return coordinates
          return getLatLng(results[0]);
        })
        .then(({ lat, lng }) => {
          setCoords([lat, lng]);
        })
        .catch(() => {
          return;
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <li key={place_id} className={s.item} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <Box width='100%'>
      <BaseInput
        value={value}
        onChange={(e) => handleInput(e.target.value)}
        disabled={!ready}
        label='Location'
        size='medium'
      />
      {/*  uses the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && <ul className={s.list}>{renderSuggestions()}</ul>}
    </Box>
  );
};

export default PlacesAutocomplete;
