import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeIcon from '@mui/icons-material/Badge';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { Autocomplete, Box, Button, Card, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_ACCESS_TOKEN } from '../../api/access-token';
import { ClientGetData } from '../../api/client/types';
import BaseInput from '../../components/common/BaseInput';
import Loader from '../../components/common/loader';
import { ClientNames, Roles } from '../../types';
import Header from '../common/header';
import { Props } from './types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const NewAccessToken = ({ clientData, loggedUser: user }: Props) => {
  const navigate = useNavigate();
  const [deviceName, setDeviceName] = useState<string>();
  const [zipCode, setZipCode] = useState<string>();
  const [client, setClient] = useState<ClientNames | null>();

  const [token, setToken] = useState<string>('');
  // module stuff
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    navigate('/access-tokens');
  };

  // if user admin show all roles, else show roles only based on current client
  const [clientNames] = useState<ClientNames[]>(
    user.currentClient.client.roles.includes(Roles.ADMIN)
      ? clientData.map((client: ClientGetData) => {
          return { id: client.id, name: client.name };
        })
      : [{ id: user.currentClient.client.id, name: user.currentClient.client.name }]
  );

  const [loading] = useState(false);

  const create = async () => {
    if (!deviceName || !zipCode || !client) return;
    const success = await API_ACCESS_TOKEN.generateToken(deviceName, zipCode, client);
    // add a success module
    if (success) {
      setToken(success);
      setOpen(true);
    }
    // success ? navigate('/access-tokens') : '';
  };

  const handleClientChange = (client: ClientNames | null) => {
    setClient(client);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box maxWidth={1400} marginX='auto'>
            <Link to='/access-tokens' style={{ textDecoration: 'none' }}>
              <Button color='warning' endIcon={<ArrowBackIcon />}>
                Cancel
              </Button>
            </Link>
            <Card sx={{ marginTop: 2 }}>
              <Header text='Device name' Icon={<BadgeIcon />} />
              <BaseInput label='Device name' onChange={(e) => setDeviceName(e.target.value)} />
            </Card>
            <Card sx={{ marginTop: 2 }}>
              <Header text='ZIP Code' Icon={<BadgeIcon />} />
              <BaseInput label='ZIP Code' onChange={(e) => setZipCode(e.target.value)} />
            </Card>
            <Card sx={{ marginTop: 2 }}>
              <Header text='Client' Icon={<CorporateFareIcon />} />
              <Autocomplete
                onChange={(_, client) => handleClientChange(client)}
                fullWidth
                //  multiple
                options={clientNames}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                renderInput={(params) => <BaseInput {...params} label='Pick Clients' />}
              />
            </Card>
            <Button
              disabled={!deviceName || !zipCode || !client}
              variant='contained'
              size='small'
              sx={{ mt: 2, alignSelf: 'flex-start' }}
              onClick={() => create()}
            >
              Create Access Token
            </Button>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Token Generated Successfully!
              </Typography>
              <Typography id='modal-modal-title' component='p'>
                Please copy and paste the token in a secure file. This token will only be seen once.
              </Typography>
              <Typography
                id='modal-modal-description'
                sx={{ mt: 2, backgroundColor: 'lightgray', padding: '4px', overflowWrap: 'break-word' }}
              >
                {token}
              </Typography>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default NewAccessToken;
