import { Delete, Edit } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_PLAYLIST } from '../../../../api/digital-signage-cms/playlist';
import { PlaylistData } from '../../../../api/digital-signage-cms/types';
import { UserAuthData } from '../../../../api/user/types';
import { formatDateMMDDYYY } from '../../../../utils/format-data';
import ConfirmDialog from '../../../common/ConfirmDialog';
import Loader from '../../../common/loader/Loader';

const LoaderRow = () => {
  return (
    <TableRow>
      <TableCell component='th' scope='row'></TableCell>
      <TableCell align='right'></TableCell>
      <TableCell align='right'>
        <Loader />
      </TableCell>
      <TableCell align='right'></TableCell>
      <TableCell align='right'></TableCell>
    </TableRow>
  );
};

const NoDataRow = () => {
  return (
    <TableRow>
      <TableCell component='th' scope='row'></TableCell>
      <TableCell align='right'></TableCell>
      <TableCell align='right'>No Current Playlists</TableCell>
      <TableCell align='right'></TableCell>
      <TableCell align='right'></TableCell>
    </TableRow>
  );
};

const PlaylistTable = ({ user: loggedUser }: { user: UserAuthData }) => {
  const navigate = useNavigate();
  const { error, data: playlistsData } = useQuery<PlaylistData[]>(['AllUserPlaylist'], () =>
    API_PLAYLIST.getAllPlaylist(loggedUser.currentClient.client.id)
  );
  const [selectedPlaylistIndex, setSelectedPlaylistIndex] = useState<number>(-1);
  const [confirmationDialogInView, setConfirmationDialogInView] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const handleDeleteBtnClick = (index: number) => {
    setConfirmationDialogInView(true);
    setSelectedPlaylistIndex(index);
  };

  const confirmationResponseHandler = (res: boolean) => {
    if (res) handlePlaylistDelete(selectedPlaylistIndex);
    setConfirmationDialogInView(false);
    setSelectedPlaylistIndex(-1);
  };

  const handlePlaylistDelete = (index: number) => {
    if (playlistsData) {
      const playlistToDelete = playlistsData[index];
      deletePlaylistMutation.mutate(playlistToDelete);
    }
  };

  // deleting mutation
  const deletePlaylistMutation = useMutation({
    mutationFn: (playlistToDelete: PlaylistData) =>
      API_PLAYLIST.deletePlaylist(playlistToDelete.id ? playlistToDelete.id : ''),
    onMutate: async (playlistToDelete: PlaylistData) => {
      await queryClient.cancelQueries({ queryKey: ['AllUserPlaylist'] });
      const previousPresentations = queryClient.getQueryData<PlaylistData[]>(['AllUserPlaylist']);
      if (previousPresentations) {
        queryClient.setQueryData<PlaylistData[]>(
          ['AllUserPlaylist'],
          (previousPresentations: PlaylistData[] | undefined) =>
            previousPresentations?.filter((p: PlaylistData) => p.id !== playlistToDelete.id)
        );
        return { previousPresentations };
      }
    },
    onError: (err, playlistToDelete, context) => {
      if (context?.previousPresentations) {
        queryClient.setQueryData(['AllUserPlaylist'], context.previousPresentations);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['AllUserPlaylist'] });
    },
  });
  const createRow = (data: PlaylistData, index: number) => {
    return (
      <TableRow key={data.name} sx={{ '&:last-child td, &:last-child th': { border: 0 }, width: '100%' }}>
        <TableCell component='th' scope='row'>
          {data.name}
        </TableCell>
        <TableCell align='center'>{data._count.media}</TableCell>
        <TableCell align='center'>
          {data.user.firstName} {data.user.lastName}
        </TableCell>
        <TableCell align='center'>{formatDateMMDDYYY(data.createdAt)}</TableCell>
        <TableCell align='center'>{formatDateMMDDYYY(data.updatedAt)}</TableCell>
        <TableCell align='center'>
          <Stack>
            {loggedUser.permissions[loggedUser.currentClient.client.name].includes('playlist-update') ? (
              <Button onClick={() => navigate(`/digital-signage/playlist/edit/${data.id}`)}>
                <Edit htmlColor='green' />
              </Button>
            ) : null}
            {loggedUser.permissions[loggedUser.currentClient.client.name].includes('playlist-delete') ? (
              <Button onClick={() => handleDeleteBtnClick(index)}>
                <Delete htmlColor='red' />
              </Button>
            ) : null}
          </Stack>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label='simple table' width={'100%'}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align='center'>Media Count</TableCell>
              <TableCell align='center'>Created By</TableCell>
              <TableCell align='center'>Created At</TableCell>
              <TableCell align='center'>Updated At</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {error ? (
              <TableRow>
                <TableCell>Error loading playlist data</TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {playlistsData ? (
              playlistsData.length > 0 ? (
                playlistsData.map((playlist, index) => {
                  return createRow(playlist, index);
                })
              ) : (
                <NoDataRow />
              )
            ) : (
              <LoaderRow />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {confirmationDialogInView && (
        <ConfirmDialog
          title={'Please confirm the action'}
          message='Are you sure you would like to delete this playlist?'
          handleClick={confirmationResponseHandler}
        />
      )}
    </>
  );
};

export default PlaylistTable;
