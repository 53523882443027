import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { createNewLabelForLocation, updateLabelForLocation } from '../../../../api/whiteboard/map/location/api';
import { LocationData, WBLabel } from '../../../../api/whiteboard/map/location/types';
import checkIfEmptyString from '../../../../utils/check-if-empty';
import Loader from '../../../common/loader';

type Props = {
  locationData: LocationData;
  itemToEdit: WBLabel | null;
  close: () => void;
};

const CompanyForm = ({ itemToEdit, locationData, close }: Props) => {
  const queryClient = useQueryClient();
  const { location } = locationData;
  const [step, setStep] = useState<number>(0);
  const [name, setName] = useState<string>(itemToEdit ? itemToEdit.name : '');
  // error
  const [nameError, setNameError] = useState<{ isError: boolean; message: string }>({ isError: false, message: '' });

  // TODO: create an API file once the backend is created
  const handleSubmit = async () => {
    if (name.length < 3 || checkIfEmptyString(name)) {
      setNameError({ isError: true, message: 'Company name cannot be empty and greater than 3 characters.' });
    } else {
      setStep(1);
      if (itemToEdit) {
        try {
          await updateLabelForLocation(location.id, itemToEdit.id, name);
          const _locationData = { ...locationData };
          const _labels = [..._locationData.labels];
          const foundIndex = _labels.findIndex((label) => {
            return label.id == itemToEdit.id;
          });
          if (foundIndex) {
            const _updatedLabel = _labels[foundIndex];
            _updatedLabel.name = name;
            _labels[foundIndex] = _updatedLabel;
            _locationData.labels = _labels;
            queryClient.setQueryData<LocationData>(['clientLocation'], _locationData);
          }
          toast.success('Successfully updated Label!');
          setStep(0);
          close();
        } catch {
          toast.error('Failed to update label.');
        }
      } else {
        try {
          const newLabel = await createNewLabelForLocation({ locationId: location.id, name });
          // making a copy and removing that media
          const _locationData = { ...locationData };
          const _labels = [..._locationData.labels, newLabel];
          _locationData.labels = _labels;
          queryClient.setQueryData<LocationData>(['clientLocation'], _locationData);
          toast.success('Successfully created new Label!');
          setStep(0);
          close();
        } catch {
          toast.error('Failed to create a new label.');
        }
      }
    }
  };

  const isNameValid = (name: string) => {
    let isValid = true;
    if (name.length < 3) {
      isValid = false;
    } else if (checkIfEmptyString(name)) {
      isValid = false;
    } else if (name[0] === ' ') {
      isValid = false;
    }
    return isValid;
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} pt={2}>
      {step === 0 ? (
        <>
          <TextField
            value={name}
            label={'Company Name'}
            helperText={nameError.isError ? nameError.message : ''}
            onChange={(e) => setName(e.target.value)}
          />
          <Box display={'flex'} justifyContent={'center'}>
            <Button variant='contained' disabled={isNameValid(name) ? false : true} onClick={handleSubmit}>
              <Typography color={'#fff'} fontWeight={'bold'}>
                {itemToEdit ? 'Edit Company' : 'Add Company'}
              </Typography>
            </Button>
          </Box>
        </>
      ) : (
        <Stack direction={'column'}>
          <Typography textAlign={'center'} fontWeight={'bold'}>
            Saving new company...
          </Typography>
          <Box position={'relative'} height={'8vh'}>
            <Loader />
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default CompanyForm;
